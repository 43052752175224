<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_19906_33834)">
      <rect
        width="7"
        height="20"
        fill="#0052B4"
      />
      <rect
        x="7"
        width="6"
        height="20"
        fill="#F7FCFF"
      />
      <rect
        x="13"
        width="7"
        height="20"
        fill="#F50100"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 20 });
</script>
