import { NamedValue } from '@intlify/core-base';

export const currentCopyright = (
  t: (key: string, named: NamedValue) => string,
  date: string
): string => t('footer.copyright', { date });

export const currentSupportLink = 'https://vr-labs.ru/support/';

export const currentImgPath = '/img/example/xls_example.jpg';

export const currentEmail = 'info@vizex.ru';

export const currentSupportEmail = 'support@vr-labs.ru';

export const companyWebsiteLink = 'https://vr-labs.ru/';
