import { computed, Ref } from 'vue';
import i18n from '@/i18n';
import store from '@/store';
import dayjs from '@/utils/dayjs';
import axios from '@/services/axios';
import { getBrowserLocale } from '@/utils/i18n';
import { getStorageItem, setStorageItem } from '@/utils/utils-storage';

export const availableLocales = Object.keys(i18n.global.messages.value);

export const getLocale = (): string | undefined => {
  const vuexLocale = store.getters.lang;
  const storedLocale = getStorageItem<string>('vr_lang');
  const browserLocale = getBrowserLocale();
  return vuexLocale || storedLocale || browserLocale;
};

export const setLocale = (lang?: string): void => {
  let locale = lang || getLocale();
  if (!locale || !availableLocales.includes(locale)) {
    locale = (process.env.VUE_APP_I18N_FALLBACK_LOCALE) || 'en';
  }
  setStorageItem('vr_lang', locale);

  // Set frontend locale
  i18n.global.locale.value = locale;
  // Set dayjs locale
  dayjs.locale(locale);
  // Set backend locale
  axios.defaults.headers.common['Custom-lang'] = locale;
};

export const currentI18nLocale: Ref<string> = i18n.global.locale;

export const isRuLocaleSet = computed<boolean>(
  () => i18n.global.locale.value === 'ru'
);

export default {
  getLocale,
  setLocale,
  availableLocales,
  currentI18nLocale,
  isRuLocaleSet,
};
