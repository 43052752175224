<template>
  <WithSpinner :isFetching="isFetching">
    <div class="lab">
      <div class="lab__picture">
        <div class="lab__top-content container">
          <router-link
            to="/"
            class="lab__go-back"
          >
            <IconArrowBack />
            <span>{{ t("toMainPage") }}</span>
          </router-link>

          <div class="lab__info">
            <div class="lab__subtitle">
              {{ subtitle }}
            </div>
            <div class="lab__title">
              {{ product.name }}
            </div>
          </div>

          <div class="lab__info-more">
            <div
              v-if="expirationDate"
              class="lab__info-expires"
            >
              <div class="lab__info-express-text">
                <div
                  v-if="isSoonExpire"
                  class="lab__info-express-alert"
                />
                {{ expirationDate }}
              </div>
              <ElTooltip
                class="box-item"
                effect="light"
                :content="tooltipSpendTime"
                rawContent
                placement="bottom"
              >
                <div class="lab__info-calendar">
                  <Calendar />
                </div>
              </ElTooltip>
            </div>
            <router-link
              v-if="product.has_workbook && product.workbook_url"
              :to="product.workbook_url"
              class="lab__workbook"
            >
              <IconWorkbook class="lab__workbook-icon" />
              {{ t("labs.workbook") }}
            </router-link>
          </div>
        </div>
        <picture>
          <img
            :src="product.app_img?.original"
            :alt="product.name"
            class="lab__background"
          />
        </picture>

        <div class="lab__subscribe-wrap">
          <div class="lab__subscribe">
            <!-- запустить -->
            <a
              v-if="canLaunch"
              class="el-button lab__btn-play ui-btn --style-1"
              :href="launchLink"
              target="_blank"
              @click="handleClickApp"
            >
              {{ t("labs.launch") }}
              <ElIcon class="el-icon--right">
                <IconPlay />
              </ElIcon>
            </a>

            <!-- подписаться -->
            <template v-else>
              <ElButton
                class="lab__btn-play ui-btn --style-1"
                @click="handleSubscribeClick"
              >
                {{ t("labs.subscribe") }}
                <ElIcon class="el-icon--right">
                  <ShopCart />
                </ElIcon>
              </ElButton>

              <!-- демо -->
              <ElTooltip
                v-if="product.demo_mode"
                class="box-item"
                effect="light"
                placement="top"
                :content="demoCounterTooltipText"
              >
                <a
                  class="el-button lab__btn-play ui-btn --style-1"
                  :class="{ 'mobile': isMobileConst, 'is-disabled': !launchLink }"
                  :href="isUser ? launchLink : undefined"
                  target="_blank"
                  @click="handleClickApp"
                >
                  {{ t("labs.demo") }}
                  <ElIcon class="el-icon--right">
                    <IconPlay />
                  </ElIcon>
                </a>
              </ElTooltip>
            </template>
          </div>
          <div class="lab__subscribe">
            <a
              v-for="link in buttonLinks"
              :key="`link-${link.id}`"
              class="el-button lab__btn-link ui-btn"
              :href="link.locales[0].url"
            >
              {{ link.locales[0].caption }}
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="product.authors_signature || product.description"
        class="lab__content"
      >
        <div
          v-if="product.authors_signature"
          class="lab__content-space border-bottom"
        >
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoImportant />
            </div>
            <div
              class="lab__content-title"
              v-html="product.authors_signature"
            />
          </div>
        </div>
        <div
          class="lab__content-space"
          v-html="product.description"
        />
      </div>

      <div class="lab__content">
        <div class="lab__content-space border-bottom">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoVideo />
            </div>
            <div class="lab__content-title">
              {{ t('labelVideos') }}
            </div>
          </div>
        </div>
        <div class="lab__content-space lab__content-carousel">
          <Carousel
            :totalSlides="videoInstructions.length"
            :slidesPerPage="3"
            :slideWidth="264"
            :containerGap="8"
            class="lab__video-carousel"
          >
            <template v-for="(video, index) in videoInstructions">
              <a
                v-if="isLink(video)"
                :key="`video-link-${index}`"
                :href="video.locales[0].file"
                class="lab__video-link"
                target="_blank"
              >
                <img
                  class="lab__video-cover"
                  src="/img/empty/card.png"
                  :alt="video.locales[0].caption"
                />
                <span class="lab__video-title">
                  {{ video.locales[0].caption }}
                </span>
              </a>
              <VideoPlayer
                v-else
                :key="`video-embed-${index}`"
                :path="video.locales[0].file"
                :title="video.locales[0].caption"
                :autoplay="true"
                width="70%"
                openButtonCssClass="lab__video-btn"
                :openButtonText="video.locales[0].caption"
              >
                <template #video-preview>
                  <video
                    :src="video.locales[0].file"
                    preload="metadata"
                    class="lab__video-cover"
                  />
                </template>
                <template #btn-text>
                  <span class="lab__video-title">
                    {{ video.locales[0].caption }}
                  </span>
                </template>
              </VideoPlayer>
            </template>
          </Carousel>
        </div>
      </div>

      <div class="lab__content">
        <div class="lab__content-space border-bottom">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoImportant />
            </div>
            <div class="lab__content-title">
              {{ t('fields.supported_locales') }}
            </div>
          </div>
        </div>
        <div class="lab__content-space">
          <div
            v-for="lang in product.supported_locales"
            :key="`lang-${lang}`"
            class="lab__content-lang"
          >
            <LangIcon :lang="lang" />
            {{ lang }}
          </div>
        </div>
      </div>

      <div class="lab__content">
        <div class="lab__content-space border-bottom">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoDoc />
            </div>
            <div class="lab__content-title">
              {{ t('labelDocs') }}
            </div>
          </div>
        </div>
        <div class="lab__content-space">
          <a
            v-for="doc in usersGuides"
            :key="`doc-link-${doc.id}`"
            :href="doc.locales[0].file"
            target="_blank"
            class="lab__content-link"
          >
            <div class="lab__content-link-svg" />
            <div class="lab__content-link-text">
              {{ doc.locales[0].caption }}
            </div>
          </a>
        </div>
      </div>

      <div class="lab__content">
        <div class="lab__content-space border-bottom">
          <div class="lab__short">
            <div class="lab__short-icon">
              <InfoSysReq />
            </div>
            <div class="lab__content-title">
              {{ t('fields.labelSystemReq') }}
            </div>
          </div>
        </div>
        <div
          class="lab__content-space"
          v-html="product.system_requirements || sysReq"
        />
      </div>
    </div>
  </WithSpinner>

  <MobileModal
    :isOpen="isOpen"
    @close="isOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ElIcon, ElTooltip, ElButton } from 'element-plus';
import { names } from '@/constants/routes';
import { getStorageItem, setStorageItem, catchErrorShow, pluralNoun, isMobile } from '@/utils';
import { loginUser } from '@/services/userActions';
import IconWorkbook from '@/components/icons/Workbook.vue';
import Calendar from '@/components/icons/CalendarClock.vue';
import InfoImportant from '@/components/icons/InfoImportant.vue';
import InfoVideo from '@/components/icons/InfoVideo.vue';
import InfoDoc from '@/components/icons/InfoDoc.vue';
import InfoSysReq from '@/components/icons/InfoSysReq.vue';
import dayjs from '@/utils/dayjs';
import MobileModal from '@/components/MobileModal.vue';
import IconArrowBack from '@/components/icons/ArrowBack.vue';
import IconPlay from '@/components/icons/Play.vue';
import ShopCart from '@/components/icons/ShopCart.vue';
import WithSpinner from '@/components/WithSpinner.vue';
import { Manual, FileStatusEnum, DocumentTypeEnum, MediaFile, ManualViewTypeEnum } from '@/types/manuals';
import { createFieldByManuals } from '@/router/Admin/builderManuals';
import LangIcon from '@/components/Form/LangIcon.vue';
import Carousel from '@/components/Carousel.vue';
import VideoPlayer from '@/components/VideoPlayer/index.vue';
import { LabData, SysReq } from '@/types/LabData';
import services from '../services';

const { t } = useI18n();
const router = useRouter();

const isLink = (item: MediaFile) => item.view_type === ManualViewTypeEnum.open;

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const isMobileConst = isMobile();
const store = useStore();
const isFetching = ref(true);

const product = ref<LabData>({
  active_for_user: false,
  app_img: {
    original: '',
    '240x160': '',
    '400x280': '',
  },
  demo_counter: 0,
  demo_mode: false,
  description: '',
  authors_signature: '',
  system_requirements: '',
  id: null,
  license_state: false,
  name: '',
  offer: {
    id: null,
    price_per_month: null,
    price_per_month_with_discount: null,
    discount_per_month: null,
    type: '',
  },
  has_workbook: false,
  workbook_url: '',
  subsection: {
    shortname: '',
    name: '',
  },
  uuid: '',
  links: [],
  manuals: [],
  supported_locales: [],
});
const isOpen = ref(false);
const isUser = computed(() => store.getters.isAuthed);
const canLaunch = computed(() => product.value?.active_for_user);
const subtitle = computed(() => t('labs.virtualLab', { name: product.value?.subsection?.name }));
const launchLink = computed(() => {
  if (
    isMobileConst
    || !product.value?.uuid
    || (product.value.demo_mode && !product.value.demo_counter)
  ) {
    return undefined;
  }

  return product.value.demo_mode
    ? `/api/product/launch-as-demo/${product.value.uuid}`
    : `/api/product/launch/${product.value.uuid}`;
});
const userLang = store.getters.lang;
const sysReq = computed(() => {
  const data = store.getters['Common/systemRequirements'];
  return data.find((x: SysReq) => x.locale === userLang)?.text;
});
store.dispatch('Common/getSystemRequirements');

const loadedManuals = computed(() => product?.value?.manuals
  .filter((item: Manual) => item.locales[0].file_status === FileStatusEnum.loaded) || []);

const videoInstructions = computed(() => (loadedManuals.value
  ? createFieldByManuals(loadedManuals.value, DocumentTypeEnum.Video)
    .sort((a, b) => a.order - b.order)
  : []));

const usersGuides = computed(() => (loadedManuals.value
  ? createFieldByManuals(loadedManuals.value, DocumentTypeEnum.Document)
    .sort((a, b) => a.order - b.order)
  : []));

const buttonLinks = computed(() => product.value.links.slice().sort((a, b) => a.order - b.order));

const formatTime = (seconds: number) => {
  if (!seconds) { return ''; }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let time = hours ? `${hours} ${t('time.hour')}. ` : '';
  time += minutes ? `${minutes} ${t('time.minute')}.` : '';
  return time;
};

const expiredTime = computed(() => {
  if (!product.value.license_expiration_date) { return false; }
  return new Date(product.value.license_expiration_date).getTime();
});

const remainedTimeSeconds = computed(() => {
  if (!expiredTime.value) { return false; }
  return Math.floor((expiredTime.value - new Date().getTime()) / 1000);
});

const isSoonExpire = computed(() => {
  if (!remainedTimeSeconds.value) { return false; }
  return (remainedTimeSeconds.value / 60 / 60) < 48;
});

const expirationDate = computed(() => {
  if (!expiredTime.value || !remainedTimeSeconds.value) { return false; }
  if (isSoonExpire.value) {
    return `${t('tooltipLicense')} ${t('tooltipAfter')} ${formatTime(remainedTimeSeconds.value)}`;
  }
  return `${t('tooltipLicense')} ${dayjs(product.value.license_expiration_date).format('DD.MM.YYYY')}`;
});

const productSpendTime = computed(() => {
  const spendTime = product.value.product_active_duration;
  if (!spendTime) { return '–'; }
  return spendTime < 60 ? t('lessOneMinute') : formatTime(spendTime);
});

const productLastLaunch = computed(() => {
  if (!product.value.product_last_start) { return '–'; }
  return `${dayjs(product.value.product_last_start).format('DD.MM.YYYY HH:mm')}`;
});

const tooltipSpendTime = computed(() => {
  let data = `${t('tooltipSpendTime')}: ${productSpendTime.value}</br>`;
  data += `${t('tooltipLastLaunch')}: ${productLastLaunch.value}`;
  return data;
});

const demoCounterTooltipText = computed(
  () => t(
    'youHaveNLaunchesLeft',
    {
      nn: product.value?.demo_counter || 0,
    },
    pluralNoun(product.value?.demo_counter || 0),
  )
);

const handleSubscribeClick = () => {
  // Get from local storage and covert into Set for unique values
  const basket = new Set(getStorageItem<number[]>('basket') || []);
  if (product.value?.offer?.id) {
    // Add offer id of product to select them in shop
    basket.add(product.value?.offer?.id);
  }
  // Update basket
  setStorageItem('basket', Array.from(basket.values()));

  // Go into shop
  router.push({ name: names.shop });
};

const handleClickApp = () => {
  if (!isUser.value) {
    return loginUser();
  }

  if (isMobileConst) {
    isOpen.value = true;
  }

  if (product.value.uuid) {
    try {
      services.sendAccountingAppUrl(product.value.uuid, isMobileConst);
    } catch (err) {
      catchErrorShow(err, t);
    }
  }
};

const launchFunction = async () => {
  isFetching.value = true;
  try {
    if (isUser.value) {
      product.value = await services.getLaboratoryById(props.id);
    } else {
      product.value = await services.getPublicLaboratoryById(props.id);
    }
  } catch (err) {
    catchErrorShow(err, t);
  }
  isFetching.value = false;
};

launchFunction();
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

@media screen and (max-width: map-get($device-breakpoints, tablet)) {
  .lab {
    &__content {
      width: auto;
    }
  }
}

.lab {

  &__picture {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    margin-bottom: 48px;
  }

  &__btn-play {
    height: 36px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
  }

  &__btn-link {
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    margin: 8px 0;
  }

  .lab__btn-play, .lab__btn-link {
    &.el-button+.el-button {
      margin-left: 0;
    }
  }

  &__top-content {
    position: relative;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: end;
    padding-top: 53px;
    padding-bottom: 250px;
    z-index: 1;
    color: $colorText1;
  }

  &__go-back {
    padding: 8px 16px;
    border-radius: 52px;
    font-size: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    background: $bgGrad1;
    border: 1px solid transparent;

    &:hover {
      color: white;
      background: $bgGradPrimary;
    }
    &:active {
      color: white;
      background: $bgGradPrimaryPressed;
    }

    & svg {
      width: 16px;
      height: 24px;
    }
  }

  &__info {
    padding: 24px 44px 18px;
    background: $color-white;
    width: fit-content;
    border-radius: 8px;
    box-shadow: 208px 109px 66px 0 #09293900;
    background: $bgGrad1;
  }

  &__info-more {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
  }

  &__info-expires {
    background: $color-white;
    font-size: 16px;
    border: 1px solid #DBDBDB;
    border-top: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__info-express-text {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &__info-express-alert {
    width: 19px;
    height: 18px;
    background: url("@/assets/img/alert.svg");
    margin-right: 4px;
  }

  &__info-calendar {
    width: 36px;
    height: 36px;
    background: linear-gradient(0deg, #ededed, #d1d1d1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid;
    border-image-source: linear-gradient(15.63deg, #DBDBDB 10.93%, #FFFFFF 89.07%);

    svg {
      width: 20px;
      height: 22px;
    }
  }

  &__subtitle {
    color: $color-primary-darkest;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($colorText1, .5);
    line-height: 1;
  }

  &__title {
    font-size: 44px;
    font-weight: 700;
    color: $color-primary-darkest;
    text-align: right;
    line-height: 1;
    padding-top: 10px;
  }

  &__workbook {
    display: flex;
    width: fit-content;
    padding: 6px 16px;
    font-size: 16px;
    background: $color-peach;
    color: $color-white;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #DBDBDB;

    &-icon {
      font-size: 24px;
    }
  }

  &__content {
    width: $containerW;
    margin: 12px auto;
    background: white;
    border-radius: 12px;

    & p,
    & li,
    & ul,
    & ol {
      margin: revert;
    }

    ul, ol {
      padding-inline-start: 40px;
    }

    .border-bottom {
      border-bottom: 1px solid $colorBlue1;
    }
  }

  $item-margin: 10px 20px;

  &__content-lang {
    display: inline-block;
    margin: $item-margin;
  }

  &__content-link {
    display: inline-flex;
    transition: color .2s;
    margin: $item-margin;

    &:hover {
      color: $colorBlue1;
    }
  }

  &__content-link-svg {
    display: inline-block;
    width: 18px;
    height: 20px;
    background: transparent url("@/assets/img/pdf.svg") no-repeat center / cover;
    margin-right: 10px;
  }
  &__content-link-text {
    text-decoration: underline;
  }

  &__content-carousel {
    display: flex;
    justify-content: center;
  }

  &__content-space {
    padding: 16px 32px;
  }
  &__short {
    display: flex;
    align-items: center;
  }
  &__short-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    color: $colorBlue1;
    display: flex;
    align-items: center;

    svg {
      width: 28px;
      height: 27px;
    }
  }
  &__content-title {
    font-size: 20px;
    font-weight: 500;
  }

}

.lab__background {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.lab__subscribe-wrap {
  padding: 40px;
}

.lab__subscribe {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0 auto;
  max-width: $containerW;
  position: relative;
}

.labs__modal-title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}

.labs__modal-content {
  display: grid;
  gap: 10px;
  justify-content: center;
}

@include media-breakpoint-down("md") {
  .lab__info {
    margin: 0;
    box-shadow: none;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
  .lab__subtitle {
    font-size: 16px;
    border-bottom: none;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .lab__title {
    font-size: 20px;
    text-align: center;
  }
  .lab__top-content {
    padding: 0;
  }
  .lab__background {
    position: relative;
  }
  .lab__subscribe-wrap {
    padding: 20px;
  }
  .lab__subscribe {
    display: grid;
    grid-template-columns: minmax(200px, 250px) min-content;
    gap: 20px;
  }

  .lab__terms {
    padding: 20px;
    margin: 0;

    & > p:first-child {
      margin-top: 0;
    }
  }
}

</style>
