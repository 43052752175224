<template>
  <div
    class="search-switch"
    :class="{ 'active': isActive }"
  >
    <div
      class="search-switch__find"
      @click="switchMobileInput"
    >
      <IconMagnifier />
    </div>
    <CleanInput
      ref="input"
      :value="searchRequest"
      :placeholder="placeholder"
      class="search-switch__input"
      @input="useInputEvent ? handleInput($event) : undefined"
      @keyup.enter="handleSearch"
      @focus="isActive = true"
      @blur="isActive = false"
    />
    <div
      v-if="searchRequest"
      class="search-switch__clean"
      @click="cleanSearchInput"
    >
      <Delete />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import CleanInput from '@/components/inputs/CleanInput.vue';
import IconMagnifier from '@/components/icons/Magnifier.vue';
import Delete from '@/components/icons/Delete.vue';

interface Props {
  value?: string;
  placeholder?: string;
  useInputEvent?: boolean;
}

withDefaults(defineProps<Props>(), {
  value: '',
  placeholder: 'search',
  useInputEvent: false,
});

const emit = defineEmits<{
  (event: 'search', value: string): void
  (event: 'input', value: string): void
}>();

const input = ref();
const isActive = ref(false);
const searchRequest = ref();

const switchMobileInput = () => {
  isActive.value = true;
  nextTick(() => {
    input.value.$el.focus();
  });
};

const handleInput = (val: string) => {
  searchRequest.value = val;
  emit('input', val);
};

const cleanSearchInput = () => {
  input.value?.$el.focus();
  searchRequest.value = '';
  emit('input', '');
};

const handleSearch = () => {
  const val = input.value?.$el.value;
  emit('search', val);
};

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.search-switch {
  min-width: 406px;
  height: $input-height;
  position: relative;
  color: $color-primary;
  border: 1px solid var(--color-acent);
  background: $color-white;
  border-radius: 22px;
  display: flex;

  &__find {
    margin-left: 16px;
    color: var(--color-acent);

    svg {
      width: 24px;
      height: $input-height;
    }
  }

  &__input {
    width: calc(100% - 74px);
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--color-acent);
      font-style: italic;
    }
  }

  &__button {
    align-items: center;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
  }

  &__clean {
    margin-right: 10px;
    cursor: pointer;

    svg {
      width: 24px;
      height: $input-height;
    }
  }
}

@include media-breakpoint-down("sm") {

  .search-switch {
    height: 39px;
    min-width: 39px;
    position: absolute;
    right: 0;
    overflow: visible;

    &__find {
      margin: 0;
      cursor: pointer;

      svg {
        width: 39px;
        height: 39px;
      }
    }

    &__button {
      width: 32px;
      border: 1px solid $color-purple;
      border-radius: 50%;
      color: $color-purple;
    }

    &.active {
      border: 1px solid var(--color-acent);

      .search-switch__input {
        visibility: visible;
      }
    }

    &__input {
      visibility:hidden;
      width: calc(100vw - 44px);
      height: 42px;
      position: absolute;
      right: 0;
      display: inline-block;
      box-shadow: 0 0 10px 10px white;
      font-size: 14px;
      background: white;
      border: 1px solid var(--color-acent);
      border-radius: 8px;

      &::placeholder {
        text-align: center;
        text-transform: lowercase;
      }
    }

    &__clean {
      z-index: 1;
      display: none;
    }
  }

}

</style>
