<template>
  <svg v-bind="attrs">
    <mask
      id="mask0_17360_56012"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect
        width="32"
        height="32"
        fill="#"
      />
    </mask>
    <g mask="url(#mask0_17360_56012)">
      <path
        d="M8.33268 22.3327V11.666H5.66602V9.66602H10.3327V22.3327H8.33268ZM14.389 22.3327L18.384 15.5993L14.789 9.66602H17.153L19.5583 13.6403L21.979 9.66602H24.2764L20.748 15.5993L24.743 22.3327H22.379L19.5327 17.5583L16.6863 22.3327H14.389Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
