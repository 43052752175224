<template>
  <button
    :class="`${openButtonCssClass}`"
    @click="dialogVisible = true"
  >
    <slot name="video-preview" />
    <slot name="btn-text">
      {{ openButtonText }}
    </slot>
  </button>

  <ElDialog
    v-if="dialogVisible"
    v-model="dialogVisible"
    :width="width"
    :class="`${cssClass}-modal`"
    :beforeClose="handleModalClose"
    :showClose="false"
    :closeOnPressEscape="false"
    :alignCenter="true"
  >
    <template #header="{ close, titleId, titleClass }">
      <div
        v-show="isHeaderVisible"
        ref="$header"
        class="videoplayer-modal__header"
      >
        <h3
          :id="titleId"
          :class="titleClass"
        >
          {{ title }}
        </h3>
        <button
          class="videoplayer-modal__close-btn videoplayer__btn"
          aria-label="Закрыть"
          @click="close"
        >
          <Cross />
        </button>
      </div>
    </template>

    <div
      ref="$container"
      :class="`${cssClass}__container`"
    >
      <div
        ref="$playbackAnimation"
        :class="`${cssClass}__playback-animation`"
      >
        <IconPlay v-if="!isPaused" />
        <IconPause v-else />
      </div>

      <video
        ref="$video"
        :src="path"
        width="960px"
        height="auto"
        :class="`${cssClass}`"
        preload="metadata"
        :autoplay="autoplay"
        :muted="isMuted"
        @loadedmetadata="initializeVideo"
        @timeupdate="updateProgress"
        @mousemove="showControls"
        @volumechange="updateCurrentVolume"
        @play="isPaused = false"
        @pause="isPaused = true"
        @ended="isPaused = true"
        @stalled="isPaused = true"
        @click="handlePlay"
      />

      <div
        v-show="areControlsVisible"
        ref="$controls"
        :class="`${cssClass}__controls`"
        @mousemove="showControls"
      >
        <Play
          :isPaused="isPaused"
          @click="handlePlay"
        />

        <VideoProgress
          :progress="elapsed"
          :duration="duration"
          @skipTo="handleSkip"
        />

        <Volume
          :currentVolume="currentVolume"
          :isMuted="isMuted"
          @click="handleMute"
          @volumeInput="handleVolumeChange"
        />

        <PlaybackRate @click="handlePlaybackRate" />

        <Fullscreen
          :isFullscreen="isFullscreen"
          @click="handleFullscreen"
        />
      </div>
    </div>
  </ElDialog>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { ElDialog } from 'element-plus';
import { debounce } from '@/utils/debounce';

import Play from '@/components/VideoPlayer/Buttons/Play.vue';
import Volume from '@/components/VideoPlayer/Buttons/Volume.vue';
import PlaybackRate from '@/components/VideoPlayer/Buttons/PlaybackRate.vue';
import Fullscreen from '@/components/VideoPlayer/Buttons/Fullscreen.vue';
import VideoProgress from '@/components/VideoPlayer/VideoProgress.vue';
import Cross from '@/components/icons/videoplayer/Cross.vue';

import IconPlay from '@/components/icons/videoplayer/Play.vue';
import IconPause from '@/components/icons/videoplayer/Pause.vue';

defineProps<{
  path: string,
  width: number | string,
  title: string,
  autoplay: boolean,
  openButtonCssClass?: string,
  openButtonText: string,
}>();

const dialogVisible = ref<boolean>(false);

const $header = ref<HTMLElement | null>(null);
const $container = ref<HTMLElement | null>(null);
const $video = ref<HTMLVideoElement | null>(null);
const $controls = ref<HTMLElement | null>(null);
const $playbackAnimation = ref<HTMLElement | null>(null);

const duration = ref<number>(0);
const elapsed = ref<number>(0);
const currentVolume = ref<number>(1);

const isPaused = ref<boolean>(true);
const isMuted = ref<boolean>(false);
const isFullscreen = ref<boolean>(false);
const isHeaderVisible = ref<boolean>(true);
const areControlsVisible = ref<boolean>(true);

const cssClass = 'videoplayer';

const initializeVideo = (): void => {
  if (!$video.value) { return; }

  duration.value = Math.round($video.value.duration);
};

const animatePlayback = (): void => {
  $playbackAnimation?.value?.animate([
    {
      opacity: 1,
      transform: 'scale(1)',
    },
    {
      opacity: 0,
      transform: 'scale(1.3)',
    },
  ], {
    duration: 500,
  });
};

const hideControls = debounce((): void => {
  if (!$video.value || $video.value.paused) { return; }

  if ($controls.value && $header.value) {
    areControlsVisible.value = false;
    isHeaderVisible.value = false;
  }
}, 3000);

const showControls = (): void => {
  if (
    $controls.value
    && $header.value
    && !areControlsVisible.value
  ) {
    areControlsVisible.value = true;

    if (!isFullscreen.value) {
      isHeaderVisible.value = true;
    }
  }

  hideControls();
};

const handlePlay = (): void => {
  if (!$video.value) { return; }

  if ($video.value.paused || $video.value.ended) {
    $video.value.play();
    hideControls();
  } else {
    $video.value.pause();
    showControls();
  }

  animatePlayback();
};

const updateProgress = (): void => {
  if (!$video.value) { return; }
  elapsed.value = Math.round($video.value.currentTime);
};

const handleMute = (value: boolean): void => {
  if (!$video.value) { return; }
  isMuted.value = value;
};

const toggleMuteWithKey = (): void => {
  if (!$video.value) { return; }
  isMuted.value = !isMuted.value;
};

const handleVolumeChange = (volume: number): void => {
  if (!$video.value) { return; }
  $video.value.volume = volume;
  isMuted.value = $video.value.volume === 0;
};

const updateCurrentVolume = () => {
  if (!$video.value) { return; }
  currentVolume.value = $video.value.volume;
};

const handlePlaybackRate = (rate: number): void => {
  if (!$video.value) { return; }
  $video.value.playbackRate = rate;
};

const handleFullscreen = (): void => {
  if ($container.value) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if ((document as any).webkitFullscreenElement) {
      (document as any).webkitExitFullscreen();
    } else if (($container.value as any).webkitRequestFullscreen) {
      ($container.value as any).webkitRequestFullscreen();
    } else {
      $container.value.requestFullscreen();
    }
  }
};

const handleSkip = (skipTo: number): void => {
  if (!$video.value) {
    return;
  }

  $video.value.currentTime = skipTo;
};

const keyboardShortcuts = (event: { code: string; }): void => {
  const { code } = event;

  // eslint-disable-next-line default-case
  switch (code) {
    case 'Space':
      handlePlay();
      break;
    case 'KeyM':
      toggleMuteWithKey();
      break;
    case 'KeyF':
    case 'Escape':
      handleFullscreen();
      break;
  }
};

const handleModalClose = (done: () => void) => {
  if (!$video.value) { return; }
  $video.value.pause();
  done();
};

onMounted(() => {
  const fullscreenChange: string[] = [
    'fullscreenchange',
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'msfullscreenchange',
  ];

  fullscreenChange.forEach((event) => {
    document.addEventListener(event, () => {
      isFullscreen.value = Boolean(document.fullscreenElement);
    });
  });

  document.addEventListener('keyup', keyboardShortcuts);
});

onUnmounted(() => {
  document.removeEventListener('keyup', keyboardShortcuts);
});
</script>

<style lang="scss">
@import "@/assets/style/include.scss";
$controls-background: linear-gradient(180deg, #FFF 0%, #F2F2F2 100%), linear-gradient(270deg, #EDF2FF 0%, #F9FBFF 99.67%);

.videoplayer-modal {

  &.el-dialog {
    --el-dialog-padding-primary: 0;
    --el-dialog-title-font-size: 16px;
    --el-dialog-bg-color: transparent;

    border-radius: 8px;

    & .videoplayer-modal__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 10px 22px;
      background: $controls-background;
      z-index: 1;
      transition: $transition-speed ease-in;
    }

    & .videoplayer-modal__close-btn {
      width: 16px;
      height: 16px;
      padding: 0;
    }

    & .el-dialog__title {
      margin-bottom: 0;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 500;
    }
  }
}

.videoplayer {
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &__container {
    display: flex;
    position: relative;
  }

  &__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px 16px;
    background: $controls-background;
    transition: $transition-speed ease-in;
  }

  &__btn {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    padding: 4px;
    border: none;
    line-height: 1;
    color: $font-color;
    background-color: transparent;
    transition: color $transition-speed;

    &:hover {
      color: $color-primary;
    }

    &:active {
      color: $btn-bg-primary_active;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &__tooltip {
    position: absolute;
    margin-left: -20px;
    border-radius: 2px;
    padding: 4px 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: $color-white;
    background-color: $font-color;

    &::after {
      position: absolute;
      content: '';
      top: 95%;
      left: 25%;
      width: 50%;
      height: 25px;
      background-color: $font-color;
      transform: rotate(180deg);
      clip-path: polygon(50% 70%, 0% 100%, 100% 100%);
    }
  }

  &__playback-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsla(0, 0%, 100%, .8);
    opacity: 0;
    pointer-events: none;

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
