<template>
  <Modal
    v-if="isOpen"
    class="labs__modal"
    width="330"
    @close="handleClose"
  >
    <template #header>
      <h2 class="labs__modal-title">
        {{ t("labs.oops") }}
      </h2>
    </template>
    <div class="labs__modal-content">
      <p>
        {{ t("labs.mobileModalText") }}
      </p>
      <VButton
        appearance="solid"
        size="wide"
        color="primary"
        class="lab__btn"
        @click="handleClose"
      >
        {{ t("labs.clearly") }}
      </VButton>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import VButton from '@/components/VButton.vue';
import Modal from '@/components/Modal.vue';

defineProps<{
  isOpen: boolean,
}>();

const emits = defineEmits<{
  (event: 'close'): void,
}>();

const { t } = useI18n();

const handleClose = () => {
  emits('close');
};
</script>
