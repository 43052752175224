<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_17687_60745)">
      <g mask="url(#mask0_17687_60745)">
        <path
          d="M3.373 11.75L15.5 11.75L15.5 13.25L3.373 13.25L9.06925 18.9462L8 20L0.500001 12.5L8 5L9.06925 6.05375L3.373 11.75Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 16, height: 25 });
</script>
