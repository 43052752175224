<template>
  <svg v-bind="attrs">
    <path
      d="M7 12C6.44772 12 6 11.5523 6 11V7C6 6.44772 6.44772 6 7 6H11C11.5523 6 12 6.44772 12 7V11C12 11.5523 11.5523 12 11 12H7ZM8 10H10V8H8V10ZM6 18V16H3C2.44772 16 2 15.5523 2 15V12H0V10H2V8H0V6H2V3C2 2.44772 2.44772 2 3 2H6V0H8V2H10V0H12V2H15C15.5523 2 16 2.44772 16 3V6H18V8H16V10H18V12H16V15C16 15.5523 15.5523 16 15 16H12V18H10V16H8V18H6ZM14 14V4H4V14H14Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 18 });
</script>
