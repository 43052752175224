import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

import laboratory from './Laboratory/routes';
import Laboratories from './index.vue';

const laboratories: Array<RouteRecordRaw> = [
  {
    path: paths.laboratories,
    name: names.laboratories,
    component: Laboratories,
    meta: {
      permissions: permissions.laboratory,
      requiresAuth: false,
      isAuthed: false,
    },
  },
  ...laboratory,
];

export default laboratories;
