<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_19906_33841)">
      <rect
        y="20"
        width="7"
        height="20"
        transform="rotate(-90 0 20)"
        fill="#FECA00"
      />
      <rect
        y="13"
        width="6"
        height="20"
        transform="rotate(-90 0 13)"
        fill="#0052B4"
      />
      <rect
        y="7"
        width="7"
        height="20"
        transform="rotate(-90 0 7)"
        fill="#E31D1C"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 20 });
</script>
