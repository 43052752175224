export enum LinksRolesEnum {
  Admin = 'admin',
  Teacher = 'teacher',
  Student = 'student',
}

export enum DocumentTypeEnum {
  Document = 'document',
  Video = 'video',
  Link = 'link',
}
export type DocumentType =
    DocumentTypeEnum.Document | DocumentTypeEnum.Video | DocumentTypeEnum.Link

export type LinkRole = LinksRolesEnum.Admin | LinksRolesEnum.Teacher | LinksRolesEnum.Student;

export type MediaFileType = MediaFileEnum.File | MediaFileEnum.Link | ''

export enum FileStatusEnum {
  not_loaded = 'not_loaded',
  progress = 'in_progress',
  loaded = 'loaded',
}

export type Lang = 'ru' | 'en' | 'fr' | 'hy';

export interface Locale {
  id?: number;
  locale: string, // ru
  caption: string, // текст на кнопке
  enabled: number, // 0 | 1
  file_type?: MediaFileType,
  file: string // ссылка либо filename c s3 (облако яндекс)
  url?: string
  name?: string
  file_status?: FileStatusEnum,
  is_file_changed: number, // 0 | 1
  filledLang: string // спец функционал со сменой цвета - добавление перехода https://wiki.mos.social/pages/viewpage.action?pageId=821924399
}

export interface Sortable {
  order: number;
}

export enum UniversalManualsEnum {
  Tournament = 'tournament',
  Workbook = 'workbook',
  Saving = 'saving',
}

export type ManualFlags =
    UniversalManualsEnum.Tournament |
    UniversalManualsEnum.Workbook |
    UniversalManualsEnum.Saving | ''

export interface UniversalManualsRequest {
  manual_type?: DocumentTypeEnum | null,
  flag?: ManualFlags | null
}

export interface GeneralManualsAddParams {
  manual_type: DocumentTypeEnum | null,
  manuals: Manual[]
}

export interface GeneralManual extends Manual {
  parent_id: number
  flag: ManualFlags
}

export enum ManualViewTypeEnum {
  open = 'open',
  embed = 'embed',
}
export type ManualViewType = ManualViewTypeEnum.open | ManualViewTypeEnum.embed

export interface MediaFile extends Sortable {
  id?: number | null,
  parent_id?: number
  hash: string;
  for_roles?: LinkRole[];
  type?: MediaFileType, // local using
  file_type?: DocumentType,
  locales: Locale[],
  isDisabled?: boolean
  file_status?: FileStatusEnum,
  flag?: ManualFlags,
  view_type?: ManualViewType,
}

export enum MediaFileEnum {
  File = 'file',
  Link = 'link',
}

export interface Link extends MediaFile {
  is_mesh_card_url: 0 | 1;
  isDisabled?: boolean
}

export interface Manual {
  id?: number | null | undefined;
  parent_id?: number;
  order: number;
  file_type: DocumentType;
  type?: MediaFileType;
  flag?: ManualFlags;
  locales: Locale[];
  view_type?: ManualViewType;
  hash: string,
  for_roles?: LinkRole[];
}

export interface CheckBoxFlag {
  value: ManualFlags,
  label: string,
  checked: boolean,
}

export enum MediaFields {
  VideoInstructions = 'video_instructions',
  UsersGuides = 'users_guides',
  Links = 'links',
}


export enum ProductStateEnum {
  Draft = 'draft',
  Complete = 'complete'
}
export type ProductStateType = ProductStateEnum.Draft | ProductStateEnum.Complete

export interface NewProductInfo {
  mesh_description: string | null;
  subject_program_id: number | null;
  classes: Array<number>;
  section_id: number | null;
  didactic_unit_ids: Array<number>;
}

export interface NewProduct extends NewProductInfo {
  active: number;
  enabled: number;
  mesh_name: string;
  description: string;
  authors_signature: string;
  system_requirements: string;
  system_requirements_type: 'standard' | 'individual';
  mesh_id?: number | null;
  app_url: string;
  product_in_mesh: number;
  mesh_page_url?: string;
  registry_number: string | null;
  article_number: string | null;
  img_cover?: null | string;
  img_background?: null | string;
  users_guides: Array<MediaFile>;
  links: Link[];
  video_instructions: Array<MediaFile>;
  manuals: Manual[],
  subject_program_inner_card?: number;
  product_id?: number,
  is_workbook?: number,
  material_type: string,
  material_business_type?: string | null,
  studying_level_id?: number | null,
  product_will_be_created_in_mesh?: number,
  published: number,
  universal_files_usage: string[],
  state: ProductStateType;
  custom_meta: Record<string, any>
  keycloak_id: string
}

export interface SysReqResponseBackend {
  locale: Lang,
  text: string,
}
export interface SysReq {
  data: SysReqResponseBackend[]
}
