import { ProfileField } from '@/types';

export const firstname: ProfileField = Object.freeze({
  label: 'labels.firstname',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'firstname',
});

export const surname: ProfileField = Object.freeze({
  label: 'labels.surname',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'surname',
});

export const patronymic: ProfileField = Object.freeze({
  label: 'labels.patronymic',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'patronymic',
});

export const email: ProfileField = Object.freeze({
  label: 'labels.email',
  value: '',
  oldValue: '',
  type: 'email',
  apiName: 'email',
});

export const role: ProfileField = Object.freeze({
  label: 'labels.role',
  value: '',
  oldValue: '',
  type: 'select',
  apiName: 'role_id',
});

export const organization: ProfileField = Object.freeze({
  label: 'labels.organization1',
  value: '',
  oldValue: '',
  type: 'ajax-select',
  apiName: 'organization',
});

export const country: ProfileField = Object.freeze({
  label: 'labels.country',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'country',
});

export const region: ProfileField = Object.freeze({
  label: 'labels.region',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'region',
});

export const city: ProfileField = Object.freeze({
  label: 'labels.city',
  value: '',
  oldValue: '',
  type: 'text',
  apiName: 'city',
});

export const password: ProfileField = Object.freeze({
  label: 'labels.password',
  value: '',
  oldValue: '',
  type: 'password',
  apiName: 'password',
});

export const local_roles: ProfileField = Object.freeze({
  label: 'labels.localRoles',
  value: [],
  oldValue: [],
  type: 'select',
  multiple: true,
  apiName: 'local_roles',
});

export const studyClass: ProfileField = Object.freeze({
  label: 'labels.studyClass',
  value: '',
  oldValue: '',
  required: false,
  type: 'select',
  apiName: 'class',
});

export const lang: ProfileField = Object.freeze({
  label: 'labels.lang',
  value: '',
  oldValue: '',
  required: false,
  type: 'select',
  apiName: 'locale',
});

export default {
  firstname,
  surname,
  patronymic,
  email,
  role,
  organization,
  country,
  region,
  city,
  password,
  local_roles,
  studyClass,
  lang,
};
