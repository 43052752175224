export const pluralNoun = (length: number): 0 | 1 | 2 => {
  let n = Math.abs(length) % 100;

  if (n >= 5 && n <= 20) {
    return 2;
  }

  n %= 10;

  if (n === 1) {
    return 0;
  }

  if (n > 1 && n < 5) {
    return 1;
  }

  return 2;
};

export default {
  pluralNoun,
};
