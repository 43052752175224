import { computed, ComputedRef } from 'vue';
import store from '@/store';
import { isAdmin } from '@/utils/user';
import { roles } from '@/constants/roles';

type UseRoleActionAccess = {
  role: ComputedRef<string>;
  roleId: ComputedRef<number>;
  selfProfileId: ComputedRef<number | null>;
  isModer: ComputedRef<boolean>;
  canDoAction: (id: number, userRole: string) => boolean;
  canDoActionByRoleId: (id: number) => boolean;
  canDoActionByArray: (rolesArray: Array<string>) => boolean;
};

export const useRoleActionAccess = (): UseRoleActionAccess => {
  const role = computed<string>(() => store.getters.role);
  const roleId = computed<number>(() => store.getters.roleId);
  const selfProfileId = computed<number | null>(() => store.getters.userId);
  const isModer = computed(() => role.value === roles.moderator);

  const canDoActionByRoleId = (id: number): boolean => roleId.value < id;
  const canDoActionByArray = (
    rolesArray: Array<string>
  ): boolean => rolesArray.includes(role.value);

  const canDoAction = (id: number, userRole: string): boolean => {
    if (isAdmin(userRole)) {
      return true;
    }

    return canDoActionByRoleId(id);
  };

  return {
    role,
    roleId,
    selfProfileId,
    isModer,
    canDoAction,
    canDoActionByRoleId,
    canDoActionByArray,
  };
};

export default useRoleActionAccess;
