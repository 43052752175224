<template>
  <div class="side-menu">
    <Tooltip
      position="left"
      :message="t('addApp')"
      class="side-menu__tooltip"
    >
      <div
        class="side-menu__item"
        @click="showCreateMenu = true"
      >
        <IconPlus />
      </div>
    </Tooltip>

    <Tooltip
      position="left"
      :message="t('commonVideo')"
      class="side-menu__tooltip"
      @click="isVideoDialogVisible = true"
    >
      <div class="side-menu__item">
        <IconVideos />
      </div>
    </Tooltip>

    <Tooltip
      position="left"
      :message="t('commonDocs')"
      class="side-menu__tooltip"
      @click="isDocsDialogVisible = true"
    >
      <div class="side-menu__item">
        <IconDocs />
      </div>
    </Tooltip>

    <Tooltip
      position="left"
      :message="t('systemReqs')"
      class="side-menu__tooltip"
      @click="isSystemReqVisible = true"
    >
      <div class="side-menu__item">
        <IconSystemReqs />
      </div>
    </Tooltip>

    <div
      v-show="showCreateMenu"
      class="side-menu__add"
    >
      <div class="side-menu__add-top">
        <div class="side-menu__add-title">
          {{ t('createProductCard') }}
        </div>
        <div
          class="side-menu__add-close"
          @click="showCreateMenu = false"
        >
          <IconCross />
        </div>
      </div>
      <div class="side-menu__add-cards">
        <router-link
          :to="paths.addProduct"
          class="side-menu__add-card"
        >
          <div class="side-menu__add-card-name">
            {{ t('shop.product') }}
          </div>
          <div class="side-menu__add-card-img" />
          <div class="side-menu__add-card-text">
            {{ t('someText1') }}
          </div>
        </router-link>
        <router-link
          :to="paths.addAnnouncement"
          class="side-menu__add-card"
        >
          <div class="side-menu__add-card-name">
            {{ t('labelAnnouncement') }}
          </div>
          <div class="side-menu__add-card-img" />
          <div class="side-menu__add-card-text">
            {{ t('someText2') }}
          </div>
        </router-link>
      </div>
    </div>

    <ManualsGeneral
      v-if="isVideoDialogVisible"
      v-model="isVideoDialogVisible"
      :type="DocumentTypeEnum.Video"
      @close="isVideoDialogVisible = false"
    />

    <ManualsGeneral
      v-if="isDocsDialogVisible"
      v-model="isDocsDialogVisible"
      :type="DocumentTypeEnum.Document"
      @close="isDocsDialogVisible = false"
    />
  </div>

  <DialogSystemReq
    v-if="isSystemReqVisible"
    v-model="isSystemReqVisible"
    @close="isSystemReqVisible = false"
  />

  <div
    v-show="showCreateMenu"
    class="side-menu__backdrop"
    @click="showCreateMenu = false"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import IconPlus from '@/components/icons/Plus.vue';
import IconVideos from '@/components/icons/Videos.vue';
import IconDocs from '@/components/icons/Docs.vue';
import IconSystemReqs from '@/components/icons/SystemReqs.vue';
import IconCross from '@/components/icons/Cross.vue';
import Tooltip from '@/components/Tooltip.vue';
import { paths } from '@/constants/routes';
import { DocumentTypeEnum } from '@/types/manuals';
import ManualsGeneral from '@/components/Form/ManualsGeneral.vue';
import DialogSystemReq from '@/components/Form/DialogSystemReq.vue';

const { t } = useI18n();

const showCreateMenu = ref(false);
const isVideoDialogVisible = ref(false);
const isDocsDialogVisible = ref(false);
const isSystemReqVisible = ref(false);

watch(isVideoDialogVisible, () => {
  showCreateMenu.value = false;
});
watch(isDocsDialogVisible, () => {
  showCreateMenu.value = false;
});
watch(isSystemReqVisible, () => {
  showCreateMenu.value = false;
});
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.side-menu {
  position: fixed;
  z-index: 10;
  top: 300px;
  right: 12px;
  background: $colorBlue1;
  padding: 6px;
  border-radius: 16px 0 0 16px;
  color: white;
  box-shadow: 2px 4px 4px 0 #00000038;

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }

  &__tooltip {

    :deep(.side-menu__tooltip) {
      right: 66px;
    }
  }

  &__item {
    padding: 15px;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: $colorBlueHover;
    }
    &:active {
      background: $colorBluePressed;
    }
  }

  &__add {
    position: absolute;
    left: -25px;
    top: 0;
    transform: translate(-100%, -30%);
    padding: 32px;
    background: $colorBlue1;
    border-radius: 22px;
  }
  &__add-top {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
  }
  &__add-title {
    font-size: 26px;
    font-weight: 500;
  }
  &__add-close {
    width: 42px;
    height: 42px;
    line-height: 42px;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;

    &:hover {
      background: $colorBlueHover;
    }

    svg {
      transform: translateY(2px);
    }
  }
  &__add-cards {
    display: flex;
    gap: 22px;
    margin-top: 38px;
  }
  &__add-card {
    width: 308px;
    background: white;
    border-radius: 12px;
    font-size: 16px;
    border: 1px solid $colorBlue1;
    transition: .2s;

    &:hover {
      border: 1px solid $colorBlueHover;
    }

    &:nth-child(1) {
      & .side-menu__add-card-img {
        background: url("@/assets/img/card.jpg");
      }
    }
    &:nth-child(2) {
      & .side-menu__add-card-img {
        background: url("@/assets/img/announcement.jpg");
      }
    }
  }
  &__add-card-name {
    height: 44px;
    color: $colorBlue1;
    font-weight: 700;
    padding: 0 20px;
    line-height: 44px;
    text-transform: uppercase;
  }
  &__add-card-img {
    height: 180px;
  }
  &__add-card-text {
    height: 84px;
    color: $colorText1;
    font-weight: 400;
    padding: 12px 20px;
  }
}

@media screen and (max-width: map-get($device-breakpoints, tablet)) {
  .side-menu {
    display: none;
  }
}

</style>
