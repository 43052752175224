export const generateHash = (length: number): string => {
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  return [...Array(length)]
    .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
    .join('');
};

export default {
  generateHash,
};
