<template>
  <input
    v-if="type !== 'textarea'"
    v-bind="{
      ...attrs,
      readonly: static,
      tabindex: static ? -1 : 0,
      type,
    }"
    :key="key"
    :value="value"
    @input="handleInput"
    @keydown="handleKeydown"
    @focus="handleFocus"
    @blur="handleBlur"
  />

  <textarea
    v-else
    v-bind="{
      ...attrs,
      readonly: static,
      tabindex: static ? -1 : 0,
      type,
    }"
    :key="key"
    :value="value"
    @input="handleInput"
    @keydown="handleKeydown"
    @focus="handleFocus"
    @blur="handleBlur"
  />
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, toRefs } from 'vue';

import { inputKeysMap } from '@/constants/keys';

import {
  defaultProps,
  defaultEmits,
  mapType,
  filterNumberInput,
} from './constants';
import { HandlerContext } from './types';

const createInputHandler = (
  context: HandlerContext,
  emit: Function
): Function => (e: KeyboardEvent): void => {
  const target = e.target as HTMLInputElement;

  if (context.props.type === 'number') {
    target.value = target.value.split('').filter(filterNumberInput).join('');
    target.setCustomValidity(
      Number.isNaN(Number(target.value)) ? 'Not a number' : ''
    );
  }

  emit('input', target.value);
};

const createKeydownHandler = (
  context: HandlerContext,
  emit: Function,
): Function => (e: KeyboardEvent): void => {
  if (context.props.type === 'number') {
    const target = e.target as HTMLInputElement;
    const key = e.keyCode ? e.keyCode : e.which;
    const min = Number(context.attrs.min || -1 / 0);
    const max = Number(context.attrs.max || 1 / 0);
    const step = Number(context.attrs.step || 1);

    if (key === inputKeysMap.ARROW_UP) {
      e.preventDefault();

      emit('input', Math.min(Number(target.value) + step, max));
    } else if (key === inputKeysMap.ARROW_DOWN) {
      e.preventDefault();

      emit('input', Math.max(Number(target.value) - step, min));
    }
  }

  emit(e.type, e);
};

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input',
  },
  props: defaultProps,
  emits: defaultEmits,
  setup(props, context) {
    const { attrs, emit } = context;
    const currentInstance = getCurrentInstance();
    const refProps = toRefs(props);
    const type: string = mapType(String(refProps.type.value));

    const handlerContext: HandlerContext = {
      props: {
        ...refProps,
        type,
      },
      attrs,
    };

    return {
      ...refProps,
      attrs,
      type,
      key: currentInstance?.vnode.key,
      handleInput: createInputHandler(handlerContext, emit),
      handleKeydown: createKeydownHandler(handlerContext, emit),
      handleFocus: (ev: Event) => emit('focus', ev),
      handleBlur: (ev: Event) => emit('blur', ev),
    };
  },
});
</script>
