import services from '@/services/admin';
import { setBuilderLocales } from '@/router/Admin/builderManuals';

export const Locales = {
  namespaced: true,
  state() {
    return {
      supportedLocales: [],
      requiredLocales: [],
      locales: [],
    };
  },
  getters: {
    supportedLocales: (state) => state.supportedLocales.map((x) => x.locale),
    requiredLocales: (state) => state.requiredLocales.map((x) => x.locale),
    locales: (state) => state.supportedLocales.map((lang) => {
      const isRequired = Boolean(state.requiredLocales
        .find((loc) => loc.locale === lang.locale));

      return {
        value: lang.locale,
        isRequired,
      };
    }),
  },
  actions: {
    async getSupportedLocales({ commit }) {
      const data = await services.getSupportedLocales();
      commit('SET_SUPPORTED_LOCALES', data);
    },
    async getRequiredLocales({ commit }) {
      const data = await services.getRequiredLocales();
      commit('SET_REQUIRED_LOCALES', data);
    },
  },
  mutations: {
    SET_SUPPORTED_LOCALES: (state, data) => {
      state.supportedLocales = data;
      setBuilderLocales(state.supportedLocales.map((x) => x.locale));
    },
    SET_REQUIRED_LOCALES: (state, data) => {
      state.requiredLocales = data;
    },
  },
};

export default {
  Locales,
};
