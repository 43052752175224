<template>
  <svg v-bind="attrs">
    <path
      d="M0.0205926 12.3842L0.0452547 5.71763L4.99389 5.73594L10.7327 0.0394582L10.6657 18.1413L4.96923 12.4026L0.0205926 12.3842ZM8.71473 4.88303L5.83748 7.73907L2.03751 7.72501L2.02764 10.3917L5.82762 10.4057L8.68366 13.283L8.71473 4.88303Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 11, height: 19 });
</script>
