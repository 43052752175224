<template>
  <svg v-bind="attrs">
    <path
      d="M13.4019 7.77297C13.6467 7.61194 13.769 7.38952 13.769 7.10572C13.769 6.82193 13.6467 6.59846 13.4019 6.43529L9.34364 3.80018C9.07542 3.62248 8.80219 3.61522 8.52393 3.77838C8.24567 3.94153 8.10653 4.17901 8.10653 4.4908V9.71745C8.10653 10.0293 8.24567 10.2667 8.52393 10.4299C8.80219 10.593 9.07542 10.5858 9.34364 10.4081L13.4019 7.77297ZM5.75972 14.3826C5.1636 14.3826 4.65752 14.1746 4.24148 13.7585C3.82545 13.3425 3.61743 12.8364 3.61743 12.2403V2.14232C3.61743 1.54619 3.82545 1.04011 4.24148 0.624076C4.65752 0.208025 5.1636 0 5.75972 0H15.8577C16.4538 0 16.9599 0.208025 17.3759 0.624076C17.792 1.04011 18 1.54619 18 2.14232V12.2403C18 12.8364 17.792 13.3425 17.3759 13.7585C16.9599 14.1746 16.4538 14.3826 15.8577 14.3826H5.75972ZM5.75972 12.5085H15.8577C15.9248 12.5085 15.9862 12.4806 16.0421 12.4247C16.098 12.3688 16.1259 12.3073 16.1259 12.2403V2.14232C16.1259 2.07526 16.098 2.01378 16.0421 1.95789C15.9862 1.90202 15.9248 1.87408 15.8577 1.87408H5.75972C5.69266 1.87408 5.63119 1.90202 5.57532 1.95789C5.51943 2.01378 5.49149 2.07526 5.49149 2.14232V12.2403C5.49149 12.3073 5.51943 12.3688 5.57532 12.4247C5.63119 12.4806 5.69266 12.5085 5.75972 12.5085ZM2.14229 18C1.54618 18 1.0401 17.792 0.624054 17.3759C0.208018 16.9599 0 16.4538 0 15.8577V4.82269C0 4.56455 0.0916348 4.34384 0.274904 4.16057C0.458174 3.9773 0.678882 3.88567 0.937029 3.88567C1.19518 3.88567 1.41589 3.9773 1.59917 4.16057C1.78244 4.34384 1.87408 4.56455 1.87408 4.82269V15.8577C1.87408 15.9248 1.90202 15.9862 1.95789 16.0421C2.01378 16.098 2.07525 16.1259 2.14229 16.1259H13.1773C13.4355 16.1259 13.6562 16.2176 13.8395 16.4008C14.0227 16.5841 14.1144 16.8048 14.1144 17.063C14.1144 17.3211 14.0227 17.5418 13.8395 17.7251C13.6562 17.9084 13.4355 18 13.1773 18H2.14229Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 18 });
</script>
