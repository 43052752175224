<template>
  <button
    :class="`${cssClass}__btn`"
    :aria-label="isPaused ? 'Пуск' : 'Пауза'"
    @click="emits('click')"
  >
    <IconPlay v-if="isPaused" />
    <IconPause v-else />
  </button>
</template>

<script setup lang="ts">
import IconPlay from '@/components/icons/videoplayer/Play.vue';
import IconPause from '@/components/icons/videoplayer/Pause.vue';

defineProps<{
  isPaused: boolean,
}>();

const emits = defineEmits(['click']);

const cssClass = 'videoplayer';
</script>
