<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_15983_38460)">
      <path
        d="M3.82814 11.0578C4.74719 11.0578 5.49222 10.3173 5.49222 9.40388C5.49222 8.49047 4.74719 7.75 3.82814 7.75C2.9091 7.75 2.16406 8.49047 2.16406 9.40388C2.16406 10.3173 2.9091 11.0578 3.82814 11.0578Z"
        fill="currentColor"
      />
      <path
        d="M4.50489 15.5009C4.50489 14.4481 4.50766 12.7942 4.50766 11.6556C4.29429 11.6278 4.0794 11.6131 3.86422 11.6115C3.22863 11.5967 2.59757 11.7214 2.01604 11.9768C1.43452 12.2322 0.916867 12.6119 0.5 13.089V15.2253C0.5 15.2984 0.52922 15.3685 0.581233 15.4202C0.633246 15.4719 0.70379 15.5009 0.777347 15.5009H4.50489Z"
        fill="currentColor"
      />
      <path
        d="M3.27636 15.4999C3.27636 14.4471 3.27359 12.7933 3.27359 11.6547C3.48696 11.6269 3.70185 11.6121 3.91703 11.6106C4.55262 11.5957 5.18368 11.7204 5.76521 11.9758C6.34673 12.2312 6.86438 12.611 7.28125 13.088V15.2243C7.28125 15.2974 7.25203 15.3675 7.20002 15.4192C7.148 15.4709 7.07746 15.4999 7.0039 15.4999H3.27636Z"
        fill="currentColor"
      />
      <path
        d="M14.1764 1.76141C14.1764 1.90495 14.1512 2.02457 14.1006 2.12026L11.4965 7.29978C11.1846 7.9218 10.8096 8.38034 10.3714 8.6754C9.93313 8.97046 9.36848 9.11799 8.67741 9.11799L8.38666 9.10603C8.17597 9.09008 7.99899 9.01432 7.85572 8.87875C7.71245 8.73521 7.64081 8.56376 7.64081 8.36439C7.64081 8.15705 7.71666 7.98161 7.86836 7.83807C8.02005 7.68655 8.20125 7.6068 8.41194 7.59883L8.51307 7.61079H8.57628C8.85439 7.60281 9.08194 7.543 9.25892 7.43136C9.4359 7.31971 9.60445 7.13231 9.76458 6.86915L6.9961 2.16811C6.92025 2.04849 6.88232 1.90894 6.88232 1.74944C6.88232 1.51818 6.97081 1.3228 7.14779 1.16331C7.32478 0.995843 7.53547 0.912109 7.77987 0.912109C8.15911 0.912109 8.42458 1.06363 8.57628 1.36666L10.7127 5.24233L12.4446 1.43843C12.5963 1.08755 12.8744 0.912109 13.2789 0.912109C13.5317 0.912109 13.7424 0.995843 13.911 1.16331C14.088 1.33078 14.1764 1.53014 14.1764 1.76141Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_15983_38460">
        <rect
          width="15.5"
          height="15.5"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 16, height: 16 });
</script>
