<template>
  <svg v-bind="attrs">
    <mask
      id="mask0_17360_56011"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect
        width="32"
        height="32"
        fill="#"
      />
    </mask>
    <g mask="url(#mask0_17360_56011)">
      <path
        d="M7.25586 22.3327V14.9993H12.5892V11.666H7.25586V9.66602H14.5892V16.9993H9.25586V20.3327H14.5892V22.3327H7.25586ZM16.1792 22.3327L20.0509 15.871L16.3329 9.66602H18.4099L21.0892 14.1377L23.7689 9.66602H25.8199L22.1149 15.8453L25.9995 22.3327H23.9225L21.0765 17.5917L18.2302 22.3327H16.1792Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
