<template>
  <svg v-bind="attrs">
    <mask
      id="mask0_17360_56009"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect
        width="32"
        height="32"
        fill="#"
      />
    </mask>
    <g mask="url(#mask0_17360_56009)">
      <path
        d="M9.02502 22.0007V20.0007H11.025V22.0007H9.02502ZM21.9993 22.0007V20.0007H27.3327V16.6673H21.9993V9.33398H29.3327V11.334H23.9993V14.6673H29.3327V22.0007H21.9993ZM12.8457 22.0007V14.6673H18.179V11.334H12.8457V9.33398H20.1787V16.6673H14.8453V20.0007H20.1787V22.0007H12.8457ZM5.20468 22.0007V11.334H2.66602V9.33398H7.20435V22.0007H5.20468Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
