<template>
  <svg v-bind="attrs">
    <path
      d="M9.74862 26.655L7.36929 22.645L2.85129 21.655L3.29229 17.004L0.230957 13.5013L3.29229 9.99866L2.85129 5.34766L7.36929 4.35766L9.74862 0.347656L14 2.15265L18.2513 0.347656L20.6306 4.35766L25.1486 5.34766L24.7076 9.99866L27.769 13.5013L24.7076 17.004L25.1486 21.655L20.6306 22.645L18.2513 26.655L14 24.85L9.74862 26.655ZM10.6 24.1013L14 22.6603L17.441 24.1013L19.3333 20.9013L23 20.0603L22.6666 16.3013L25.1333 13.5013L22.6666 10.6603L23 6.90132L19.3333 6.10132L17.4 2.90132L14 4.34232L10.559 2.90132L8.66663 6.10132L4.99996 6.90132L5.33329 10.6603L2.86662 13.5013L5.33329 16.3013L4.99996 20.1013L8.66663 20.9013L10.6 24.1013ZM14 19.809C14.3008 19.809 14.5555 19.7048 14.764 19.4963C14.9726 19.2877 15.077 19.0329 15.077 18.732C15.077 18.4311 14.9726 18.1764 14.764 17.968C14.5555 17.7595 14.3008 17.6553 14 17.6553C13.6991 17.6553 13.4444 17.7595 13.236 17.968C13.0273 18.1764 12.923 18.4311 12.923 18.732C12.923 19.0329 13.0273 19.2877 13.236 19.4963C13.4444 19.7048 13.6991 19.809 14 19.809ZM13 14.9373H15V6.93732H13V14.9373Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 28, height: 27 });
</script>
