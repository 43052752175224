<script lang="jsx">
import { defineComponent } from 'vue';
import BaseLoader from '@/components/base/BaseLoader.vue';

export default defineComponent({
  props: {
    isFetching: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    return () => (props.isFetching
      ? (
        <BaseLoader inline={props.inline} />
      )
      : (
        slots.default && slots.default()
      ));
  },
});
</script>
