<template>
  <svg v-bind="attrs">
    <path
      d="M12.8051 2.35973L11.0351 4.13973C10.3567 3.95769 9.64231 3.9575 8.96382 4.13917C8.28533 4.32084 7.66664 4.67796 7.16997 5.17463C6.67331 5.6713 6.31618 6.28999 6.13451 6.96848C5.95284 7.64697 5.95303 8.36133 6.13507 9.03973L3.37507 11.7897C2.05507 10.7897 0.955068 9.48973 0.195068 7.99973C1.33599 5.76256 3.20805 3.98314 5.50019 2.95713C7.79234 1.93113 10.3665 1.72032 12.7951 2.35973H12.8051ZM16.6051 4.20973C17.9351 5.20973 19.0351 6.50973 19.8051 7.99973C18.6634 10.241 16.7881 12.0233 14.4916 13.0496C12.1952 14.0759 9.61642 14.2842 7.18507 13.6397L8.95507 11.8597C9.63346 12.0418 10.3478 12.042 11.0263 11.8603C11.7048 11.6786 12.3235 11.3215 12.8202 10.8248C13.3168 10.3282 13.674 9.70947 13.8556 9.03098C14.0373 8.35248 14.0371 7.63812 13.8551 6.95973L16.6151 4.20973H16.6051ZM16.3551 0.219727L17.7751 1.63973L3.63507 15.7797L2.21507 14.3597L16.3551 0.219727Z"
      fill="white"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 16 });
</script>
