import axios from '@/services/axios';
import { isSU } from '@/utils/user';

const makeBase = (role: string) => {
  if (isSU(role)) {
    return '/su';
  }
  return '';
};

export const getClassesList = (): Promise<any> => axios
  .get('/classes')
  .then((response) => response.data);

export const getRolesList = (role: string): Promise<any> => axios
  .get(`${makeBase(role)}/roles`)
  .then((response) => response.data);

export const getVizexRolesList = (): Promise<any> => axios
  .get('/su/roles/su')
  .then((response) => response.data);

export const findOrgByText = (search: string): Promise<any> => {
  const searchParam = search || undefined;
  return axios
    .get('/orgs', { params: { page_size: 50, search: searchParam } })
    .then((response) => response.data);
};

export const getLocalRolesList = (): Promise<any> => axios
  .get('/roles/local')
  .then((response) => response.data);

export const getOrgRolesList = (): Promise<any> => axios
  .get('/org/roles/list')
  .then((response) => response.data);

export default {
  getClassesList,
  getRolesList,
  getVizexRolesList,
  findOrgByText,
  getLocalRolesList,
  getOrgRolesList,
};
