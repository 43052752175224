<template>
  <svg v-bind="attrs">
    <rect
      x="1"
      width="14"
      height="16"
      rx="1"
      fill="white"
    />
    <path
      d="M3 0H2V16H3V0Z"
      fill="#FD8263"
    />
    <rect
      x="5"
      y="5"
      width="8"
      height="1"
      rx="0.5"
      fill="#FD8263"
    />
    <rect
      x="5"
      y="11"
      width="8"
      height="1"
      rx="0.5"
      fill="#FD8263"
    />
    <rect
      x="5"
      y="8"
      width="8"
      height="1"
      rx="0.5"
      fill="#FD8263"
    />
    <path
      d="M0 8C0 7.44772 0.447715 7 1 7H2V9H1C0.447715 9 0 8.55228 0 8Z"
      fill="white"
    />
    <path
      d="M0 12C0 11.4477 0.447715 11 1 11H2V13H1C0.447715 13 0 12.5523 0 12Z"
      fill="white"
    />
    <path
      d="M0 4C0 3.44772 0.447715 3 1 3H2V5H1C0.447715 5 0 4.55228 0 4Z"
      fill="white"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 15, height: 16 });
</script>
