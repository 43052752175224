<template>
  <div ref="$el">
    <slot
      name="button"
      :buttonClass="'modal-menu-toggle'"
    />
    <transition
      name="slide-fade"
      :duration="600"
    >
      <div
        v-if="isShow"
        class="modal-menu"
        :class="{ 'full-width': fullWidth }"
      >
        <div
          class="modal-menu__overlay"
          @click="emit('close')"
        />
        <div class="modal-menu__content">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { useClickOutside } from '@/libraries/useClickOutside';

defineProps<{
  isShow: boolean;
  fullWidth?: boolean;
}>();

const emit = defineEmits(['toggle', 'close']);

const $el = ref<HTMLDivElement | null>(null);

useClickOutside($el, () => emit('close'));
</script>

<style lang="scss">
.modal-menu-toggle {
  cursor: pointer;
}

.modal-menu {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 40px;

  &.full-width {
    padding: 10px 0;
  }
}

.modal-menu__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 44px);
  background-color: rgba(14, 20, 31, 0.8);
}

.modal-menu__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 2px;
  opacity: 1;
  position: relative;
  z-index: 2;
}

.modal-menu__overlay, .modal-menu__content {
  transition: all 0.5s ease;
}

.slide-fade-enter-active .modal-menu__content,
.slide-fade-leave-active .modal-menu__overlay {
  transition-delay: 0.1s;
}

.slide-fade-enter-from .modal-menu__overlay,
.slide-fade-leave-to .modal-menu__overlay {
  opacity: 0;
}

.slide-fade-enter-from .modal-menu__content,
.slide-fade-leave-to .modal-menu__content {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
