<template>
  <div :class="`${cssClass}__volume`">
    <button
      :class="`${cssClass}__btn`"
      :aria-label="isMuted ? 'Включить звук' : 'Выключить звук'"
      @click="click"
    >
      <IconVolumeMute v-if="isMuted" />
      <IconVolumeLow v-else-if="volumeInput <= 0.3" />
      <IconVolumeMedium v-else-if="volumeInput <= 0.7" />
      <IconVolumeHigh v-else />
    </button>

    <div :class="`${cssClass}__volume-progress`">
      <IconVolumeScale :class="`${cssClass}__volume-progress-bar`" />

      <input
        ref="$volumeInput"
        v-model="volumeInput"
        :class="`${cssClass}__volume-input`"
        type="range"
        min="0"
        max="1"
        step="0.01"
        aria-label="Настроить громкость"
        @input="setVolume"
      />

      <span
        :class="[
          `${cssClass}__volume-tooltip`,
          `${cssClass}__tooltip`,
        ]"
      >
        {{ volumeTooltip }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import IconVolumeScale from '@/components/icons/videoplayer/Volume/VolumeScale.vue';
import IconVolumeMute from '@/components/icons/videoplayer/Volume/VolumeMute.vue';
import IconVolumeLow from '@/components/icons/videoplayer/Volume/VolumeLow.vue';
import IconVolumeMedium from '@/components/icons/videoplayer/Volume/VolumeMedium.vue';
import IconVolumeHigh from '@/components/icons/videoplayer/Volume/VolumeHigh.vue';

const props = defineProps<{
  currentVolume: number,
  isMuted: boolean,
}>();

const emits = defineEmits(['click', 'volumeInput']);

const cssClass = 'videoplayer';
const volumeInput = ref<number>(1);
const volumeTooltip = computed(() => Math.trunc(volumeInput.value * 100));
const isMuted = computed(() => props.isMuted);

const click = () => {
  emits('click', !isMuted.value);
};

const setVolume = (): void => {
  emits('volumeInput', volumeInput.value);
};

watch(isMuted, (newValue) => {
  if (newValue) {
    volumeInput.value = 0;
  } else if (props.currentVolume === 0) {
    volumeInput.value = 0.5;
    emits('volumeInput', volumeInput.value);
  } else {
    volumeInput.value = props.currentVolume;
  }
});
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.videoplayer {
  &__volume {
    position: relative;

    &:hover .videoplayer__volume-progress {
      visibility: visible;
      opacity: 1;
    }
  }

  &__volume-progress {
    position: absolute;
    top: -62px;
    left: 6px;
    right: 0;
    width: 18px;
    height: 71px;
    color: $font-color;
    visibility: hidden;
    opacity: 0;
    transition: all $transition-speed ease-in;
  }

  &__volume-progress-bar {
    width: 100%;
    height: 100%;
  }

  &__volume-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    left: -20px;
    top: 15px;
    width: 58px;
    height: 30px;
    margin: 0;
    clip-path: polygon(100% 25%, 0 50%, 100% 75%);
    transform: rotate(-90deg);
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      background: transparent;
    }

    &::-moz-range-track {
      background: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0;
      height: 18px;
      background-color: $font-color;
      border-radius: 0;
      border: 4px solid $font-color;
      box-shadow: -407px 0 0 400px $font-color;
    }

    &::-moz-range-thumb {
      width: 0;
      height: 18px;
      background-color: $font-color;
      border-radius: 0;
      border: 4px solid $font-color;
      box-shadow: -407px 0 0 400px $font-color;
    }
  }

  &__volume-tooltip {
    top: -30px;
    left: 13px;
    width: 32px;
  }
}
</style>
