import { RouteRecordRaw } from 'vue-router';
import { names, paths } from '@/constants/routes';

const shop: RouteRecordRaw = {
  path: paths.shop,
  name: names.shop,

  component: () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'shop' */
    './index.vue'
  ),
};

export default shop;
