const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err: unknown) {
    if (typeof err === 'string') {
      throw Error(err);
    } else if (err instanceof Error) {
      throw Error(err.message);
    } else {
      console.error(err);
    }
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string): void => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  try {
    navigator.clipboard.writeText(text);
  } catch (err: unknown) {
    if (typeof err === 'string') {
      throw Error(err);
    } else if (err instanceof Error) {
      throw Error(err.message);
    } else {
      console.error(err);
    }
  }
};

export default {
  copyTextToClipboard,
};
