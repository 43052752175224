export const inputKeysMap = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  TAB: 9,

  BACKSPACE: 8,
  CTRL: 17,
  C: 67,
  V: 86,
  X: 88,
  DELETE: 46,
  // (META/Command on mac)
  WINDOWS: 91,
  // (option on mac)
  ALT: 18,
  SHIFT: 16,
};

export const allowedKeysForInputs = [
  inputKeysMap.ARROW_LEFT,
  inputKeysMap.ARROW_RIGHT,
  inputKeysMap.TAB,
  inputKeysMap.BACKSPACE,
  inputKeysMap.CTRL,
  inputKeysMap.C,
  inputKeysMap.V,
  inputKeysMap.X,
  inputKeysMap.WINDOWS,
  inputKeysMap.SHIFT,
];

export default {
  inputKeysMap,
  allowedKeysForInputs,
};
