<template>
  <svg v-bind="attrs">
    <path
      d="M6.96774 0.856757C4.30399 -0.819145 0.837646 1.09532 0.837646 4.24242V83.7576C0.837646 86.9047 4.30399 88.8191 6.96774 87.1432L70.1601 47.3857C72.6533 45.8171 72.6533 42.1829 70.1601 40.6143L6.96774 0.856757Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 73, height: 88 });
</script>
