import axios, { AxiosResponse, AxiosError } from 'axios';
import router from '@/router/index';
import { paths } from '@/constants/routes';
import { logoutUser } from '@/services/userActions';

const apiClient = axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    crossDomain: true,
  },
});

const isNotMainPage = () => router?.currentRoute?.value?.path !== '/';
const isNotAuthorized = (error: AxiosError) => error.response?.status === 401;

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (isNotAuthorized(error) && isNotMainPage()) {
      logoutUser(paths.alerts.closed);
    }
    return Promise.reject(error);
  }
);

export default apiClient;
