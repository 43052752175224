import { computed } from 'vue';
import store from '@/store';
import env from '@/env';

import VisitParameters = ym.VisitParameters;

type UseYMetrica = {
  useReachGoal: (goal: string, params?: Record<string, any>) => void;
  useHit: (to: string, from?: string) => void;
};

export const useYMetrica = (): UseYMetrica => {
  const email = computed<string>(
    () => (store.state as any)?.UserData?.user?.email || ''
  );

  const useReachGoal = (goal: string, params?: VisitParameters) => {
    if (window.ym) {
      return window.ym(Number(env.YMETRIKA_NUMBER), 'reachGoal', goal, params);
    }
  };

  const useHit = (to: string = '', from?: string): void => {
    if (window.ym && !email.value.endsWith('vizex.ru')) {
      window.ym(Number(env.YMETRIKA_NUMBER), 'hit', to, { referer: from || '' });
    }
  };

  return {
    useHit,
    useReachGoal,
  };
};

export default useYMetrica();
