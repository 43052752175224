<template>
  <svg v-bind="attrs">
    <path d="M15 20H7V18H9.92661L12.0425 6H9V4H17V6H14.0734L11.9575 18H15V20Z" />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
