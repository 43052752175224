<template>
  <svg v-bind="attrs">
    <rect
      width="750"
      height="600"
      fill="#ED2939"
    />
    <rect
      width="500"
      height="600"
      fill="#fff"
    />
    <rect
      width="240"
      height="600"
      fill="#002395"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 750, height: 600 });
</script>
