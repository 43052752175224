<template>
  <dialog
    ref="$modal"
    :class="[cssClass, $attrs.class]"
    :style="{ width: `${width}px` }"
    @click="handleOutsideClick"
  >
    <header
      v-if="slots.header"
      :class="`${cssClass}__header`"
    >
      <slot name="header" />
    </header>

    <main :class="`${cssClass}__body`">
      <slot />
    </main>

    <div
      v-if="slots.buttons"
      :class="`${cssClass}__buttons`"
    >
      <slot name="buttons" />
    </div>
  </dialog>
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue';

interface Props {
  closeOutsideModal?: boolean,
  width?: number | string,
}

const props = withDefaults(defineProps<Props>(), {
  closeOutsideModal: false,
  width: 600,
});

const slots = useSlots();
const $modal = ref<HTMLDialogElement | null>(null);
const cssClass = 'modal';

const handleOpen = () => {
  $modal.value?.showModal();
  document.body.style.overflow = 'hidden';
};

const handleClose = () => {
  $modal.value?.close();
  document.body.style.overflow = '';
};

const handleOutsideClick = (event: any): void => {
  if (props.closeOutsideModal && $modal.value) {
    const dialogDimensions = $modal.value.getBoundingClientRect();

    if (
      event.clientX < dialogDimensions.left
        || event.clientX > dialogDimensions.right
        || event.clientY < dialogDimensions.top
        || event.clientY > dialogDimensions.bottom
    ) {
      handleClose();
    }
  }
};

defineExpose({
  showModal: handleOpen,
  close: handleClose,
});
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.modal {
  $modal-header-margin_bottom: 34px !default;
  $modal-body-margin_bottom: 34px !default;

  top: 50%;
  height: max-content;
  margin: 0 auto;

  border: none;
  border-radius: $modal-content-border-radius;
  padding: $modal-content-padding;
  opacity: 0;
  transform: scale(0);
  transition:
    opacity $modal-transition-duration,
    transform $modal-transition-duration,
    overlay $modal-transition-duration allow-discrete,
    display $modal-transition-duration allow-discrete;

  &__header {
    margin-bottom: $modal-header-margin_bottom;
    padding-right: $modal-header-padding-right;

    & h3 {
      font-size: 24px;
    }
  }

  &__body:not(:last-child) {
    margin-bottom: $modal-body-margin_bottom;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;
  }

  &[open] {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}
</style>
