import { RouteRecordRaw } from 'vue-router';
import { paths, names } from '@/constants/routes';

import Laboratory from './index.vue';

const laboratory: Array<RouteRecordRaw> = [
  {
    name: names.laboratory,
    path: paths.laboratory,
    component: Laboratory,
    // Can't use RouteRecordRaw because it doesn't exist params; RouteRecordSingleView doesn't export;
    props: (route: any) => ({ id: Number(route.params.id) }),
  },
];

export default laboratory;
