<template>
  <ElDialog
    :title="isVideo ? t('commonVideo') : t('commonDocs')"
    class="general-dialog"
    :closeOnClickModal="false"
  >
    <ElForm
      ref="manualsForm"
      :model="formData"
      :rules="rules"

      statusIcon
      :validateOnRuleChange="false"
    >
      <ElTabs
        v-model="activeTab"
        type="border-card"
        class="add-page__tabs add-page__gap"
      >
        <ElTabPane
          v-for="lang in tabLocales"
          :key="`tab-${lang.value}`"
          :name="lang.value"
        >
          <template #label>
            {{ localesNamesMapper[lang.value] }}
            <ElIcon class="el-icon--right">
              <div
                v-if="lang.isRequired && lang.isFilled"
                class="--filled"
              >
                <IconSelect />
              </div>
              <div
                v-else-if="lang.isRequired"
                class="--warning"
              />
              <LangIcon :lang="lang.value" />
            </ElIcon>
          </template>
          <ElSkeleton
            animated
            :loading="isLoading"
          />
          <GeneralTable
            :list="generalList"
            :tabLang="lang.value"
            :type="type"
            @inputFileLink="inputFileLink"
            @inputTextButton="inputTextButton"
            @changeFlag="changeFlag"
            @changeFile="changeFile"
            @updateFiles="updateFiles"
            @delete="handleDelete"
          />
        </ElTabPane>
      </ElTabs>

      <ElRow>
        <ElCol
          :offset="3"
          class="files-group__action-footer"
        >
          <ElButton
            round
            @click="addGeneralLink"
          >
            {{ t('buttons.addByLink') }}
            <ElIcon class="el-icon--right">
              <ClipIcon />
            </ElIcon>
          </ElButton>
          <ElButton
            round
            @click="addGeneralFile"
          >
            {{ t('buttons.loadFile') }}
            <ElIcon class="el-icon--right">
              <UploadIcon />
            </ElIcon>
          </ElButton>
        </ElCol>
      </ElRow>

      <ElRow>
        <ElCol class="general-dialog__between">
          <ElButton
            round
            class="ui-btn --big-round"
            @click="undoChanges"
          >
            {{ t('cancel') }}
          </ElButton>
          <ElButton
            type="primary"
            round
            class="ui-btn --big-round"
            :icon="SuccessFilled"
            @click="checkValid"
          >
            {{ t('buttonSave') }}
          </ElButton>
        </ElCol>
      </ElRow>
    </ElForm>
  </ElDialog>
</template>

<script setup lang="ts">
import { ElButton, ElCol, ElDialog, ElIcon, ElRow, ElTabs, ElTabPane, ElForm, ElSkeleton } from 'element-plus';
import { computed, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SuccessFilled, Select as IconSelect } from '@element-plus/icons-vue';
import { useI18n } from 'vue-i18n';
import cloneDeep from 'lodash.clonedeep';
import ClipIcon from '@/components/icons/Clip.vue';
import UploadIcon from '@/components/icons/Upload.vue';
import GeneralTable from '@/components/Form/GeneralTable.vue';
import { DocumentType, DocumentTypeEnum, MediaFile, Lang, Locale, MediaFields } from '@/types/manuals';
import { cleanEmptyFileFields, createGeneralManualsByVideo, reOrder }
  from '@/router/Admin/builderManuals';
import LangIcon from '@/components/Form/LangIcon.vue';
import { localesNamesMapper } from '@/constants/languages';
import { showValidationError, UploadedPayloadType } from '@/utils';

const store = useStore();
const { t } = useI18n();

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'saveGlobalFiles'): void;
}>();

const props = defineProps<{
  type: DocumentType;
}>();

const manualsForm = ref();
const requiredLocales = computed(() => store.getters['Locales/requiredLocales']);
const tabLocales = computed(() => {
  const locales = cloneDeep(store.getters['Locales/locales']);
  return locales.map((loc: any, localIndex: number) => {
    loc.isFilled = (() => {
      const isFilled: any[] = [];

      if (generalList.value.length) {
        generalList.value.forEach((item: MediaFile, itemIndex: number) => {
          if (rules.value?.[`${fieldName}.${itemIndex}.locales.${localIndex}.caption`]?.[0]?.required) {
            isFilled.push(item.locales[localIndex].caption);
          }
          if (rules.value?.[`${fieldName}.${itemIndex}.locales.${localIndex}.file`]?.[0]?.required) {
            isFilled.push(item.locales[localIndex].file);
          }
          if (rules.value?.[`${fieldName}.${itemIndex}.flag`]?.[0]?.required) {
            isFilled.push(item.flag);
          }
        });
      }

      const hasEmptyField = Boolean(isFilled.filter((field: string) => field === '').length);
      return !hasEmptyField;
    })();

    return loc;
  });
});
const activeTab = ref<Lang>('ru');
const isVideo = props.type === DocumentTypeEnum.Video;
const fieldName = isVideo ? MediaFields.VideoInstructions : MediaFields.UsersGuides;

const generalList = computed(() => (isVideo
  ? store.getters['Manuals/getGeneralVideoManuals']
  : store.getters['Manuals/getGeneralDocManuals']));

const isLoading = computed(() => !generalList.value?.length);

const formData = computed(() => ({ [fieldName]: generalList.value }));

const rules = computed(() => {
  const data = {};
  generalList.value.forEach((item: MediaFile, indexItem: number) => {
    item?.locales?.forEach((locItem, locIndex) => {
      const required = requiredLocales.value.includes(locItem.locale);
      data[`${fieldName}.${indexItem}.locales.${locIndex}.file`] = [{ required, message: '', trigger: 'blur' }];
      data[`${fieldName}.${indexItem}.flag`] = [{ required: true, message: '', trigger: 'blur' }];
      data[`${fieldName}.${indexItem}.locales.${locIndex}.caption`] = [{ required, message: '', trigger: 'blur' }];
    });
  });

  return data;
});

const updateManualLink = (index: number, link: string, indexLang?: number) => {
  const loc = generalList.value[index].locales
    .find((x: Locale, i: number) => x.locale === activeTab.value || i === indexLang);
  loc.file = link;
  loc.is_file_changed = 1;
};

const changeFile = ({ data, index, indexLang }:
  { data: UploadedPayloadType, index: number, indexLang: number }) => {
  updateManualLink(index, data.name, indexLang);
};
const updateFiles = (list: MediaFile[]) => {
  store.commit('Manuals/SET_MANUALS', { list, isVideo });
};
const inputTextButton = (value: { index: number, value: string }) => {
  generalList.value[value.index].locales
    .find((x: Locale) => x.locale === activeTab.value).caption = value.value;
};
const inputFileLink = (value: { index: number, value: string }) => {
  updateManualLink(value.index, value.value);
};
const changeFlag = (value: { index: number, value: string }) => {
  generalList.value[value.index].flag = value.value;
};
const handleDelete = (value: number) => {
  generalList.value.splice(value, 1);
};

const addGeneralLink = (): void => {
  store.commit('Manuals/ADD_NEW_MANUALS', { isFile: false, isVideo });
};

const addGeneralFile = (): void => {
  store.commit('Manuals/ADD_NEW_MANUALS', { isFile: true, isVideo });
};

const undoChanges = () => {
  emit('close');
  store.commit('Manuals/RESET_MANUALS', isVideo);
};

const checkValid = async () => {
  await manualsForm.value.validate(async (valid: boolean, fields: any) => {
    if (valid) {
      await saveChanges();
    } else {
      showValidationError(fields, t);
    }
  });
};

const saveChanges = async () => {
  let generalListWithoutEmptyFileFields = cleanEmptyFileFields(generalList.value);
  generalListWithoutEmptyFileFields = reOrder(generalListWithoutEmptyFileFields);
  const data = createGeneralManualsByVideo(toRaw(generalListWithoutEmptyFileFields), isVideo);
  await store.dispatch('Manuals/saveGeneralManuals', { data, isVideo });

  emit('saveGlobalFiles');
  emit('close');
};

store.dispatch('Manuals/fetchGeneralManuals', isVideo);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.general-dialog {
  min-width: 960px;
  --el-dialog-padding-primary: 24px 40px;

  .el-icon {
    $iconSize: 16px;
    display: flex;
    width: $iconSize;
    height: $iconSize;
    position: relative;

    svg {
      width: $iconSize;
      height: $iconSize;
    }

    .--filled {
      position: absolute;
      right: -11px;
      top: -6px;

      svg {
        width: 11px;
        color: $colorGrin;
      }
    }
    .--warning {
      position: absolute;
      right: -8px;
      top: -6px;
      color: $input-border-color-error;
      font-style: normal;

      &:before {
        content: '!';
        display: block;
      }
    }
  }

  .lang-icon {
    border-radius: 50%;
    border: 1px solid black;


  }

  &.el-dialog {
    border-radius: 12px;
  }

  .el-dialog {

    &__header {
      padding-bottom: 24px;
    }
    &__headerbtn {
      display: none;
    }

    &__title {
      font-size: 24px;
      padding-bottom: 10px;
    }
  }

  &__between {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $column-content;
    padding-top: 12px;
  }
}

</style>
