import { computed, ComputedRef } from 'vue';
import { documents } from '@/constants/document-links';
import { currentI18nLocale } from '@/composition/useLangSet';

const useDocumentLinks = (): ComputedRef<
  Record<string, string>
> => computed<Record<string, string>>(() => {
  const temp: Record<string, string> = {};

  // TODO: Add French documents and remove value check
  const locale = currentI18nLocale.value === 'ru' ? 'ru' : 'en';
  // const locale = currentI18nLocale.value;

  Object.keys(documents).forEach((name) => {
    temp[name] = documents[name].replace('{lang}', locale);
  });
  return temp;
});

export default useDocumentLinks;
