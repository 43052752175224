<template>
  <svg v-bind="attrs">
    <path
      d="M23.3023 24.7077L19.282 20.687C18.8204 20.9828 18.3341 21.2392 17.823 21.4563C17.3119 21.6734 16.7828 21.8554 16.2357 22.0023V19.9357C16.5212 19.8332 16.799 19.7286 17.069 19.6217C17.3392 19.5148 17.5948 19.3879 17.8357 19.241L13.0563 14.4613V20.2407L7.33833 14.523H2.38967V7.85633H6.45133L0 1.405L1.405 0L24.7073 23.3023L23.3023 24.7077ZM22.651 17.5L21.2177 16.0667C21.681 15.3436 22.0349 14.5679 22.2793 13.7397C22.5238 12.9114 22.646 12.0503 22.646 11.1563C22.646 9.13589 22.0584 7.32056 20.8833 5.71033C19.708 4.09989 18.1588 2.98878 16.2357 2.377V0.310333C18.7263 0.975222 20.7507 2.30767 22.3087 4.30767C23.8669 6.30767 24.646 8.59056 24.646 11.1563C24.646 12.3257 24.4721 13.4483 24.1243 14.5243C23.7763 15.6003 23.2852 16.5922 22.651 17.5ZM18.569 13.418L16.2357 11.0847V6.25133C17.1348 6.74022 17.8297 7.42867 18.3203 8.31667C18.811 9.20467 19.0563 10.1623 19.0563 11.1897C19.0563 11.5828 19.0157 11.9661 18.9343 12.3397C18.8532 12.7132 18.7314 13.0727 18.569 13.418ZM13.0563 7.905L10.1793 5.01533L13.0563 2.13867V7.905ZM11.0563 15.3897V12.4613L8.45133 9.85633H4.38967V12.523H8.18967L11.0563 15.3897Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 25, height: 25 });
</script>
