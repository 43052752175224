import { ValidationArgs } from '@vuelidate/core';

import { suRoles, roles } from '@/constants/roles';
import {
  firstname,
  surname,
  patronymic,
  email,
  role,
  organization,
  studyClass,
  lang,
  country,
  region,
  city,
} from '@/constants/input-fields';
import { maxLength, minLength, required } from '@/utils/validations';

import {
  OrgFieldsColumn,
  PersonalFieldsColumn,
  ProfileField,
} from './types';

export const personalDataFields: PersonalFieldsColumn = {
  lang: { ...lang, can_see: [...suRoles, roles.marketer] },
  firstname: { ...firstname, required: true },
  surname: { ...surname, required: true },
  patronymic,
  role: {
    ...role,
    can_see: [...suRoles, roles.orgOwner, roles.marketer],
    can_edit: [],
  },
  email: {
    ...email,
    can_edit: [],
  },
};

export const studyDataFields = (): Record<string, ProfileField> => ({
  lang: { ...lang },
  role: { ...role },
  organization: { ...organization },
  class: { ...studyClass },
});

export const orgDataFields: OrgFieldsColumn = {
  lang: { ...lang },
  role: {
    ...role,
    can_see: [roles.orgModerator],
    can_edit: [],
  },
  organization: {
    ...organization,
    required: false,
    can_edit: [],
    type: 'text',
    label: 'labels.organizationName',
  },
  country: {
    ...country,
    can_see: [roles.orgOwner],
    can_edit: [],
  },
  region: {
    ...region,
    can_see: [roles.orgOwner],
    can_edit: [],
  },
  city: {
    ...city,
    can_see: [roles.orgOwner],
    can_edit: [],
  },
};

export const orgUnits = {
  students: '',
  teachers: '',
  moderators: '',
};

export default {
  personalDataFields,
  studyDataFields,
  orgDataFields,
};

export const validationRules = <ValidationArgs>{
  firstname: {
    value: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  surname: {
    value: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  patronymic: {
    value: {
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  email: {},
  lang: {},
};

export const correctTypeInviteLink: Array<string> = [
  'accepted',
  'consists',
  'consists-another',
  'confirmation-await',
  'incorrect-role',
];
