<template>
  <header :class="['header', { mobile: isMobileScreen }]">
    <a
      :href="companyWebsiteLink"
      class="header__logo-link"
      target="_blank"
      :aria-label="t('vrLabs')"
    >
      <AppLogo />
    </a>

    <TransitionFade>
      <HeaderNav class="header__nav" />
    </TransitionFade>

    <HeaderProfile v-if="userData" />

    <CleanButton
      v-if="!userData"
      class="header__login"
      @click="handleLogin"
    >
      {{ t("menus.login") }}
    </CleanButton>

    <LangSwitcher v-if="!userData" />
  </header>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useScreenSize } from '@/composition/useScreenSize';

import { loginUser as handleLogin } from '@/services/userActions';
import { LoggedUser } from '@/types';

import { companyWebsiteLink } from '@/utils/constTemplates';

import AppLogo from '@/components/AppLogo.vue';
import CleanButton from '@/components/CleanButton.vue';

import TransitionFade from '@/components/TransitionFade.vue';

import HeaderNavMobile from '@/components/Header/Nav/Mobile.vue';
import HeaderNavDesktop from '@/components/Header/Nav/Desktop.vue';

import HeaderProfileMobile from '@/components/Header/Profile/Mobile.vue';
import HeaderProfileDesktop from '@/components/Header/Profile/Desktop.vue';

import LangSwitcherMobile from '@/components/Header/LangSwitcher/Mobile.vue';
import LangSwitcherDesktop from '@/components/Header/LangSwitcher/Desktop.vue';

const store = useStore();
const { t } = useI18n();
const { isMobileScreen } = useScreenSize();

const userData = computed<LoggedUser>(() => store.state.UserData.user);

const HeaderNav = computed<ComponentPublicInstance>(
  () => (isMobileScreen.value ? HeaderNavMobile : HeaderNavDesktop)
);
const HeaderProfile = computed<ComponentPublicInstance>(
  () => (isMobileScreen.value ? HeaderProfileMobile : HeaderProfileDesktop)
);
const LangSwitcher = computed<ComponentPublicInstance>(
  () => (isMobileScreen.value ? LangSwitcherMobile : LangSwitcherDesktop)
);
</script>

<style scoped lang="scss">
@use "sass:map";
@import "@/assets/style/include";

.header {
  background-color: $header-background;
  box-shadow: $header-box-shadow;
  display: flex;
  align-items: center;
  height: $header-height;
  left: 0;
  padding-left: $header-padding-x;
  padding-right: $header-padding-x;
  position: fixed;
  right: 0;
  top: 0;
  gap: 1rem;
  width: 100%;
  z-index: $header-index;
  transition: background-color 1s;

  @include media-breakpoint-down("lg") {
    padding-left: $header-padding-x-medium;
    padding-right: $header-padding-x-medium;
  }

  @include media-breakpoint-down("sm") {
    height: $header-height-mobile;
    padding-left: $header-padding-x-small;
    padding-right: $header-padding-x-small;
    font-size: $header-font-size-small;
  }

  &.mobile {
    background-color: $header-background-mobile;
    border-bottom: 1px solid $color-primary-light;
  }
}

.header__logo-link {
  display: inline-flex;
  font-size: 50px;

  @include media-breakpoint-down("sm") {
    font-size: 32px;
  }
}

.header__nav {
  flex-grow: 1;
}

.header__login {
  background-color: var(--color-grey-header);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  transition: background-color 0.3s;

  @include hover-focus() {
    background-color: var(--color-acent-lightest);
    outline: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
