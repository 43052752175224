<template>
  <svg v-bind="attrs">
    <path
      d="M13.4789 0.000197495C13.09 0.00586719 12.7171 0.160304 12.4417 0.435775L11.7952 1.08328L14.9164 4.2046L15.5639 3.55807C16.1454 2.97656 16.1454 2.01733 15.5639 1.43585L14.5639 0.435775C14.2759 0.147887 13.8834 -0.00630378 13.4789 0.000197495ZM11.0881 1.79036L5.79496 7.0837L8.91618 10.205L14.2094 4.91168L11.0881 1.79036ZM5.15235 7.85524C5.05811 8.05477 5.00394 8.2722 5.00391 8.49689L5 10.498C4.99975 10.564 5.01256 10.6294 5.0377 10.6904C5.06284 10.7514 5.0998 10.8069 5.14647 10.8535C5.19313 10.9002 5.24857 10.9372 5.30958 10.9623C5.3706 10.9874 5.43599 11.0002 5.50198 11L7.50303 10.9961C7.7277 10.9961 7.94514 10.9419 8.14466 10.8476L5.15235 7.85524Z"
      fill="white"
    />
    <rect
      y="13.5312"
      width="20"
      height="2.24882"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 16 });
</script>
