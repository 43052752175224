<template>
  <svg v-bind="attrs">
    <path
      d="M1.45455 16L0 14.5455L6.54545 8L0 1.45454L1.45455 1.8019e-07L8 6.54545L14.5455 0L16 1.45455L9.45028 7.9929L15.9957 14.5384L14.5341 16L7.98864 9.45455L1.45455 16Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 16, height: 16 });
</script>
