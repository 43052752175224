export const applyMask = (
  value: string,
  mask: string
): string => {
  let result = '';
  let valueI = 0;

  for (let i = 0; i < mask.length; i += 1) {
    const maskSymbol = mask[i];

    if (maskSymbol === 'X') {
      if (valueI >= value.length) {
        return result;
      }
      result += value[valueI];
      valueI += 1;
    } else {
      if (value[valueI] === maskSymbol) {
        valueI += 1;
      }
      result += maskSymbol;
    }
  }

  return result;
};

export default {
  applyMask,
};
