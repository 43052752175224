<template>
  <svg v-bind="attrs">
    <rect
      x="6"
      y="6"
      width="12"
      height="2"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="6"
      y="11"
      width="12"
      height="2"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="6"
      y="16"
      width="12"
      height="2"
      rx="1"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
