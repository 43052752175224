export const getBrowserLocale = (options = {}): string | undefined => {
  const defaultOptions = { countryCodeOnly: true };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  return opt.countryCodeOnly
    ? navigatorLocale.trim().split(/[-_]/)[0]
    : navigatorLocale.trim();
};

export default {
  getBrowserLocale,
};
