<template>
  <div
    ref="$el"
    class="lang-switcher"
  >
    <Dropdown
      :show="isShow"
      :indent="0"
      right
      class="lang-switcher__dropdown"
    >
      <template #trigger>
        <ButtonRound
          class="lang-switcher__button-current"
          @click="handleToggle"
        >
          <component :is="localesIconsMapper[currentLang]" />
        </ButtonRound>
      </template>
      <CleanButton
        v-for="lang in availableLocales"
        :key="lang"
        :class="['lang-switcher__dropdown-button', { active: currentLang === lang }]"
        :disabled="currentLang === lang"
        @click="handleLangSelect(lang)"
      >
        {{ localesNamesMapper[lang] }}
        <component :is="localesIconsMapper[lang]" />
      </CleanButton>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/i18n';
import { localesIconsMapper, localesNamesMapper } from '@/constants/languages';
import { availableLocales, setLocale } from '@/composition/useLangSet';
import { useClickOutside } from '@/libraries/useClickOutside';

import Dropdown from '@/components/Dropdown.vue';
import CleanButton from '@/components/CleanButton.vue';
import ButtonRound from '@/components/ButtonRound.vue';

const router = useRouter();

const $el = ref<HTMLDivElement | null>(null);
const isShow = ref<boolean>(false);
const currentLang = computed<string>(() => i18n.global.locale.value);

const handleToggle = () => {
  isShow.value = !isShow.value;
};
const handleClose = () => {
  isShow.value = false;
};
const handleLangSelect = (lang: string) => {
  setLocale(lang);
  handleClose();
  // TODO Think to how refresh without location reload
  router.go(0);
};

useClickOutside($el, handleClose);
</script>

<style lang="scss" src="./index.scss"></style>

<style lang="scss">
@import "@/assets/style/include";

.lang-switcher__dropdown {
  border-top: 2px solid $color-accent-dark;
  padding: 0;
  transform: translateY(15px);
}

.lang-switcher__dropdown-button {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: 0.25rem;
  white-space: nowrap;
  width: 100%;

  & + & {
    border-top: 1px solid $color-accent-lightest;
  }

  @include hover-focus() {
    background: $color-accent-lightest;
    outline: 0;
  }

  &.active {
    color: $color-white;
    background: $color-accent-dark;
    cursor: default;
  }

  & > svg {
    margin-left: 0.5rem;
  }
}
</style>
