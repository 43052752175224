import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';
import { correctTypeInviteLink } from './constants';

const profile: RouteRecordRaw = {
  path: paths.profile,
  name: names.profile,
  meta: {
    permissions: permissions.profile,
    requiresAuth: true,
    isAuthed: false,
  },
  component: () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'profile' */
    './index.vue'
  ),
  beforeEnter(to, from, next) {
    if (
      to.params.type
      && !correctTypeInviteLink.includes(String(to.params?.type || ''))
    ) {
      next({ name: names.profile });
      return;
    }
    next();
  },
};

export default profile;
