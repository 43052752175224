<template>
  <svg v-bind="attrs">
    <path
      d="M13.8457 21.7431L13.8534 19.6764C15.7787 19.0718 17.332 17.9664 18.513 16.3603C19.6943 14.7544 20.2887 12.9413 20.2961 10.9209C20.3036 8.90043 19.7227 7.08294 18.5533 5.46838C17.3842 3.8536 15.8392 2.73676 13.9183 2.11787L13.926 0.0512208C16.4055 0.725287 18.4228 2.0652 19.9778 4.07097C21.5326 6.07673 22.3053 8.3625 22.2958 10.9283C22.2863 13.494 21.4968 15.774 19.9272 17.7682C18.3573 19.7624 16.3302 21.0874 13.8457 21.7431ZM0.0275009 14.2126L0.052163 7.54595L5.0008 7.56426L10.7396 1.86778L10.6726 19.9697L4.97613 14.2309L0.0275009 14.2126ZM13.8677 15.8021L13.904 5.99218C14.8013 6.48439 15.4936 7.1754 15.981 8.06521C16.4682 8.95502 16.7099 9.91359 16.7061 10.9409C16.7023 11.9545 16.4514 12.8967 15.9533 13.7675C15.4551 14.6383 14.76 15.3165 13.8677 15.8021ZM8.72164 6.71135L5.84439 9.56739L2.04442 9.55333L2.03455 12.22L5.83453 12.234L8.69057 15.1113L8.72164 6.71135Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 23, height: 22 });
</script>
