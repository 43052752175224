import loadScript from '@/utils/scriptLoader';
import YM from '@/composition/useYMetrica';

export const mountApp = (app: any) => {
  YM.useHit(window.location.pathname, document.referrer);
  app.use(loadScript).mount('#app');
};

export default {
  mountApp,
};
