<template>
  <svg v-bind="attrs">
    <path
      d="M1.9043 7.81061H15.9043V4.41909C15.9043 4.34209 15.8722 4.27159 15.808 4.20759C15.744 4.14343 15.6735 4.11134 15.5965 4.11134H2.21205C2.13505 4.11134 2.06455 4.14343 2.00055 4.20759C1.93638 4.27159 1.9043 4.34209 1.9043 4.41909V7.81061ZM2.21205 20.5029C1.70688 20.5029 1.2793 20.3279 0.929297 19.9779C0.579297 19.6279 0.404297 19.2003 0.404297 18.6951V4.41909C0.404297 3.91393 0.579297 3.48634 0.929297 3.13634C1.2793 2.78634 1.70688 2.61134 2.21205 2.61134H3.59655V0.496094H5.13505V2.61134H12.712V0.496094H14.212V2.61134H15.5965C16.1017 2.61134 16.5293 2.78634 16.8793 3.13634C17.2293 3.48634 17.4043 3.91393 17.4043 4.41909V10.7741C17.1645 10.6689 16.9195 10.5839 16.6695 10.5191C16.4195 10.4544 16.1645 10.4035 15.9043 10.3664V9.31061H1.9043V18.6951C1.9043 18.7721 1.93638 18.8426 2.00055 18.9066C2.06455 18.9708 2.13505 19.0029 2.21205 19.0029H8.71405C8.79855 19.2797 8.9008 19.5415 9.0208 19.7884C9.14063 20.0352 9.2768 20.2734 9.4293 20.5029H2.21205ZM15.0965 21.5029C13.8479 21.5029 12.7857 21.065 11.91 20.1894C11.0344 19.3137 10.5965 18.2515 10.5965 17.0029C10.5965 15.7542 11.0344 14.692 11.91 13.8164C12.7857 12.9407 13.8479 12.5029 15.0965 12.5029C16.3454 12.5029 17.4075 12.9407 18.283 13.8164C19.1587 14.692 19.5965 15.7542 19.5965 17.0029C19.5965 18.2515 19.1587 19.3137 18.283 20.1894C17.4075 21.065 16.3454 21.5029 15.0965 21.5029ZM16.762 19.2914L17.385 18.6684L15.539 16.8221V14.0606H14.6543V17.1836L16.762 19.2914Z"
      fill="#1C1B1F"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 22 });
</script>
