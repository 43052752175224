import axios from '@/services/axios';

const getLaboratoriesList = () => axios
  .get('/products')
  .then((response) => response.data);

const getPublicLaboratoriesList = () => axios
  .get('/public/products')
  .then((response) => response.data);

const getLaboratoryById = (id: number) => axios
  .get(`/products/${id}`)
  .then((response) => response.data);

const getPublicLaboratoryById = (id: number) => axios
  .get(`/public/products/${id}`)
  .then((response) => response.data);

export const sendAccountingAppUrl = (
  product_uuid: string,
  is_mobile: boolean,
): Promise<any> => axios
  .post('/accounting/app-url', {
    product_uuid,
    is_mobile: Number(is_mobile),
  })
  .then((response) => response.data);

export default {
  getLaboratoriesList,
  getPublicLaboratoriesList,
  getLaboratoryById,
  getPublicLaboratoryById,
  sendAccountingAppUrl,
};
