<template>
  <svg v-bind="attrs">
    <mask
      id="mask0_17360_56010"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect
        width="32"
        height="32"
        fill="#"
      />
    </mask>
    <g mask="url(#mask0_17360_56010)">
      <path
        d="M8.40958 22.3327V20.3327H10.4096V22.3327H8.40958ZM4.81992 22.3327V11.666H2.28125V9.66602H6.81992V22.3327H4.81992ZM20.5632 22.3327L24.4352 15.871L20.7172 9.66602H22.7939L25.4736 14.1377L28.1529 9.66602H30.2043L26.4993 15.8453L30.3839 22.3327H28.3069L25.4606 17.5917L22.6146 22.3327H20.5632ZM11.9993 22.3327V20.3327H16.9866V16.9993H11.9993V9.66602H18.9863V11.666H13.9993V14.9993H18.9863V22.3327H11.9993Z"
        fill="currenColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
