import { onMounted, onUnmounted, Ref } from 'vue';

export const useClickOutside = (
  $el: Ref,
  callback: Function
): void => {
  const $handleClickOutside = (ev: MouseEvent) => {
    if (!$el.value.contains(ev.target) && callback) {
      callback();
    }
  };

  onMounted(() => window.addEventListener('click', $handleClickOutside, true));
  onUnmounted(() => window.removeEventListener('click', $handleClickOutside, true));
};

export default {
  useClickOutside,
};
