type Size = {
  width: number;
  height: number;
};

interface Attributes {
  xmlns: string,
  fill: string,
  stroke: string,
  viewBox: string,
  height: string,
  width: string,
  style: {
    pointerEvents: string
  },
}

export const iconAttributes = (size: Size): Attributes => {
  const { width, height } = size;

  return {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'currentColor',
    stroke: 'none',
    viewBox: `0 0 ${width} ${height}`,
    height: '1em',
    width: `${width / height}em`,
    style: { pointerEvents: 'none' },
  };
};

export default {
  iconAttributes,
};
