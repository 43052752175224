<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.2173 1.284C23.74 1.80667 24.0009 2.43422 24 3.16667V21.8333C24 22.5667 23.7391 23.1947 23.2173 23.7173C22.6956 24.24 22.0676 24.5009 21.3333 24.5H2.66667C1.93333 24.5 1.30578 24.2391 0.784 23.7173C0.262222 23.1956 0.000888889 22.5676 0 21.8333V3.16667C0 2.43333 0.261333 1.80578 0.784 1.284C1.30667 0.762222 1.93422 0.500889 2.66667 0.5H21.3333C22.0667 0.5 22.6947 0.761333 23.2173 1.284ZM2.19821 22.3031C2.04903 22.1539 2.00039 22.0329 2 21.8318V3.16667C2 2.96858 2.04715 2.84902 2.19701 2.69942C2.34856 2.54812 2.4702 2.50038 2.66819 2.5H21.3333C21.5315 2.5 21.6522 2.54727 21.8031 2.69821C21.954 2.84909 22.0002 2.96852 22 3.16424V3.16667V21.8333C22 22.0326 21.9523 22.1536 21.8019 22.3043C21.6534 22.4531 21.5346 22.5002 21.3358 22.5H21.3333H2.66667C2.46747 22.5 2.34755 22.4525 2.19821 22.3031ZM7.08333 16.5H13.5833C13.8903 16.5 14.1476 16.4042 14.3552 16.2125C14.5628 16.0208 14.6667 15.7833 14.6667 15.5V13.3L17.6729 15.525C17.9438 15.725 18.2326 15.7583 18.5396 15.625C18.8465 15.4917 19 15.2667 19 14.95V10.05C19 9.73333 18.8465 9.50833 18.5396 9.375C18.2326 9.24167 17.9438 9.275 17.6729 9.475L14.6667 11.7V9.5C14.6667 9.21667 14.5628 8.97917 14.3552 8.7875C14.1476 8.59583 13.8903 8.5 13.5833 8.5H7.08333C6.77639 8.5 6.5191 8.59583 6.31146 8.7875C6.10382 8.97917 6 9.21667 6 9.5V15.5C6 15.7833 6.10382 16.0208 6.31146 16.2125C6.5191 16.4042 6.77639 16.5 7.08333 16.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 25 });
</script>
