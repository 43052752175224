<template>
  <ModalMenu
    class="lang-switcher"
    :isShow="isShow"
    fullWidth
    @close="handleClose"
  >
    <template #button>
      <ButtonRound
        class="lang-switcher__button-current"
        @click="handleToggle"
      >
        <component :is="localesIconsMapper[currentLang]" />
      </ButtonRound>
    </template>
    <template #content>
      <ModalMenuItem
        v-for="lang in availableLocales"
        :key="lang"
        :class="[{ active: currentLang === lang }]"
        :disabled="currentLang === lang"
        right
        @click="handleLangSelect(lang)"
      >
        {{ localesNamesMapper[lang] }}
        <template #icon="{ iconClass }">
          <component
            :is="localesIconsMapper[lang]"
            :class="iconClass"
          />
        </template>
      </ModalMenuItem>
    </template>
  </ModalMenu>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/i18n';
import { localesIconsMapper, localesNamesMapper } from '@/constants/languages';
import { availableLocales, setLocale } from '@/composition/useLangSet';

import ButtonRound from '@/components/ButtonRound.vue';
import ModalMenuItem from '@/components/Header/ModalMenuItem.vue';
import ModalMenu from '@/components/Header/ModalMenu.vue';

const router = useRouter();

const isShow = ref<boolean>(false);
const currentLang = computed<string>(() => i18n.global.locale.value);

const handleToggle = () => {
  isShow.value = !isShow.value;
};
const handleClose = () => {
  isShow.value = false;
};
const handleLangSelect = (lang: string) => {
  setLocale(lang);
  handleClose();
  // TODO Think to how refresh without location reload
  router.go(0);
};
</script>

<style lang="scss" src="./index.scss"></style>
