<template>
  <svg v-bind="attrs">
    <path d="M6.5 5.05578L11.5558 0L13 1.44422L7.94422 6.5L13 11.5558L11.5558 13L6.5 7.94422L1.44422 13L0 11.5558L5.05578 6.5L0 1.44422L1.44422 0L6.5 5.05578Z" />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 13, height: 13 });
</script>
