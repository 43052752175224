<template>
  <teleport to="#modal">
    <Modal
      v-if="isOpen"
      width="500"
      @close="handleClose"
    >
      <template #header>
        <h2>
          {{ t("labsSystemRequirements.baseTitle") }}
        </h2>
      </template>
      <p>
        <template
          v-for="(paragraph, index) in systemRequirements"
          :key="index"
        >
          <b v-if="paragraph.label">{{ t(paragraph.label) }}</b>
          {{ t(paragraph.description) }}
          <br />
        </template>
        <br />
        {{ t("labsSystemRequirements.checkWebGL") }}
        <a
          class="underline"
          href="https://webglreport.com/?v=2"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://webglreport.com/?v=2
        </a>
      </p>
    </Modal>
  </teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import Modal from '@/components/Modal.vue';
import { systemRequirements } from '@/constants/system-requirements';

const { t } = useI18n();
const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close', val: boolean): void;
}>();

const handleClose = () => emit('close', !props.isOpen);
</script>
