import { useEventListener } from '@/libraries/useEventListener';
import { throttle } from '@/utils/debounce';

export const useWindowResize = (callback: Function, delay: number): void => {
  useEventListener(
    window,
    'resize',
    throttle(() => callback(), delay)
  );
};

export default {
  useWindowResize,
};
