<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_16362_64246)">
      <path
        d="M3.32814 11.0578C4.24719 11.0578 4.99222 10.3173 4.99222 9.40388C4.99222 8.49047 4.24719 7.75 3.32814 7.75C2.4091 7.75 1.66406 8.49047 1.66406 9.40388C1.66406 10.3173 2.4091 11.0578 3.32814 11.0578Z"
        fill="currentColor"
      />
      <path
        d="M4.00489 15.5009C4.00489 14.4481 4.00766 12.7942 4.00766 11.6556C3.79429 11.6278 3.5794 11.6131 3.36422 11.6115C2.72863 11.5967 2.09757 11.7214 1.51604 11.9768C0.934522 12.2322 0.416867 12.6119 0 13.089V15.2253C0 15.2984 0.0292204 15.3685 0.081233 15.4202C0.133246 15.4719 0.20379 15.5009 0.277347 15.5009H4.00489Z"
        fill="currentColor"
      />
      <path
        d="M2.77636 15.4999C2.77636 14.4471 2.77359 12.7933 2.77359 11.6547C2.98696 11.6269 3.20185 11.6121 3.41703 11.6106C4.05262 11.5957 4.68368 11.7204 5.26521 11.9758C5.84673 12.2312 6.36438 12.611 6.78125 13.088V15.2243C6.78125 15.2974 6.75203 15.3675 6.70002 15.4192C6.648 15.4709 6.57746 15.4999 6.5039 15.4999H2.77636Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82009 10.1159C9.03223 10.2927 9.06089 10.608 8.88411 10.8201L6.38411 13.8201C6.20733 14.0322 5.89205 14.0609 5.67991 13.8841C5.46777 13.7073 5.43911 13.392 5.61589 13.1799L8.11589 10.1799C8.29267 9.96777 8.60795 9.93911 8.82009 10.1159Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0.5C7.44772 0.5 7 0.947715 7 1.5V8C7 8.55229 7.44772 9 8 9H14.5C15.0523 9 15.5 8.55229 15.5 8V1.5C15.5 0.947715 15.0523 0.5 14.5 0.5H8ZM8.5 2.5C8.5 2.22386 8.72386 2 9 2H13.5C13.7761 2 14 2.22386 14 2.5C14 2.77614 13.7761 3 13.5 3H9C8.72386 3 8.5 2.77614 8.5 2.5ZM9 4C8.72386 4 8.5 4.22386 8.5 4.5C8.5 4.77614 8.72386 5 9 5H13.5C13.7761 5 14 4.77614 14 4.5C14 4.22386 13.7761 4 13.5 4H9ZM9 6C8.72386 6 8.5 6.22386 8.5 6.5C8.5 6.77614 8.72386 7 9 7H13.5C13.7761 7 14 6.77614 14 6.5C14 6.22386 13.7761 6 13.5 6H9Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_16362_64246">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 16, height: 16 });
</script>
