<template>
  <svg v-bind="attrs">
    <path
      fill="#F2A800"
      d="M0 0h800v600H0z"
    />
    <path
      fill="#0033A0"
      d="M0 0h800v400H0z"
    />
    <path
      fill="#D90012"
      d="M0 0h800v200H0z"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 800, height: 600 });
</script>
