<template>
  <svg v-bind="attrs">
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#2E3192"
    />
    <path
      d="M16.2734 27.5862C13.5929 27.5857 10.9955 26.6558 8.9238 24.9549C6.85206 23.254 5.43416 20.8874 4.91169 18.2583C4.38922 15.6292 4.79451 12.9003 6.05849 10.5366C7.32247 8.1728 9.36695 6.32041 11.8436 5.29504C14.3202 4.26966 17.0757 4.13474 19.6407 4.91325C22.2057 5.69176 24.4213 7.33554 25.9102 9.56451C27.3991 11.7935 28.069 14.4698 27.8059 17.1373C27.5428 19.8049 26.3629 22.2987 24.4672 24.1938C23.3936 25.273 22.1166 26.1285 20.7102 26.7108C19.3037 27.2931 17.7957 27.5906 16.2734 27.5862Z"
      fill="#2E3192"
    />
    <path
      d="M16.2757 26.4827C22.0652 26.4827 26.7585 21.7894 26.7585 16C26.7585 10.2105 22.0652 5.51721 16.2757 5.51721C10.4863 5.51721 5.79297 10.2105 5.79297 16C5.79297 21.7894 10.4863 26.4827 16.2757 26.4827Z"
      fill="#77A7D9"
    />
    <path
      d="M16.8281 17.3794H15.7246V21.5173H16.8281V17.3794Z"
      fill="#2E3192"
    />
    <path
      d="M22.4836 11.0346C21.9148 10.7575 21.3045 10.6148 20.688 10.6148C20.0715 10.6148 19.4612 10.7575 18.8925 11.0346L18.4824 9.89973C19.1808 9.55632 19.9312 9.37939 20.6893 9.37939C21.4474 9.37939 22.1978 9.55632 22.8962 9.89973L22.4836 11.0346Z"
      fill="#2E3192"
    />
    <path
      d="M16.1361 24C15.3932 24.0023 14.6573 23.852 13.971 23.5579C13.2848 23.2637 12.662 22.8316 12.1387 22.2866L12.8856 21.5173C13.3125 21.9594 13.8195 22.3102 14.3777 22.5495C14.936 22.7888 15.5343 22.912 16.1387 22.912C16.743 22.912 17.3414 22.7888 17.8996 22.5495C18.4578 22.3102 18.9649 21.9594 19.3918 21.5173L20.1387 22.2866C19.615 22.8326 18.9914 23.2654 18.3042 23.5595C17.6169 23.8537 16.8799 24.0035 16.1361 24Z"
      fill="#2E3192"
    />
    <path
      d="M10.0669 11.0346L9.6543 9.89973C10.3527 9.55632 11.1031 9.37939 11.8612 9.37939C12.6193 9.37939 13.3697 9.55632 14.0681 9.89973L13.6554 11.0346C13.0872 10.7574 12.4773 10.6147 11.8612 10.6147C11.2451 10.6147 10.6352 10.7574 10.0669 11.0346Z"
      fill="#2E3192"
    />
    <path
      d="M11.8626 18.4828C13.5385 18.4828 14.8971 17.1242 14.8971 15.4483C14.8971 13.7724 13.5385 12.4138 11.8626 12.4138C10.1867 12.4138 8.82812 13.7724 8.82812 15.4483C8.82812 17.1242 10.1867 18.4828 11.8626 18.4828Z"
      fill="white"
    />
    <path
      d="M11.8653 19.3104C11.0467 19.311 10.2463 19.0767 9.56537 18.6374C8.88446 18.1981 8.35366 17.5734 8.04012 16.8424C7.72659 16.1114 7.64441 15.3069 7.80399 14.5307C7.96356 13.7545 8.35771 13.0416 8.93658 12.482C9.51545 11.9224 10.253 11.5414 11.056 11.3872C11.8589 11.2329 12.6911 11.3123 13.4473 11.6154C14.2036 11.9185 14.8498 12.4316 15.3043 13.0898C15.7587 13.748 16.001 14.5218 16.0005 15.3131C15.999 16.3728 15.5629 17.3887 14.7877 18.1381C14.0125 18.8874 12.9616 19.309 11.8653 19.3104ZM11.8653 12.4033C11.2699 12.4028 10.6876 12.573 10.1922 12.8924C9.69682 13.2118 9.31057 13.6661 9.08231 14.1977C8.85405 14.7294 8.79404 15.3145 8.90988 15.8791C9.02571 16.4438 9.31218 16.9625 9.73305 17.3697C10.1539 17.7769 10.6903 18.0543 11.2743 18.1668C11.8583 18.2793 12.4636 18.2219 13.0138 18.0017C13.564 17.7815 14.0343 17.4086 14.3652 16.93C14.6961 16.4514 14.8727 15.8887 14.8727 15.3131C14.8727 14.5419 14.5559 13.8021 13.992 13.2565C13.4281 12.7109 12.6632 12.404 11.8653 12.4033Z"
      fill="#2E3192"
    />
    <path
      d="M8.27472 14.8966H5.24023V16.0001H8.27472V14.8966Z"
      fill="#2E3192"
    />
    <path
      d="M17.1024 14.8966H15.4473V16.0001H17.1024V14.8966Z"
      fill="#2E3192"
    />
    <path
      d="M20.6888 18.4828C22.3647 18.4828 23.7233 17.1242 23.7233 15.4483C23.7233 13.7724 22.3647 12.4138 20.6888 12.4138C19.0129 12.4138 17.6543 13.7724 17.6543 15.4483C17.6543 17.1242 19.0129 18.4828 20.6888 18.4828Z"
      fill="#F7941D"
    />
    <path
      d="M20.6915 19.3104C19.8729 19.311 19.0724 19.0767 18.3915 18.6374C17.7106 18.1981 17.1798 17.5734 16.8663 16.8424C16.5528 16.1114 16.4706 15.3069 16.6302 14.5307C16.7897 13.7545 17.1839 13.0416 17.7628 12.482C18.3416 11.9224 19.0792 11.5414 19.8821 11.3872C20.6851 11.2329 21.5173 11.3123 22.2735 11.6154C23.0297 11.9185 23.676 12.4316 24.1304 13.0898C24.5849 13.748 24.8272 14.5218 24.8266 15.3131C24.8252 16.3728 24.389 17.3887 23.6139 18.1381C22.8387 18.8874 21.7878 19.309 20.6915 19.3104ZM20.6915 12.4033C20.096 12.4028 19.5138 12.573 19.0184 12.8924C18.523 13.2118 18.1367 13.6661 17.9085 14.1977C17.6802 14.7294 17.6202 15.3145 17.736 15.8791C17.8519 16.4438 18.1384 16.9625 18.5592 17.3697C18.9801 17.7769 19.5164 18.0543 20.1004 18.1668C20.6844 18.2793 21.2898 18.2219 21.84 18.0017C22.3902 17.7815 22.8605 17.4086 23.1914 16.93C23.5223 16.4514 23.6989 15.8887 23.6989 15.3131C23.6981 14.5421 23.3811 13.8028 22.8174 13.2573C22.2536 12.7118 21.4891 12.4047 20.6915 12.4033Z"
      fill="#2E3192"
    />
    <path
      d="M27.3099 14.8966H24.2754V16.0001H27.3099V14.8966Z"
      fill="#2E3192"
    />
    <path
      d="M12.6088 13.5172L11.9291 15.6598L11.2259 13.5172H9.93164L11.3327 17.3793H12.4447L13.7937 13.5172H12.6088Z"
      fill="#433C97"
    />
    <path
      d="M20.5953 13.5172H19.3105V17.1034H22.345V16.1256H20.5953V13.5172Z"
      fill="#433C97"
    />
  </svg>
</template>

<script setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 32, height: 32 });
</script>
