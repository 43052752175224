export const systemRequirements: Array<{
  label: string;
  description: string;
}> = [
  {
    label: 'labsSystemRequirements.labels.processor',
    description: 'labsSystemRequirements.descriptions.processor',
  },
  {
    label: 'labsSystemRequirements.labels.RAM',
    description: 'labsSystemRequirements.descriptions.RAM',
  },
  {
    label: 'labsSystemRequirements.labels.video',
    description: 'labsSystemRequirements.descriptions.video',
  },
  {
    label: 'labsSystemRequirements.labels.videoIntegrated',
    description: 'labsSystemRequirements.descriptions.videoIntegrated',
  },
  {
    label: 'labsSystemRequirements.labels.videoExternal',
    description: 'labsSystemRequirements.descriptions.videoExternal',
  },
  {
    label: 'labsSystemRequirements.labels.resolution',
    description: 'labsSystemRequirements.descriptions.resolution',
  },
  {
    label: 'labsSystemRequirements.labels.browsersSupport',
    description: 'labsSystemRequirements.descriptions.browsersSupport',
  },
  {
    label: 'labsSystemRequirements.labels.operationSystemsSupport',
    description:
      'labsSystemRequirements.descriptions.operationSystemsSupport',
  },
];

export default {
  systemRequirements,
};
