/* eslint-disable import/extensions */
import { createStore } from 'vuex';
import dictionary from '@/store/dictionary';
import { UserData } from './UserData.js';
import { ShopData } from './ShopData.js';
import { Manuals } from './Manuals.js';
import { Locales } from './Locales.js';
import { Common } from './Common.js';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    UserData,
    ShopData,
    dictionary,
    Manuals,
    Locales,
    Common,
  },
});
