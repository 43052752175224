import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const subscribe: RouteRecordRaw = {
  path: paths.subscribe,
  name: names.subscribe,
  meta: {
    permissions: permissions.subscribe,
    requiresAuth: true,
    isAuthed: false,
  },
  component: () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'subscriptions' */
    './index.vue'
  ),
};

export default subscribe;
