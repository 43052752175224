<template>
  <svg v-bind="attrs">
    <path
      d="M20.2922 20L14.0307 13.8854C13.5307 14.3011 12.9557 14.6229 12.3057 14.8507C11.6557 15.0786 11.0026 15.1925 10.3462 15.1925C8.74456 15.1925 7.38898 14.6511 6.27948 13.5683C5.17015 12.4855 4.61548 11.1625 4.61548 9.59944C4.61548 8.03617 5.1699 6.71207 6.27873 5.62715C7.38756 4.54238 8.74231 4 10.343 4C11.9438 4 13.2997 4.54165 14.4107 5.62495C15.5216 6.70841 16.077 8.03218 16.077 9.59626C16.077 10.2748 15.9539 10.9314 15.7077 11.5662C15.4616 12.2009 15.1385 12.7436 14.7385 13.1943L21 19.3091L20.2922 20ZM10.3462 14.216C11.6731 14.216 12.7932 13.77 13.7067 12.8779C14.6202 11.986 15.077 10.8921 15.077 9.59626C15.077 8.30041 14.6202 7.20653 13.7067 6.31463C12.7932 5.42256 11.6731 4.97653 10.3462 4.97653C9.01923 4.97653 7.89906 5.42256 6.98573 6.31463C6.07223 7.20653 5.61548 8.30041 5.61548 9.59626C5.61548 10.8921 6.07223 11.986 6.98573 12.8779C7.89906 13.77 9.01923 14.216 10.3462 14.216Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 25, height: 24 });
</script>
