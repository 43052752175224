import { RouteRecordRaw } from 'vue-router';
import { names, paths } from '@/constants/routes';

const invoices: RouteRecordRaw = {
  path: paths.invoices,
  name: names.invoices,

  component: () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'invoices' */
    './index.vue'
  ),
};

export default invoices;
