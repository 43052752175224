<template>
  <button
    :class="`${cssClass}__btn`"
    :aria-label="isFullscreen ? 'Выйти из полноэкранного режима' : 'Полноэкранный режим'"
    @click="emits('click')"
  >
    <IconFullscreenExit v-if="isFullscreen" />
    <IconFullscreen v-else />
  </button>
</template>

<script setup lang="ts">
import IconFullscreenExit from '@/components/icons/videoplayer/FullscreenExit.vue';
import IconFullscreen from '@/components/icons/videoplayer/Fullscreen.vue';

defineProps<{
  isFullscreen: boolean
}>();

const emits = defineEmits(['click']);

const cssClass = 'videoplayer';
</script>
