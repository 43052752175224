import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type !== 'textarea')
    ? (_openBlock(), _createElementBlock("input", _mergeProps({ key: 0 }, {
      ..._ctx.attrs,
      readonly: _ctx.static,
      tabindex: _ctx.static ? -1 : 0,
      type: _ctx.type,
    }, {
        key: _ctx.key,
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args))),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }), null, 16, _hoisted_1))
    : (_openBlock(), _createElementBlock("textarea", _mergeProps({ key: 1 }, {
      ..._ctx.attrs,
      readonly: _ctx.static,
      tabindex: _ctx.static ? -1 : 0,
      type: _ctx.type,
    }, {
        key: _ctx.key,
        value: _ctx.value,
        onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args))),
        onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
        onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }), null, 16, _hoisted_2))
}