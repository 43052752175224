const allowedNumberChars = '1234567890.-';

export const allowedInputTypes = [
  'date',
  'email',
  'number',
  'password',
  'tel',
  'text',
  'url',
  'textarea',
];
export const defaultInputType = 'text';

export const mapType = (type: string): string => {
  if (!allowedInputTypes.includes(type)) {
    return defaultInputType;
  }

  return type;
};

export const defaultProps = {
  align: {
    type: String,
    default: 'left',
    validator: (align: string): boolean => ['left', 'center', 'right'].includes(align),
  },
  fullwidth: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorText: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number],
  },
  static: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: defaultInputType,
    validator: (type: string): boolean => allowedInputTypes.includes(type),
  },
};

export const defaultEmits = ['input', 'keydown', 'blur', 'focus'];

export const filterNumberInput = (
  char: string
): boolean => allowedNumberChars.includes(char);
