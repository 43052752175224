<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_15437_50294)">
      <path
        d="M6.6665 8.66697C6.95281 9.04972 7.31808 9.36642 7.73754 9.5956C8.157 9.82477 8.62084 9.96105 9.0976 9.99519C9.57437 10.0293 10.0529 9.96055 10.5007 9.79349C10.9486 9.62643 11.3552 9.36502 11.6932 9.02697L13.6932 7.02697C14.3004 6.3983 14.6363 5.55629 14.6288 4.6823C14.6212 3.80831 14.2706 2.97227 13.6526 2.35424C13.0345 1.73621 12.1985 1.38565 11.3245 1.37806C10.4505 1.37046 9.60851 1.70644 8.97984 2.31364L7.83317 3.45364"
        stroke="currentColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33347 7.33381C9.04716 6.95106 8.68189 6.63435 8.26243 6.40518C7.84297 6.17601 7.37913 6.03973 6.90237 6.00559C6.4256 5.97144 5.94708 6.04023 5.49924 6.20729C5.0514 6.37435 4.64472 6.63576 4.3068 6.97381L2.3068 8.97381C1.69961 9.60248 1.36363 10.4445 1.37122 11.3185C1.37881 12.1925 1.72938 13.0285 2.3474 13.6465C2.96543 14.2646 3.80147 14.6151 4.67546 14.6227C5.54945 14.6303 6.39146 14.2943 7.02013 13.6871L8.16013 12.5471"
        stroke="currentColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15437_50294">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
