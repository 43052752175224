<template>
  <div
    v-if="editorComp"
    class="editor"
    :class="{ 'error': error }"
  >
    <MenuBar
      class="editor__header"
      :editor="editorComp"
    />
    <EditorContent
      class="editor__content"
      :editor="editorComp"
      :maxlength="maxlength"
    />
    <div
      v-if="showCounter"
      class="editor__counter"
      :class="{
        'editor__counter-max': editorComp.storage.characterCount.characters() === maxlength,
      }"
    >
      {{ editorComp.storage.characterCount.characters() }} / {{ maxlength }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs, watch } from 'vue';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';
import Link from '@tiptap/extension-link';
import { useEditor, EditorContent } from '@tiptap/vue-3';

import MenuBar from './MenuBar.vue';

const props = defineProps<{
  error?: boolean,
  value: string,
  maxlength: number,
  showCounter: boolean,
}>();

const emits = defineEmits<{
  (event: 'input', value: string): void,
}>();

const {
  value,
  maxlength,
} = toRefs(props);

const editorComp = useEditor({
  content: value.value,
  extensions: [
    StarterKit,
    Link,
    CharacterCount.configure({
      limit: maxlength.value,
    }),
  ],
  onUpdate: ({ editor }) => {
    emits(
      'input',
      editor.view.dom.innerHTML === '<p><br class="ProseMirror-trailingBreak"></p>'
        ? ''
        : editor.view.dom.innerHTML
    );
  },
});

watch(
  () => props.value,
  (newVal: string) => {
    const isSame = editorComp.value?.getHTML() === newVal;

    if (isSame) {
      return;
    }

    editorComp.value?.commands.setContent(newVal);
  }
);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.editor {
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: $input-border-radius;
  color: #0D0D0D;
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  min-height: 200px;
  width: 100%;

  &.error {
    border-color: $color-danger;
  }

  &__header {
    align-items: center;
    background-color: hsl(240, 22%, 96%);
    border-bottom: 1px solid $color-disabled;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

/* Basic editor styles */
.tiptap {
  a {
    color: $colorBlue1;
    text-decoration: underline;
    cursor: pointer;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    border-radius: 0.5rem;
    color: $color-white;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    border-left: 2px solid rgba(#0D0D0D, 0.1);
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      align-items: center;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}

.ProseMirror {
  min-height: 135px;
  height: fit-content;
  max-width: 875px;
  padding: 1rem;

  &:focus-visible {
    outline: none;
  }
}

.editor__counter {
  margin: .2rem 0;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 700;
  text-align: end;
  color: $color-gray;

  &-max {
    color: $color-black-lighter;
  }
}
</style>
