import { suRoles, orgRoles, usualRoles, roles } from '@/constants/roles';

export const isSU = (role: string): boolean => suRoles.includes(role);

export const isAdmin = (role: string): boolean => role === roles.admin;

export const isOrg = (role: string): boolean => orgRoles.includes(role);

export const isUsual = (role: string): boolean => usualRoles.includes(role);

export default {
  isSU,
  isOrg,
  isAdmin,
};
