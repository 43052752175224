<template>
  <svg v-bind="attrs">
    <g
      fill-rule="evenodd"
      stroke-width="1pt"
    >
      <path
        fill="#fff"
        d="M0 0h640v480H0z"
      />
      <path
        fill="#0039a6"
        d="M0 160h640v320H0z"
      />
      <path
        fill="#d52b1e"
        d="M0 320h640v160H0z"
      />
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 640, height: 480 });
</script>
