export const documents: Record<string, string> = {
  publicOffer: '/doc/{lang}/license.pdf',
  personalDataProcessingRules: '/doc/{lang}/policy.pdf',
  termsOfUse: '/doc/{lang}/user.pdf',
  fileExample: '/doc/{lang}/file_example.xls',
};

export default {
  documents,
};
