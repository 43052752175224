export const isEmpty = (val: unknown): boolean => {
  if (typeof val === 'object' && val !== null) {
    return !Object.keys(val).length;
  }

  if (val === null || val === undefined) {
    return true;
  }

  if (typeof val === 'number') {
    return false;
  }

  if (typeof val === 'string' || Array.isArray(val)) {
    return !val.length;
  }

  return false;
};

export const clearObjectEmptyFields = (
  obj: Record<string | number | symbol, unknown>,
  valueType: string
): any => Object.keys(obj)
  .map((key: any) => {
    const value = obj[key];
    if (typeof value === valueType && !value) {
      return null;
    }
    return { [key]: value };
  })
  .filter(Boolean)
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export default {
  isEmpty,
  clearObjectEmptyFields,
};
