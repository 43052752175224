export const ShopData = {
  state() {
    return {
      cart: [],
      isMonth: false,
    };
  },
  mutations: {
    ADD_CART(state, value) {
      state.cart.push(value);
    },
    REMOVE_CART(state, value) {
      state.cart = state.cart.filter((element) => element !== value);
    },
    CHANGE_PERIOD(state, value) {
      state.isMonth = value;
    },
  },
  actions: {
    toggleCart({ commit, state }, value) {
      if (state.cart.some((element) => element === value)) {
        commit('REMOVE_CART', value);
      } else {
        commit('ADD_CART', value);
      }
    },
  },
  getters: {
    basketSum(state) {
      if (state.isMonth) {
        return state.cart.reduce(
          (sum, element) => sum + element.price_per_month,
          0
        );
      }
      return state.cart.reduce(
        (sum, element) => sum + element.price_per_year,
        0
      );
    },
    basketDiscountSum(state) {
      if (state.isMonth) {
        return state.cart.reduce(
          (sum, element) => sum + element.price_per_month_with_discount,
          0
        );
      }
      return state.cart.reduce(
        (sum, element) => sum + element.price_per_year_with_discount,
        0
      );
    },
  },
};

export default {
  ShopData,
};
