import { Ref } from 'vue';
import { VueI18n } from 'vue-i18n';
import { ProfileField, SelectOption } from '@/types';
import { useRoleActionAccess } from '@/composition/useRoleActionAccess';
import { isAdmin, isSU, isUsual } from '@/utils/user';

const {
  canDoActionByArray,
  canDoActionByRoleId,
  roleId,
  role,
  selfProfileId,
} = useRoleActionAccess();

export const generateNewProfileField = (
  field: ProfileField,
  value: string,
  t: VueI18n['t'],
  opts?: Array<SelectOption>,
  name?: string,
  isOwnProfile: boolean = false,
): ProfileField => {
  let can_edit = true;
  let can_see = true;
  let options = opts;

  if (Array.isArray(field.can_edit)) {
    can_edit = canDoActionByArray(field.can_edit);
  }
  if (Array.isArray(field.can_see)) {
    can_see = canDoActionByArray(field.can_see);
  }

  if (name && name === 'role' && !isAdmin(role.value)) {
    // usual user can switch between student and teacher
    const isUsualUserOwnProfile = isUsual(role.value) && isOwnProfile;

    can_edit = !value
      || canDoActionByRoleId(Number(value))
      || isUsualUserOwnProfile;

    if (!isSU(role.value) && !isUsualUserOwnProfile && can_edit) {
      options = opts?.filter(({ value: val }) => val > roleId.value) || [];
    }
  }

  return {
    ...field,
    value,
    oldValue: value,
    label: `${t(field.label)}${field.required ? '*' : ''}`,
    options,
    can_edit,
    can_see,
  };
};

// TODO: expand types description with generics
export const updatedProfileFieldData = (
  profileData: Record<string, any>,
  fieldsMap: Record<string, ProfileField>,
  t: VueI18n['t'],
  optionsMapper?: Record<string, Array<SelectOption>>
): Record<string, ProfileField> => {
  const temp: Record<string, ProfileField> = {};
  const { request } = profileData;
  Object.keys(fieldsMap).forEach((key: string) => {
    const item: ProfileField = fieldsMap[key];
    let value: any = profileData[key] || '';
    const options = optionsMapper && item.type === 'select'
      ? optionsMapper[key]
      : undefined;
    if (typeof value === 'object' && !Array.isArray(value)) {
      value = value?.id || value?.name || '';
    }

    const isOwnProfile = profileData.id === selfProfileId.value;
    const field = generateNewProfileField(
      item, value, t, options, key, isOwnProfile
    );

    if (key === 'email' && 'email_verified' in profileData) {
      temp[key] = {
        ...field,
        email_verified: profileData.email_verified,
      };
      // TODO api/profile request - можно добавить флаг hasRequest, а данные возвращать в корректных полях organization и class.
    } else if (key === 'role') {
      const requestRole = request?.role;
      if (request && requestRole) {
        temp[key] = {
          ...field,
          value: requestRole.id,
        };
        return temp[key];
      }
      temp[key] = field;
      if (profileData.organization && isUsual(role.value)) {
        temp[key].can_edit = false;
      }
      return temp[key];
    } else if (key === 'class') {
      const requestClass = request?.class;
      if (request && requestClass) {
        temp[key] = {
          ...field,
          value: requestClass.id,
        };
        return temp[key];
      }
      temp[key] = field;
      return temp[key];
    } else if (key === 'organization') {
      const orgData = request ? request.organization : profileData[key];
      if (request) {
        temp[key] = {
          ...field,
          value: orgData.id,
          options: [],
          can_edit: false,
          baseOptions: [
            ...(orgData
              ? [
                {
                  label: orgData.name,
                  value: orgData.id,
                },
              ]
              : []),
          ],
        };
        return temp[key];
      }
      temp[key] = {
        ...field,
        options: [],
        can_edit: field.value ? false : field.can_edit,
        baseOptions: [
          ...(orgData
            ? [
              {
                label: orgData.name,
                value: orgData.id,
              },
            ]
            : []),
        ],
      };
      return temp[key];
    } else if (key === 'local_roles') {
      const isMeshOrg = profileData.organization?.name.toLowerCase() === 'mesh';

      temp[key] = {
        ...field,
        can_edit: isMeshOrg ? false : field.can_edit,
      };
      return temp[key];
    } else {
      temp[key] = field;
    }
  });
  return temp;
};

export const updatedProfileOrgFieldData = (
  orgData: Record<any, any>,
  fieldsMap: Ref,
  t: VueI18n['t'],
  optionsMapper?: Record<string, Array<SelectOption>>
): any => {
  const temp: Record<string, ProfileField> = {};
  Object.keys(fieldsMap.value).forEach((key: string) => {
    const item: ProfileField = fieldsMap.value[key];

    let value: any = ['region', 'city', 'country'].includes(key)
      ? orgData[key]
      : orgData.owner[key] || '';

    const options = optionsMapper && item.type === 'select' ? optionsMapper[key] : undefined;

    if (typeof value === 'object' && !Array.isArray(value)) {
      if (item.type === 'text') {
        value = value?.name || '';
      } else {
        value = value?.id || value?.name || '';
      }
    }
    const field = generateNewProfileField(item, value, t, options, key);

    if (key === 'email' && 'email_verified' in orgData.owner) {
      temp[key] = {
        ...field,
        email_verified: orgData.owner.email_verified,
      };
    } else {
      temp[key] = field;
    }
  });

  return temp;
};

export { getFileExtension } from './files';
export { createOptionsFromArray } from './select';

export default {
  generateNewProfileField,
  updatedProfileFieldData,
  updatedProfileOrgFieldData,
};
