import { checkUser } from '@/router/middleware/auth';

const removeElement = (id: string): void => {
  try {
    const el = document.getElementById(id);
    if (el) {
      el.parentNode?.removeChild(el);
    }
  } catch (e) {
    console.error(e);
  }
};

const firstLoadSpinnerRemove = (): void => {
  removeElement('loaderStyles');
  removeElement('loader');
};

const withIframeAuth = new Promise((resolve) => {
  checkUser().then((flag: boolean) => {
    if (!flag) {
      const redirect = 'api/sso/redirect';
      const baseUrlConfig = process.env.BASE_URL || '/';
      const pageBackLink = encodeURIComponent(window.location.href);
      const baseSRC = `${baseUrlConfig}${redirect}?front=${pageBackLink}&prompt=none`;
      const iframe = document.createElement('iframe');
      iframe.src = baseSRC;
      iframe.width = '100px';
      iframe.height = '100px';

      iframe.onload = () => {
        try {
          if (iframe.contentWindow && iframe.contentWindow.location.href) {
            if (iframe.contentWindow?.location.search.includes('error')) {
              resolve(false);
            } else {
              resolve(true);
            }
          }
        } catch (err) {
          console.error(err);
          resolve(false);
        } finally {
          document.body.removeChild(iframe);
          firstLoadSpinnerRemove();
        }
      };
      document.body.appendChild(iframe);
    } else {
      resolve(true);
      firstLoadSpinnerRemove();
    }
  });
});

export default withIframeAuth;
