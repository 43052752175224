import {
  createI18nMessage,
  required as requiredValidator,
  email as emailValidator,
  requiredIf as requiredIfValidator,
  minLength as minLengthValidator,
  maxLength as maxLengthValidator,
  minValue as minValueValidator,
} from '@vuelidate/validators';
import i18n from '@/i18n';

// Create your i18n message instance. Used for vue-i18n@9
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

// wrap each validator.
export const required = withI18nMessage(requiredValidator);
export const email = withI18nMessage(emailValidator);

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const requiredIf = withI18nMessage(requiredIfValidator, {
  withArguments: true,
});
export const minLength = withI18nMessage(minLengthValidator, {
  withArguments: true,
});
export const maxLength = withI18nMessage(maxLengthValidator, {
  withArguments: true,
});
export const minValue = withI18nMessage(minValueValidator, {
  withArguments: true,
});
