<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_19906_33804)">
      <path
        d="M20.0001 20.0011V10.0011C20.0001 7.46463 19.9569 3.95173 19.9995 0.00161275C18.1245 0.00088051 12.9865 0.00108421 10.0001 0.00108421C7.46401 0.00108421 3.95056 0.0397862 0.000332698 0C-0.000596613 1.875 0.000125572 7.01435 0.000125572 10.0011C0.000125572 12.9292 -0.000488281 18.1261 -0.000488281 20.0011H10.0001C12.86 20.0011 16.4279 19.9182 20.0001 20.0011Z"
        fill="#F0F0F0"
      />
      <path
        d="M-1.25005 12.5C-1.25005 13.75 -1.25049 18.75 -1.25049 20L6.24951 12.5H-1.25005Z"
        fill="#0052B4"
      />
      <path
        d="M1.24951 20.625C2.2715 21.4105 6.2199 20.9051 7.49951 21.25V14.375L1.24951 20.625Z"
        fill="#0052B4"
      />
      <path
        d="M-1.22185 10C-1.25059 10.625 -1.2795 10.5707 -1.25049 11.25H8.74931V21.25C9.48669 21.875 9.99942 21.2699 9.99942 21.25V10C9.99942 10 -0.59685 10 -1.22185 10Z"
        fill="#D80027"
      />
      <path
        d="M7.49951 12.5L-0.625488 20.625C-0.420332 20.8302 -0.222787 21.0632 -0.000443518 21.25L7.49951 13.75V12.5Z"
        fill="#D80027"
      />
      <path
        d="M-1.24956 7.78125C-1.24956 6.53125 -1.25 1.53125 -1.25 0.28125L6.25 7.78125H-1.24956Z"
        fill="#0052B4"
      />
      <path
        d="M1.25 -0.34375C2.27199 -1.12926 6.22039 -0.623828 7.5 -0.96875V5.90625L1.25 -0.34375Z"
        fill="#0052B4"
      />
      <path
        d="M-1.22136 10.2812C-1.2501 9.65625 -1.27901 9.71059 -1.25 9.03125H8.74979V-0.96875C9.48718 -1.59375 9.99991 -0.988612 9.99991 -0.968746V10.2812C9.99991 10.2812 -0.596362 10.2812 -1.22136 10.2812Z"
        fill="#D80027"
      />
      <path
        d="M7.5 7.78125L-0.625 -0.34375C-0.419844 -0.548985 -0.222299 -0.781953 4.47631e-05 -0.96875L7.5 6.53125V7.78125Z"
        fill="#D80027"
      />
      <path
        d="M21.2496 12.5C21.2496 13.75 21.25 18.75 21.25 20L13.75 12.5H21.2496Z"
        fill="#0052B4"
      />
      <path
        d="M18.75 20.625C17.728 21.4105 13.7796 20.9051 12.5 21.25V14.375L18.75 20.625Z"
        fill="#0052B4"
      />
      <path
        d="M21.2214 10C21.2501 10.625 21.279 10.5707 21.25 11.25H11.2502V21.25C10.5128 21.875 10.0001 21.2699 10.0001 21.25V10C10.0001 10 20.5964 10 21.2214 10Z"
        fill="#D80027"
      />
      <path
        d="M12.5 12.5L20.625 20.625C20.4198 20.8302 20.2223 21.0632 20 21.25L12.5 13.75V12.5Z"
        fill="#D80027"
      />
      <path
        d="M21.2496 7.78125C21.2496 6.53125 21.25 1.53125 21.25 0.28125L13.75 7.78125H21.2496Z"
        fill="#0052B4"
      />
      <path
        d="M18.75 -0.34375C17.728 -1.12926 13.7796 -0.623828 12.5 -0.96875V5.90625L18.75 -0.34375Z"
        fill="#0052B4"
      />
      <path
        d="M21.2214 10.2812C21.2501 9.65625 21.279 9.71059 21.25 9.03125H11.2502V-0.96875C10.5128 -1.59375 10.0001 -0.988612 10.0001 -0.968746V10.2812C10.0001 10.2812 20.5964 10.2812 21.2214 10.2812Z"
        fill="#D80027"
      />
      <path
        d="M12.5 7.78125L20.625 -0.34375C20.4198 -0.548985 20.2223 -0.781953 20 -0.96875L12.5 6.53125V7.78125Z"
        fill="#D80027"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 20 });
</script>
