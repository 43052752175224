<template>
  <svg v-bind="attrs">
    <path d="M12.6512 14.0654L11.6047 19.9999H9.57389L10.9247 12.3389L3.51465 4.92886L4.92886 3.51465L20.4852 19.071L19.071 20.4852L12.6512 14.0654ZM11.7727 7.53003L12.0425 5.99993H10.2426L8.24257 3.99993H19.9999V5.99993H14.0733L13.4991 9.25646L11.7727 7.53003Z" />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
