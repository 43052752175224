import cloneDeep from 'lodash.clonedeep';
import services from '@/services/admin';

const getOptionsByKeys = (obj) => Object.keys(obj.data).map((key) => ({
  value: key,
  label: obj.data[key],
}));

export const Common = {
  namespaced: true,
  state() {
    return {
      subjects: [],
      chapters: [],
      systemRequirementsOrigin: [],
      systemRequirements: [],
    };
  },
  getters: {
    subjects: (state) => state.subjects,
    chapters: (state) => state.chapters,
    systemRequirements: (state) => state.systemRequirements,
  },
  actions: {
    async getSubjects({ commit }) {
      const data = await services.getSections();
      commit('SET_SUBJECTS', data);
    },
    async getChapters({ commit }) {
      const data = await services.getSubsections();
      commit('SET_CHAPTERS', data);
    },
    async getSystemRequirements({ commit }) {
      try {
        const data = await services.getSystemReq();
        commit('SET_SYSTEM_REQ', cloneDeep(data.data));
        commit('SET_SYSTEM_REQ_ORIGIN', cloneDeep(data.data));
      } catch (e) {
        console.error(e);
      }
    },
    async setSystemRequirements({ commit }, list) {
      try {
        await services.setSystemReq(list);
        commit('SET_SYSTEM_REQ', cloneDeep(list));
        commit('SET_SYSTEM_REQ_ORIGIN', cloneDeep(list));
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    SET_SUBJECTS: (state, data) => {
      state.subjects = getOptionsByKeys(data);
    },
    SET_CHAPTERS: (state, data) => {
      state.chapters = getOptionsByKeys(data);
    },
    SET_SYSTEM_REQ: (state, data) => {
      state.systemRequirements = data;
    },
    SET_SYSTEM_REQ_ORIGIN: (state, data) => {
      state.systemRequirementsOrigin = data;
    },
    RESET_SYSTEM_REQ: (state) => {
      state.systemRequirements = cloneDeep(state.systemRequirementsOrigin);
    },
  },
};

export default {
  Common,
};
