<template>
  <svg v-bind="attrs">
    <path
      d="M4.94802 23.3327V19.0507H0.666016V17.0507H6.94802V23.3327H4.94802ZM17.0633 23.3327V17.0507H23.3454V19.0507H19.0633V23.3327H17.0633ZM0.666016 6.94802V4.94802H4.94802V0.666016H6.94802V6.94802H0.666016ZM17.0633 6.94802V0.666016H19.0633V4.94802H23.3454V6.94802H17.0633Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
