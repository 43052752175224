import { VueI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { toast } from '@/utils/toast';

const isRecord = (
  value: unknown
): value is Record<string, unknown> => typeof value === 'object' && value !== null;

const isAxiosError = <T>(error: unknown): error is AxiosError<T> => {
  const isError = error instanceof Error;
  return isError
    && isRecord(error)
    && typeof error.isAxiosError === 'boolean';
};

export const catchErrorShow = (err: unknown, t: VueI18n['t']): void => {
  let message: string | undefined;

  if (isAxiosError<{ message?: string }>(err)) {
    message = err.response?.data?.message;
  }
  toast.error(message || t('somethingWentWrong'));
  console.error(err);
};

export default {
  catchErrorShow,
};
