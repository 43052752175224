<template>
  <svg v-bind="attrs">
    <g clip-path="url(#clip0_19906_33791)">
      <rect
        width="20"
        height="6"
        transform="matrix(-1 0 0 1 20 0)"
        fill="#F7FCFF"
      />
      <rect
        width="20"
        height="8"
        transform="matrix(-1 0 0 1 20 6)"
        fill="#0052B4"
      />
      <rect
        width="20"
        height="6"
        transform="matrix(-1 0 0 1 20 14)"
        fill="#D80027"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 20, height: 20 });
</script>
