export const imageCandidatesToSrcset = (
  sources: Record<string, string | undefined>
): string => Object.entries(sources)
  .reduce<string[]>((acc, [density, url]) => {
    if (url) {
      acc.push(`${url} ${density}`);
    }
    return acc;
  }, [])
  .join(', ');

export default {
  imageCandidatesToSrcset,
};
