<template>
  <svg v-bind="attrs">
    <path
      d="M1.13476 1L1.13476 1L16.8652 0.999998L9 63.048L1.13476 1Z"
      fill="transparent"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 71 });
</script>
