<template>
  <svg v-bind="attrs">
    <path
      d="M0.0205926 12.3842L0.0452547 5.71763L4.99389 5.73594L10.7327 0.0394582L10.6657 18.1413L4.96923 12.4026L0.0205926 12.3842ZM13.8608 13.9738L13.8971 4.16386C14.7944 4.65607 15.4867 5.34708 15.9741 6.23689C16.4613 7.1267 16.7029 8.08527 16.6991 9.11259C16.6954 10.1261 16.4445 11.0683 15.9463 11.9392C15.4482 12.81 14.7531 13.4882 13.8608 13.9738ZM8.71473 4.88303L5.83748 7.73907L2.03751 7.72501L2.02764 10.3917L5.82762 10.4057L8.68366 13.283L8.71473 4.88303Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 17, height: 19 });
</script>
