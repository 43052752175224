<template>
  <svg v-bind="attrs">
    <path
      d="M6.91197 1.28571C6.91197 0.575634 7.4876 0 8.19768 0H9.4834C10.1935 0 10.7691 0.575634 10.7691 1.28571V16.7143C10.7691 17.4244 10.1935 18 9.4834 18H8.19768C7.4876 18 6.91197 17.4244 6.91197 16.7143V1.28571Z"
      fill="currenColor"
    />
    <path
      d="M1.28571 10.9286C0.575635 10.9286 8.72376e-08 10.3529 5.6199e-08 9.64286L0 8.35714C-3.10386e-08 7.64706 0.575634 7.07143 1.28571 7.07143L16.7143 7.07143C17.4244 7.07143 18 7.64706 18 8.35714V9.64286C18 10.3529 17.4244 10.9286 16.7143 10.9286L1.28571 10.9286Z"
      fill="currenColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 18 });
</script>
