/* eslint-disable */
// @ts-nocheck
const addMailScript = () => {
  const noscriptImg = document.createElement('img');
  noscriptImg.src = 'https://top-fwz1.mail.ru/counter?id=3360896;js=na';
  noscriptImg.style.position = 'absolute';
  noscriptImg.style.left = '-9999px';
  noscriptImg.alt = 'Top.Mail.Ru';

  const script = document.createElement('script');
  script.textContent = `
        (window._tmr||(window._tmr=[])).push({
            id:"3360896",
            type:"pageView",
            start:(new Date).getTime()
        });

        (function(e,t,n){
            if(!e.getElementById(n)){
                var r=e.createElement("script");
                r.type="text/javascript";
                r.async=true;
                r.id=n;
                r.src="https://top-fwz1.mail.ru/js/code.js";
                var a=function(){
                    var t=e.getElementsByTagName("script")[0];
                    t.parentNode.insertBefore(r,t)
                };
                "[object Opera]"==t.opera ? e.addEventListener("DOMContentLoaded",a,false) : a()
            }
        })(document,window,"tmr-code");
    `
  script.type = 'text/javascript';
  document.head.appendChild(script);
  document.head.appendChild(noscriptImg);
};

const addVkScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://vk.com/js/api/openapi.js?169';

  // eslint-disable-next-line prettier/prettier
  script.onload = function() {
    VK.Retargeting.Init('VK-RTRG-1512167-5krQl');
    VK.Retargeting.Hit();
  };

  // Append the script to the head
  document.head.appendChild(script);

  // Create the noscript image tag
  const noscriptImg = document.createElement('img');
  noscriptImg.src = 'https://vk.com/rtrg?p=VK-RTRG-1512167-5krQl';
  noscriptImg.style.position = 'fixed';
  noscriptImg.style.left = '-999px';
  noscriptImg.alt = '';

  // Append the noscript image to the body
  document.head.appendChild(noscriptImg);
};

const addYandexMetrika = () => {
  const yMetrikaNumber = process.env.VUE_APP_YMETRIKA_NUMBER;
  // Create the main YMetrika script
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://mc.yandex.ru/metrika/tag.js';

  script.onload = function() {
    window.ym = window.ym || function() {
        (window.ym.a = window.ym.a || []).push(arguments);
      };
    window.ym.l = 1 * new Date();
    ym(yMetrikaNumber, 'init', {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  };

  // Insert the script before other scripts in the document
  // const firstScript = document.getElementsByTagName('script')[0];
  document.head.appendChild(script);
  // firstScript.parentNode?.insertBefore(script, firstScript);

  // Create the noscript image tag
  const noscriptImg = document.createElement('img');
  noscriptImg.src = `https://mc.yandex.ru/watch/${  yMetrikaNumber}`;
  noscriptImg.style.position = 'absolute';
  noscriptImg.style.left = '-9999px';
  noscriptImg.alt = '';

  // Append the noscript image to the body
  document.head.appendChild(noscriptImg);
};

const loadScript = () => {
  addYandexMetrika();
  addVkScript();
  addMailScript();
};

export default loadScript;
