<template>
  <button
    type="button"
    class="button"
  >
    <slot />
  </button>
</template>

<style lang="scss">
.button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
</style>
