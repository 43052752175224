<template>
  <svg v-bind="attrs">
    <path
      d="M14.4 14.9C13.401 14.9 12.6 15.701 12.6 16.7C12.6 17.1774 12.7896 17.6352 13.1272 17.9728C13.4648 18.3104 13.9226 18.5 14.4 18.5C14.8774 18.5 15.3352 18.3104 15.6728 17.9728C16.0104 17.6352 16.2 17.1774 16.2 16.7C16.2 16.2226 16.0104 15.7648 15.6728 15.4272C15.3352 15.0896 14.8774 14.9 14.4 14.9ZM0 0.5V2.3H1.8L5.04 9.131L3.816 11.336C3.681 11.588 3.6 11.885 3.6 12.2C3.6 12.6774 3.78964 13.1352 4.12721 13.4728C4.46477 13.8104 4.92261 14 5.4 14H16.2V12.2H5.778C5.71833 12.2 5.6611 12.1763 5.6189 12.1341C5.57671 12.0919 5.553 12.0347 5.553 11.975C5.553 11.93 5.562 11.894 5.58 11.867L6.39 10.4H13.095C13.77 10.4 14.364 10.022 14.67 9.473L17.892 3.65C17.955 3.506 18 3.353 18 3.2C18 2.96131 17.9052 2.73239 17.7364 2.5636C17.5676 2.39482 17.3387 2.3 17.1 2.3H3.789L2.943 0.5M5.4 14.9C4.401 14.9 3.6 15.701 3.6 16.7C3.6 17.1774 3.78964 17.6352 4.12721 17.9728C4.46477 18.3104 4.92261 18.5 5.4 18.5C5.87739 18.5 6.33523 18.3104 6.67279 17.9728C7.01036 17.6352 7.2 17.1774 7.2 16.7C7.2 16.2226 7.01036 15.7648 6.67279 15.4272C6.33523 15.0896 5.87739 14.9 5.4 14.9Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 19 });
</script>
