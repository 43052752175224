export const printElem = (data) => {
  const myWindow = window.open('', 'QRCode', 'height=400,width=600');
  myWindow.document.write('<html><head><title>my div</title>');

  /*
   * optional stylesheet:
   * // myWindow.document.write('<link rel='stylesheet' href='main.css' type='text/css' />');
   */

  myWindow.document.write('</head><body >');
  myWindow.document.write(data);
  myWindow.document.write('</body></html>');

  // necessary for IE >= 10
  myWindow.document.close();

  myWindow.onload = () => {
    // necessary if the div contain images

    // necessary for IE >= 10
    myWindow.focus();

    myWindow.print();
    myWindow.close();
  };
};

export default {
  printElem,
};
