<template>
  <div class="carousel">
    <div class="carousel__wrapper">
      <button
        v-if="totalPages > 1"
        class="carousel__nav-btn carousel__nav-btn_prev"
        :class="{ 'disabled': currentPage === 1 }"
        @click="getPreviousPage"
      >
        <ChevronSlim />
      </button>

      <div
        ref="$slidesContainer"
        class="carousel__slides-container"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <slot />
      </div>

      <button
        v-if="totalPages > 1"
        class="carousel__nav-btn carousel__nav-btn_next"
        :class="{ 'disabled': currentPage === totalPages }"
        @click="getNextPage"
      >
        <ChevronSlim />
      </button>
    </div>

    <div
      v-if="totalPages > 1"
      class="carousel__pagination"
    >
      <button
        v-for="(page, index) in totalPages"
        :key="`page-${index}`"
        class="carousel__pagination-bullet"
        :class="{ 'active': index + 1 === currentPage }"
        @click="changePage(index + 1)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import debounce from '@/utils/debounce';
import ChevronSlim from '@/components/icons/ChevronSlim.vue';

const props = defineProps<{
  totalSlides: number,
  slidesPerPage: number,
  slideWidth: number,
  containerGap: number,
}>();

const totalPages = computed(() => Math.ceil(props.totalSlides / props.slidesPerPage));
const currentPage = ref<number>(1);
const $slidesContainer = ref<HTMLElement | null>(null);
const slideShift = (props.slideWidth + props.containerGap) * props.slidesPerPage;

const PAGE_CHANGE_WAIT_TIME = 300;

const getPreviousPage = debounce((): void => {
  if ($slidesContainer.value && currentPage.value > 1) {
    $slidesContainer.value.scrollLeft -= slideShift;
    currentPage.value--;
  }
}, PAGE_CHANGE_WAIT_TIME);

const getNextPage = debounce((): void => {
  if ($slidesContainer.value && currentPage.value < totalPages.value) {
    $slidesContainer.value.scrollLeft += slideShift;
    currentPage.value++;
  }
}, PAGE_CHANGE_WAIT_TIME);

const changePage = debounce((index: number): void => {
  if ($slidesContainer.value) {
    const pageDifference = index - currentPage.value;
    $slidesContainer.value.scrollLeft += pageDifference * slideShift;
    currentPage.value = index;
  }
}, PAGE_CHANGE_WAIT_TIME);

const handleKeydown = (event: any) => {
  if (event.key === 'ArrowRight') { getNextPage(); }
  if (event.key === 'ArrowLeft') { getPreviousPage(); }
};

const handleMouseEnter = () => {
  document.addEventListener('keydown', handleKeydown);
};

const handleMouseLeave = () => {
  document.removeEventListener('keydown', handleKeydown);
};
</script>

<style lang="scss">
@import "@/assets/style/include.scss";
$color-indigo-light: hsl(224, 100%, 86%); // #b6caff

.carousel {
  width: calc($containerW - 150px);

  &__wrapper {
    display: flex;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__nav-btn {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    border: 1px solid transparent;
    background-color: transparent;
    transition: border-color $transition-speed;
    color: $colorBlue1;

    &:hover {
      color: $colorBlueHover;
    }

    &:active {
      color: $colorBluePressed;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      color: $input-border-color;
      pointer-events: none;
    }

    & svg {
      width: 18px;
      height: 18px;
    }

    &_prev {
      left: -43px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 0;
    }

    &_next {
      right: -43px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: 0;

      & svg {
        transform: rotate(180deg);
      }
    }
  }

  &__slides-container {
    display: flex;
    column-gap: 8px;
    overflow-x: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  &__pagination-bullet {
    width: 10px;
    height: 10px;
    border: 1px solid $color-indigo-light;
    border-radius: 1px;
    padding: 0;
    background-color: transparent;
    transform: rotate(45deg);
    transition:
      background-color $transition-speed,
      border-color $transition-speed;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      background-color: $color-indigo-light;
    }

    &.active {
      border-color: $color-primary;
      background-color: $color-primary;
    }
  }

  .lab__video-link,
  .lab__video-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 264px;
    height: 100%;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: hsl(0, 0%, 98%);
    transition: border-color $transition-speed;

    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      border-color: $color-primary;
    }
  }

  .lab__video-btn {
    padding: 0;
  }

  .lab__video-cover {
    width: 100%;
    height: 136px;
    object-fit: cover;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
  }

  .lab__video-title {
    display: inline-block;
    padding: 11px 10px;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    color: hsl(0, 0%, 16%);
  }

}
</style>
