<template>
  <svg v-bind="attrs">
    <path
      d="M5.75972 14.3826C5.1636 14.3826 4.65752 14.1746 4.24148 13.7585C3.82545 13.3425 3.61743 12.8364 3.61743 12.2403V2.14232C3.61743 1.54619 3.82545 1.04011 4.24148 0.624076C4.65752 0.208025 5.1636 0 5.75972 0H15.8577C16.4538 0 16.9599 0.208025 17.3759 0.624076C17.792 1.04011 18 1.54619 18 2.14232V12.2403C18 12.8364 17.792 13.3425 17.3759 13.7585C16.9599 14.1746 16.4538 14.3826 15.8577 14.3826H5.75972ZM5.75972 12.5085H15.8577C15.9248 12.5085 15.9862 12.4806 16.0421 12.4247C16.098 12.3688 16.1259 12.3073 16.1259 12.2403V2.14232C16.1259 2.07526 16.098 2.01378 16.0421 1.95789C15.9862 1.90202 15.9248 1.87408 15.8577 1.87408H5.75972C5.69266 1.87408 5.6312 1.90202 5.57532 1.95789C5.51943 2.01378 5.49149 2.07526 5.49149 2.14232V12.2403C5.49149 12.3073 5.51943 12.3688 5.57532 12.4247C5.6312 12.4806 5.69266 12.5085 5.75972 12.5085ZM2.14229 18C1.54618 18 1.0401 17.792 0.624054 17.3759C0.208018 16.9599 0 16.4538 0 15.8577V4.82269C0 4.56482 0.0917366 4.34418 0.27521 4.16077C0.458682 3.97737 0.679391 3.88567 0.937334 3.88567C1.19528 3.88567 1.41589 3.97737 1.59917 4.16077C1.78244 4.34418 1.87408 4.56482 1.87408 4.82269V15.8577C1.87408 15.9248 1.90202 15.9862 1.95789 16.0421C2.01378 16.098 2.07525 16.1259 2.14229 16.1259H13.1773C13.4352 16.1259 13.6558 16.2177 13.8392 16.4011C14.0226 16.5846 14.1144 16.8053 14.1144 17.0633C14.1144 17.3212 14.0226 17.5418 13.8392 17.7251C13.6558 17.9084 13.4352 18 13.1773 18H2.14229Z"
      fill="currentColor"
    />
    <path
      d="M7.07159 4.5C7.07159 4.14496 7.3594 3.85714 7.71444 3.85714H14.143C14.4981 3.85714 14.7859 4.14496 14.7859 4.5C14.7859 4.85504 14.4981 5.14286 14.143 5.14286H7.71444C7.3594 5.14286 7.07159 4.85504 7.07159 4.5Z"
      fill="currentColor"
    />
    <path
      d="M7.07159 7.07143C7.07159 6.71639 7.3594 6.42857 7.71444 6.42857H14.143C14.4981 6.42857 14.7859 6.71639 14.7859 7.07143C14.7859 7.42647 14.4981 7.71429 14.143 7.71429H7.71444C7.3594 7.71429 7.07159 7.42647 7.07159 7.07143Z"
      fill="currentColor"
    />
    <path
      d="M7.71444 9C7.3594 9 7.07159 9.28782 7.07159 9.64286C7.07159 9.9979 7.3594 10.2857 7.71444 10.2857H14.143C14.4981 10.2857 14.7859 9.9979 14.7859 9.64286C14.7859 9.28782 14.4981 9 14.143 9H7.71444Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 18 });
</script>
