<template>
  <svg v-bind="attrs">
    <rect
      x="18.7175"
      y="19.4258"
      width="20"
      height="1"
      transform="rotate(-135 18.7175 19.4258)"
      fill="currenColor"
    />
    <rect
      x="4.57544"
      y="18.7188"
      width="20"
      height="1"
      transform="rotate(-45 4.57544 18.7188)"
      fill="#currenColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
