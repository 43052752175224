<template>
  <component
    :is="Logo"
    class="logo_link__image"
  />
  <LogoMobile class="logo_link__image_mobile" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { currentI18nLocale } from '@/composition/useLangSet';

import LogoRu from './Logos/Ru.vue';
import LogoEn from './Logos/En.vue';
import LogoMobile from './Logos/Mobile.vue';

type Component = InstanceType<typeof LogoRu> | InstanceType<typeof LogoEn>;

const logosMapper: Record<string, Component> = { ru: LogoRu, en: LogoEn };

const Logo = computed<Component>(
  () => logosMapper[currentI18nLocale.value] || LogoEn
);
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.logo_link__image {
  @include media-breakpoint-down("md") {
    display: none;
  }
}

.logo_link__image_mobile {
  @include media-breakpoint-up("md") {
    display: none;
  }
}
</style>
