import {
  SubscribedContext,
  PublicationContext,
  UnsubscribedContext,
  ConnectedContext,
  DisconnectedContext,
  ErrorContext,
  MessageContext,
} from 'centrifuge';

type wsEvent =
  SubscribedContext
  | PublicationContext
  | UnsubscribedContext
  | ConnectedContext
  | DisconnectedContext
  | ErrorContext
  | MessageContext

export const wsMessageLog = (ev: wsEvent, type?: string): void => {
  if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable no-console */
    if (type) {
      console.log('%c %s', 'color: #efc014', 'WebSocketEvent: ', type);
    }
    console.log('%c %s', 'color: green', new Date().toISOString(), ev);
    console.log('%c %s', 'color: #efc014; background: #ffffee', '----------');
    /* eslint-enable no-console */
  }
};

export default {
  wsMessageLog,
};
