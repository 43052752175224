import { SelectOption } from '@/types';

// TODO think about types (findOrgByText)
export const createOptionsFromArray = (
  data: Array<unknown>
): SelectOption[] => data.map((item: any) => ({
  label: item?.name,
  value: item?.id,
  shortname: item?.shortname,
}));

export const uniqueOptions = (arr: Array<SelectOption>): SelectOption[] => {
  const maper: Map<number | string, SelectOption> = new Map();
  arr.forEach((opt) => {
    maper.set(opt.value, opt);
  });

  return Array.from(maper.values());
};

export default {
  createOptionsFromArray,
  uniqueOptions,
};
