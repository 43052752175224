import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { Numeral, FIO, ProfileField } from '@/types';
import { currentI18nLocale } from '@/composition/useLangSet';

type Inputs = Record<string, ProfileField>;
type DataRequestFormatted = Record<
  string,
  string | number | (string | number)[]
>;

export const formatCurrency = (
  val: unknown,
  config = { minimumFractionDigits: 0 }
): string => {
  let result = val;

  if (typeof result === 'string') {
    result = parseFloat(result.replace(/[^0-9.]/g, ''));
  }

  if (typeof result === 'number') {
    const { t, locale } = useI18n();

    // Деление на 100 с сохранением десятых и округлением в большую сторону
    if (locale.value !== 'ru') {
      result = Math.ceil(result / 10) / 10;
    }

    return (result as number).toLocaleString(locale.value, {
      style: 'currency',
      currency: t('price.currencyName'),
      ...config,
    });
  }

  return '';
};

export const formatDate = (
  date: dayjs.ConfigType,
  format: string = 'DD.MM.YYYY'
): string => dayjs(date).format(format);

export const formatDatepicker = (
  date: dayjs.ConfigType
): string => dayjs(date).format('YYYY-MM-DD');

export const deepCloneSimpleObject = <T>(
  obj: T
): T => JSON.parse(JSON.stringify(obj));

export const formatNumberToLocale = (
  num: number
): string => Number(num).toLocaleString(String(currentI18nLocale.value)) || '';

export const formatBreakLines = (text?: string): string => {
  if (text) {
    return text.replace(/\n+/gm, '<br>');
  }
  return '';
};

export const createNumeral = (
  { one, few, many }: Numeral
): Function => (number: number): string => {
  let n = Math.abs(number) % 100;

  if (n >= 5 && n <= 20) {
    return many;
  }

  n %= 10;

  if (n === 1) {
    return one;
  }

  if (n > 1 && n < 5) {
    return few;
  }

  return many;
};

export const formatNumber = (
  number: number
): string => new Intl.NumberFormat().format(number);

export const createFIO = (
  { firstname, surname, patronymic }: FIO
): string => `${surname || ' '} ${firstname || ' '} ${patronymic || ' '}`
  .replace(
    /\s\s+/gm,
    ''
  );

export const formatDataRequest: (inputs: Inputs) => DataRequestFormatted = (
  inputs: Inputs
) => Object
  .entries(inputs)
  .reduce<DataRequestFormatted>(
    (acc, [key, field]) => {
      acc[field.apiName || key] = field.value;
      return acc;
    }, {}
  );

export default {
  formatCurrency,
  formatDate,
  formatDatepicker,
  formatBreakLines,
  deepCloneSimpleObject,
  formatNumberToLocale,
  createNumeral,
  formatNumber,
  createFIO,

  formatDataRequest,
};
