<template>
  <svg v-bind="attrs">
    <path
      d="M9.53932 18.3346H11.539V8.33464H9.53932V18.3346ZM14.4623 18.3346H16.462V8.33464H14.4623V18.3346ZM13.003 26.0013C11.251 26.0013 9.60421 25.6689 8.06265 25.004C6.5211 24.3391 5.18021 23.4367 4.03998 22.297C2.89976 21.1572 1.99698 19.8169 1.33165 18.276C0.66654 16.7351 0.333984 15.0887 0.333984 13.337C0.333984 11.585 0.666429 9.93819 1.33132 8.39664C1.99621 6.85508 2.89854 5.51419 4.03832 4.37397C5.1781 3.23375 6.51843 2.33097 8.05932 1.66564C9.60021 1.00052 11.2465 0.667969 12.9983 0.667969C14.7503 0.667969 16.3971 1.00041 17.9387 1.6653C19.4802 2.33019 20.8211 3.23252 21.9613 4.3723C23.1015 5.51208 24.0043 6.85241 24.6697 8.3933C25.3348 9.93419 25.6673 11.5805 25.6673 13.3323C25.6673 15.0843 25.3349 16.7311 24.67 18.2726C24.0051 19.8142 23.1028 21.1551 21.963 22.2953C20.8232 23.4355 19.4829 24.3383 17.942 25.0036C16.4011 25.6687 14.7548 26.0013 13.003 26.0013ZM13.0007 24.0013C15.9784 24.0013 18.5007 22.968 20.5673 20.9013C22.634 18.8346 23.6673 16.3124 23.6673 13.3346C23.6673 10.3569 22.634 7.83464 20.5673 5.76797C18.5007 3.7013 15.9784 2.66797 13.0007 2.66797C10.0229 2.66797 7.50065 3.7013 5.43398 5.76797C3.36732 7.83464 2.33398 10.3569 2.33398 13.3346C2.33398 16.3124 3.36732 18.8346 5.43398 20.9013C7.50065 22.968 10.0229 24.0013 13.0007 24.0013Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 26, height: 26 });
</script>
