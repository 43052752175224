export const setStorageItem = (name: string, value: unknown): void => {
  const localStorageValue: string = JSON.stringify(value);
  localStorage.setItem(name, localStorageValue);
};

export const killStorageItem = (name: string): void => {
  localStorage.removeItem(name);
};

export const getStorageItem = <T>(name: string): T | null => {
  const localStorageValue: string | null = localStorage.getItem(name);
  return localStorageValue !== null ? JSON.parse(localStorageValue) : null;
};
