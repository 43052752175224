<template>
  <component
    :is="linkMore ? 'router-link' : 'div'"
    :to="linkMore"
    :class="['card', { hold: isPromo }]"
  >
    <div class="card__subject">
      <div>{{ card.subsection.name }}</div>

      <template v-if="props.card.license_state && licenseStateLabel">
        <Tooltip
          v-if="card.active_for_user"
          position="top"
          class="card__tooltip-time"
          :message="expireDateText"
        >
          <span
            class="card__subscribe"
            :class="{ expire: isSoonExpire }"
          >
            {{ licenseStateLabel }}
          </span>
        </Tooltip>
        <div v-else>
          <span class="card__subscribe">
            {{ licenseStateLabel }}
          </span>
        </div>
      </template>
    </div>

    <div class="card__image-box">
      <picture
        :class="{
          'opacity-is-draft': isDraft,
          'opacity-not-published': isPublication || isNotPublished,
          'opacity-anons-not-published': isPromo && isNotPublished,
        }"
      >
        <source
          v-for="(srcset, media) in mediaSrcsets"
          :key="media"
          :media="media"
          :srcset="srcset"
        />
        <img
          src="/img/empty/card.png"
          :alt="card.name"
          class="card__img"
        />
      </picture>

      <div class="card__front">
        <template v-if="isSuAccessRole">
          <Tooltip
            v-if="isPublication"
            :message="t('publication')"
          >
            <button
              class="card__btn-public"
              type="button"
            >
              <IconPublishing />
            </button>
          </Tooltip>
          <Tooltip
            v-else-if="isNotPublished"
            :message="t('notPublished')"
          >
            <button
              class="card__btn-public"
              type="button"
            >
              <IconEyeSlash />
            </button>
          </Tooltip>
          <Tooltip
            v-else-if="isDraft"
            :message="t('draft')"
          >
            <button
              class="card__btn-public"
              type="button"
            >
              <IconPencilLine />
            </button>
          </Tooltip>
        </template>

        <div
          v-if="card.has_workbook"
          class="card__workbook"
        >
          <IconWorkbook />
        </div>

        <div
          v-if="isPromo"
          class="card__hold"
        >
          {{ t("labs.soon") }}
        </div>
        <a
          v-else-if="!isDraft && !isGuest && linkLaunch"
          class="card__play"
          :href="linkLaunch"
          target="_blank"
          @click.stop="handlePlay"
        >
          <IconPlay />
        </a>

        <div class="card__buttons">
          <router-link
            v-if="isSuAccessRole"
            class="card__btn"
            :to="linkEditPage"
          >
            {{ t('universalEdit') }}
          </router-link>

          <router-link
            v-if="!isPromo"
            class="card__btn"
            :to="linkMore"
          >
            {{ t('more') }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="card__title">
      {{ card.name }}
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { LocationAsRelativeRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { catchErrorShow, imageCandidatesToSrcset, isMobile } from '@/utils';
import IconWorkbook from '@/components/icons/NoteBook.vue';
import IconPencilLine from '@/components/icons/PencilLine.vue';
import IconPlay from '@/components/icons/Play.vue';
import IconPublishing from '@/components/icons/Publishing.vue';
import IconEyeSlash from '@/components/icons/EyeSlash.vue';
import Tooltip from '@/components/Tooltip.vue';
import { LabData } from '@/types/LabData';
import { ProductStateEnum } from '@/types/enums';
import { roles, suAccessRoles } from '@/constants/roles';
import { useRoleActionAccess } from '@/composition/useRoleActionAccess';
import dayjs from '@/utils/dayjs';
import services from '@/router/Laboratories/services';

const emit = defineEmits<{
  (ev: 'isMobileNotWorking'): void;
}>();

const props = defineProps<{
  card: LabData;
}>();

const { t, locale } = useI18n();
const isMobileConst = isMobile();
const { role } = useRoleActionAccess();

const isGuest = computed(() => role.value === roles.guest);
const isPublication = computed(() => props.card.state === ProductStateEnum.Publication
  || props.card.state === ProductStateEnum.RePublication);
const isDraft = computed(() => props.card.state === ProductStateEnum.Draft);
const isNotPublished = computed(() => !isDraft.value && !props.card.enabled);
const isPromo = computed(() => props.card.enabled_as_promo);
const appImg = toRef(() => props.card.app_img);

const isSuAccessRole = computed(() => suAccessRoles.includes(role.value));

// eslint-disable-next-line vue/no-setup-props-destructure
const expireDate: number = new Date(props.card.license_expiration_date).getTime();
const isSoonExpire = expireDate && ((expireDate - new Date().getTime()) / 1000 / 60 / 60) < 48;
const formatExpired = isSoonExpire ? 'DD MMMM YYYY (HH:mm)' : 'DD MMMM YYYY';
const expireDateText = expireDate && `${t('expires')}: ${dayjs(expireDate,
  { locale: locale.value }).format(formatExpired)}`;

const canLaunch = computed(() => props.card.active_for_user);
const canLaunchDemo = computed(() => props.card.demo_mode && Boolean(props.card.demo_counter));

const linkMore = computed<LocationAsRelativeRaw>(() => {
  if (!isPromo.value) {
    return { name: names.laboratory, params: { id: props.card.id } };
  }
  return undefined;
});
const linkEditPage = computed<LocationAsRelativeRaw>(() => (
  { name: names.editProduct, params: { id: props.card.id } }
));
const linkLaunch = computed(() => {
  if (
    isMobileConst
    || !props.card?.uuid
    || !(canLaunch.value || canLaunchDemo.value)
  ) {
    return undefined;
  }

  return props.card.demo_mode
    ? `/api/product/launch-as-demo/${props.card.uuid}`
    : `/api/product/launch/${props.card.uuid}`;
});

const handlePlay = async () => {
  if (isMobileConst) {
    emit('isMobileNotWorking');
    return;
  }

  if (!props.card.uuid) {
    return;
  }

  try {
    await services.sendAccountingAppUrl(props.card.uuid, isMobileConst);
  } catch (err) {
    catchErrorShow(err, t);
  }
};

const mediaSrcsets = computed(() => ({
  '(max-width: 575px)': imageCandidatesToSrcset({
    '2x': appImg.value['800x560'],
  }),
  '(min-width: 576px)': imageCandidatesToSrcset({
    '1x': appImg.value['400x280'],
  }),
}));

const licenseStateLabel = computed(() => {
  if (typeof props.card.license_state === 'boolean') {
    return null;
  }
  const shortName = props.card.license_state.replace('licenses.license_state_', '');
  return t(`labs.licenseState.${shortName}`);
});
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.card {
  border-radius: 14px;
  display: block;
  position: relative;
  width: 100%;
  background: white;
  font-size: 16px;

  &:deep(.card__tooltip-time):not([data-type="tooltip"]) {
    left: auto;
    right: 0;
    transform: translateX(0px);
    bottom: 106%;
    box-shadow: none;

    &:before,
    &:after {
      left: 88%;
    }
  }

  picture {
    overflow: hidden;
  }

  &__img {
    width: 100%;
    transition: .2s filter;
    aspect-ratio: 16 / 9;
  }

  $playTranslate: translate(-50%, calc(-50% - 12px));
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: $playTranslate;
    transition: transform .3s;
    opacity: 0;
    z-index: 2;
    width: 40%;
    height: 50%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .card__buttons {
      opacity: 1;
    }
    picture > .card__img {
      filter: blur(7px) contrast(90%);
    }
    .card__play {
      color: white;
      transform: $playTranslate scale(1.1);
      opacity: 1;
    }
  }

  &__image-box {
    position: relative;
  }

  &__buttons {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    opacity: 0;
    transition: .3s opacity;
  }
  &__btn {
    flex: 1;
    height: 27px;
    line-height: 27px;
    background: white;
    text-align: center;
    border-radius: 3px;
    transition: .3s;
    font-family: PT Sans, sans-serif;

    &:hover {
      filter: contrast(0.8);
    }
  }

  &:is(a) {
    cursor: pointer;
    transition: border-color $transition-speed;
  }

  &__btn-public {
    width: 36px;
    height: 26px;
    background: var(--color-acent);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &__front {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 18px;
    position: absolute;
    top: 0;
    width: 100%;

    & [data-type="tooltip"] {
      width: fit-content;
    }
  }

  &.hold {
    .card__img {
      opacity: 0.5;
    }
  }

  &__hold {
    background: white;
    font-size: 1.8rem;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 1rem 4rem;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }

  .opacity-not-published {
    opacity: .5;
  }
  .opacity-is-draft {
    opacity: .3;
  }
  .opacity-anons-not-published {
    opacity: .2;
  }
}

.card__subject {
  height: 44px;
  line-height: 44px;
  color: var(--color-acent);
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.card__subscribe {
  color: var(--color-acent);
  border-radius: 4px;
  border: 1px solid var(--color-acent);
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  position: relative;

  &.expire:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

.card__title {
  padding: 12px 20px;
  color: $colorText1;
}

.card__workbook {
  width: 36px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 6px;
  border-radius: 3px;
  background: $color-peach;
  font-size: 34px;

  svg {
    width: 15px;
    height: 16px;
  }
}

@media screen and (max-width: map-get($device-breakpoints, tablet)) {
  .card {
    &__title {
      font-size: 20px;
    }
    &__play {
      display: none;
    }
  }
}

</style>
