<template>
  <svg v-bind="attrs">
    <path
      d="M10.0007 18.3853L18.3853 13.0007L10.0007 7.61599V18.3853ZM13.003 25.6673C11.251 25.6673 9.60421 25.3349 8.06265 24.67C6.5211 24.0051 5.18021 23.1028 4.03998 21.963C2.89976 20.8232 1.99698 19.4829 1.33165 17.942C0.66654 16.4011 0.333984 14.7548 0.333984 13.003C0.333984 11.251 0.666429 9.60421 1.33132 8.06265C1.99621 6.5211 2.89854 5.18021 4.03832 4.03998C5.1781 2.89976 6.51843 1.99698 8.05932 1.33165C9.60021 0.66654 11.2465 0.333984 12.9983 0.333984C14.7503 0.333984 16.3971 0.666428 17.9387 1.33132C19.4802 1.99621 20.8211 2.89854 21.9613 4.03832C23.1015 5.1781 24.0043 6.51843 24.6697 8.05932C25.3348 9.60021 25.6673 11.2465 25.6673 12.9983C25.6673 14.7503 25.3349 16.3971 24.67 17.9387C24.0051 19.4802 23.1028 20.8211 21.963 21.9613C20.8232 23.1015 19.4829 24.0043 17.942 24.6697C16.4011 25.3348 14.7548 25.6673 13.003 25.6673ZM13.0007 23.6673C15.9784 23.6673 18.5007 22.634 20.5673 20.5673C22.634 18.5007 23.6673 15.9784 23.6673 13.0007C23.6673 10.0229 22.634 7.50065 20.5673 5.43398C18.5007 3.36732 15.9784 2.33398 13.0007 2.33398C10.0229 2.33398 7.50065 3.36732 5.43398 5.43398C3.36732 7.50065 2.33398 10.0229 2.33398 13.0007C2.33398 15.9784 3.36732 18.5007 5.43398 20.5673C7.50065 22.634 10.0229 23.6673 13.0007 23.6673Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 26, height: 26 });
</script>
