<template>
  <svg v-bind="attrs">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.2173 1.43634C23.74 1.95901 24.0009 2.58657 24 3.31901V21.9857C24 22.719 23.7391 23.347 23.2173 23.8697C22.6956 24.3923 22.0676 24.6532 21.3333 24.6523H2.66667C1.93333 24.6523 1.30578 24.3915 0.784 23.8697C0.262222 23.3479 0.000888889 22.7199 0 21.9857V3.31901C0 2.58568 0.261333 1.95812 0.784 1.43634C1.30667 0.914566 1.93422 0.653233 2.66667 0.652344H21.3333C22.0667 0.652344 22.6947 0.913677 23.2173 1.43634ZM2.19821 22.4555C2.04903 22.3063 2.00039 22.1853 2 21.9842V3.31901C2 3.12092 2.04715 3.00136 2.19701 2.85176C2.34856 2.70046 2.4702 2.65273 2.66819 2.65234H12V11.9857L15.3333 9.98568L18.6667 11.9857V2.65234H21.3333C21.5315 2.65234 21.6522 2.69961 21.8031 2.85056C21.954 3.00143 22.0002 3.12087 22 3.31658V3.31901V21.9857C22 22.185 21.9523 22.306 21.8019 22.4567C21.6534 22.6054 21.5346 22.6526 21.3358 22.6523H21.3333H2.66667C2.46747 22.6523 2.34755 22.6048 2.19821 22.4555Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 25 });
</script>
