<template>
  <svg v-bind="attrs">
    <path
      d="M0.666016 23.3327V17.0507H2.66602V21.3327H6.94802V23.3327H0.666016ZM17.0633 23.3327V21.3327H21.3454V17.0507H23.3454V23.3327H17.0633ZM0.666016 6.94802V0.666016H6.94802V2.66602H2.66602V6.94802H0.666016ZM21.3454 6.94802V2.66602H17.0633V0.666016H23.3454V6.94802H21.3454Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 24 });
</script>
