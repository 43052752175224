import { onMounted, onUnmounted } from 'vue';

export const useEventListener = (
  target: any,
  event: string,
  callback: Function
): void => {
  // if you want, you can also make this
  // support selector strings as target
  onMounted(() => target.addEventListener(event, callback, true));
  onUnmounted(() => target.removeEventListener(event, callback, true));
};

export default {
  useEventListener,
};
