<template>
  <div :class="`${cssClass}__progress`">
    <progress
      ref="$timeProgress"
      :class="`${cssClass}__progress-bar`"
      :value="progress"
      :max="duration"
      aria-label="Прогресс видео"
    />
    <input
      ref="$timeInput"
      :class="`${cssClass}__time-input`"
      :value="progress"
      :max="duration"
      min="0"
      step="1"
      type="range"
      aria-label="Перемотать видео"
      @mousemove="updateTooltip"
      @input="skipTo"
    />
    <span
      ref="$progressTooltip"
      :class="[
        `${cssClass}__time-input-tooltip`,
        `${cssClass}__tooltip`,
      ]"
    >
      {{ tooltip }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  progress: number,
  duration: string | number,
}>();

const emits = defineEmits(['skipTo']);

const $timeInput = ref<HTMLInputElement | null>(null);
const $timeProgress = ref<HTMLProgressElement | null>(null);
const $progressTooltip = ref<HTMLElement | null>(null);

const tooltip = ref<string>('00:00');

const cssClass = 'videoplayer';

const formatTime = (timeInSeconds: number): { minutes: string, seconds: string } => {
  const result = new Date(timeInSeconds * 1000).toISOString().slice(11, 19);

  return {
    minutes: result.slice(3, 5),
    seconds: result.slice(6),
  };
};

const updateTooltip = (event: any): void => {
  const skipTo = Math.round(
    (event.offsetX / event.target.clientWidth)
    * parseInt(event.target.getAttribute('max'), 10)
  );

  const time = formatTime(skipTo);
  tooltip.value = `${time.minutes}:${time.seconds}`;

  if ($timeProgress.value && $progressTooltip.value) {
    const rect = $timeProgress.value.getBoundingClientRect();
    $progressTooltip.value.style.left = `${event.pageX - rect.left}px`;
  }
};

const skipTo = (event: any) => {
  const skip = event.target.value;

  if ($timeInput.value && $timeProgress.value) {
    $timeInput.value.value = skip;
    $timeProgress.value.value = skip;
  }

  emits('skipTo', skip);
};
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.videoplayer {
  &__progress {
    position: relative;
    width: 100%;
  }

  &__progress-bar,
  &__time-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  &__progress-bar {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 8px;
    vertical-align: baseline;
    pointer-events: none;

    &::-webkit-progress-bar {
      border-radius: 8px;
      background: hsl(0, 0%, 50%);
    }

    &::-webkit-progress-value {
      border-radius: 8px;
      background: $font-color;
    }

    &::-moz-progress-bar {
      border-radius: 8px;
      background-color: $font-color;
    }
  }

  &__time-input {
    -webkit-appearance: none;
    margin: 0;
    background: transparent;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      border-radius: 8px;
      border: none;
      cursor: pointer;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
      border-radius: 16px;
      border: 2px solid $font-color;
      background: $color-white;
      cursor: pointer;
      transition:
        border-color .2s,
        box-shadow .2s;
    }

    &::-moz-range-thumb {
      width: 8px;
      height: 8px;
      border-radius: 16px;
      border: 2px solid $font-color;
      background: $color-white;
      cursor: pointer;
      transition:
        border-color .2s,
        box-shadow .2s;
    }

    &::-webkit-slider-thumb:hover {
      border-color: $color-primary;
      box-shadow: 0 0 0 3px hsla(0, 0%, 15%, 0.25)
    }

    &::-moz-range-thumb:hover {
      border-color: $color-primary;
      box-shadow: 0 0 0 3px hsla(0, 0%, 15%, 0.25)
    }

    &-tooltip {
      content: attr(data-title);
      top: -35px;
      opacity: 0;
    }

    &:hover + &-tooltip {
      opacity: 1;
    }
  }
}
</style>
