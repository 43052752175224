import { Component } from 'vue';
import i18n from '@/i18n';
import { SelectOption } from '@/types';

import IconRu from '@/components/icons/Flags/Ru.vue';
import IconEn from '@/components/icons/Flags/En-GB.vue';
import IconFr from '@/components/icons/Flags/Fr.vue';
import IconHy from '@/components/icons/Flags/Hy.vue';

export const locales: Array<string> = i18n.global.availableLocales;

export const ISOlocales: Record<string, string> = {
  ru: 'ru',
  en: 'en',
  fr: 'fr',
  hy: 'hy',
};

export const localesNamesMapper: Record<string, string> = {
  ru: 'Русский',
  en: 'English',
  fr: 'Français',
  hy: 'Հայերեն',
};

export const localesIconsMapper: Record<string, Component> = {
  ru: IconRu,
  en: IconEn,
  fr: IconFr,
  hy: IconHy,
};

export const localesOptions: Array<SelectOption> = locales.map(
  (locale: string) => ({
    value: locale,
    label: localesNamesMapper[locale] || locale,
  })
);

export default {
  locales,
  localesNamesMapper,
  localesIconsMapper,
  localesOptions,
};
