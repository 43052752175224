<template>
  <button
    :class="[`${cssClass}__btn`, `${cssClass}__btn-rate`]"
    aria-label="Изменить скорость воспроизведения"
    @click="changeRate"
  >
    <Icon1xRate v-if="playbackRate === 1" />
    <Icon1_25xRate v-else-if="playbackRate === 1.25" />
    <Icon1_5xRate v-else-if="playbackRate === 1.5" />
    <Icon2xRate v-else-if="playbackRate === 2" />
  </button>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import Icon1xRate from '@/components/icons/videoplayer/PlaybackRate/1x.vue';
import Icon1_25xRate from '@/components/icons/videoplayer/PlaybackRate/1-25x.vue';
import Icon1_5xRate from '@/components/icons/videoplayer/PlaybackRate/1-5x.vue';
import Icon2xRate from '@/components/icons/videoplayer/PlaybackRate/2x.vue';

const emit = defineEmits<{
  (event: 'click', rate: number): void;
}>();

const cssClass = 'videoplayer';
const playbackRate = ref<number>(1);

const changeRate = () => {
  // eslint-disable-next-line default-case
  switch (playbackRate.value) {
    case 1:
      playbackRate.value = 1.25;
      break;
    case 1.25:
      playbackRate.value = 1.5;
      break;
    case 1.5:
      playbackRate.value = 2;
      break;
    case 2:
      playbackRate.value = 1;
      break;
  }

  emit('click', playbackRate.value);
};
</script>

<style lang="scss">
.videoplayer__btn {
  &.videoplayer__btn-rate {
    padding: 0;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
