export const throttle = (func: Function, ms: number): Function => {
  let isThrottled = false;
  let savedArgs: any;
  let savedThis: any;

  /* eslint-disable */
  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      // @ts-ignore
      savedThis = this;
      return;
    }

    // @ts-ignore
    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }
  /* eslint-enable */

  return wrapper;
};

export const debounce = (func: Function, wait: number): Function => {
  let timeout: any;

  return function executedFunction(this: any): void {
    // eslint-disable-next-line
    const context = this;
    // eslint-disable-next-line
    const args = arguments;

    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export default debounce;
