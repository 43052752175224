import services from '@/services/admin';

interface UploadedPayloadType {
  name: string,
  url: string,
}

class Upload {
  currentChunk = 0;

  uploadId = '';

  uploadedBytes = 0;

  errorsCounter = 0;

  progress = 0;

  chunks: Blob[] = [];

  fileUploading = false;

  file: File | null = null;

  downloadFile(file: File) {
    this.file = file;
    this.chunks = this.createChunks();
    return this.initLoading();
  }

  createChunks() {
    if (!this.file) { return []; }
    const chunks = [];
    const size = 1024 * 1024 * 40; // 40MB
    const chunkAmount = Math.ceil(this.file.size / size);

    for (let i = 0; i < chunkAmount; i++) {
      const chunk = this.file.slice(
        i * size,
        Math.min(i * size + size, this.file.size),
        this.file.type
      );
      chunks.push(chunk);
    }

    return chunks;
  }

  initLoading(): Promise<UploadedPayloadType> {
    const formData = this.createFormData();
    return this.load(formData);
  }

  createFormData() {
    const formData = new FormData();
    if (this.uploadId) {
      formData.append('upload_id', this.uploadId);
    }
    formData.append('type', 'manual');
    formData.append('chunk', String(this.currentChunk));
    formData.append('chunks', String(this.chunks.length));
    formData.append('file', this.chunks[this.currentChunk], this.file?.name);

    return formData;
  }

  async load(formData: FormData) {
    this.fileUploading = true;
    try {
      const response = await services.uploadFile(formData, {
        onUploadProgress: (event: any) => {
          this.uploadedBytes += event.bytes;
          if (!this.file?.size) {
            this.progress = 0;
            return;
          }
          // eslint-disable-next-line max-len
          this.progress = Math.floor((this.uploadedBytes * 100) / this.file.size);
        },
      }).then((res) => res);

      this.uploadId = response.data.upload_id;
      this.currentChunk++;

      if (this.currentChunk < this.chunks.length) {
        return await this.initLoading();
      }
      return response.data;
    } catch (error: any) {
      console.log('catch error ', error.message);
      this.errorsCounter++;

      this.uploadedBytes = this.chunks
        .filter((chunk, index) => index < this.currentChunk)
        .reduce((acc, current: any) => acc + current.size, 0);

      if (this.errorsCounter < this.chunks.length * 2) {
        return await this.initLoading();
      }
    } finally {
      this.uploadedBytes = 0;
      this.fileUploading = false;
      this.uploadId = '';
      this.currentChunk = 0;
      this.errorsCounter = 0;
    }
  }
}

export {
  Upload,
};

export type { UploadedPayloadType };
