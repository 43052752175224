<template>
  <svg
    v-bind="attrs"
    fill="none"
  >
    <circle
      cx="7.5"
      cy="8"
      r="7"
      stroke="#0B2A45"
    />
    <circle
      cx="7.5"
      cy="6.5"
      r="2"
      stroke="#0B2A45"
    />
    <path
      d="M3 13C5 9.00001 10 9 12 13"
      stroke="#0B2A45"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({
  width: 15,
  height: 16,
});
</script>
