<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_15717_14053)">
      <path
        d="M2.115 14C1.655 14 1.271 13.8423 0.963 13.5269C0.654333 13.2108 0.5 12.8172 0.5 12.3461V10.3769C0.5 10.2308 0.547667 10.1089 0.643 10.0113C0.739 9.91364 0.858 9.86482 1 9.86482C1.142 9.86482 1.261 9.91364 1.357 10.0113C1.45233 10.1089 1.5 10.2308 1.5 10.3769V12.3461C1.5 12.5032 1.564 12.6476 1.692 12.7793C1.82067 12.9104 1.96167 12.9759 2.115 12.9759H12.885C13.0383 12.9759 13.1793 12.9104 13.308 12.7793C13.436 12.6476 13.5 12.5032 13.5 12.3461V10.3769C13.5 10.2308 13.5477 10.1089 13.643 10.0113C13.739 9.91364 13.858 9.86482 14 9.86482C14.142 9.86482 14.261 9.91364 14.357 10.0113C14.4523 10.1089 14.5 10.2308 14.5 10.3769V12.3461C14.5 12.8172 14.346 13.2104 14.038 13.5259C13.7293 13.842 13.345 14 12.885 14H2.115ZM7 1.63646L5.029 3.65489C4.93167 3.75457 4.816 3.80679 4.682 3.81157C4.548 3.81635 4.42567 3.76208 4.315 3.64875C4.20833 3.5361 4.15433 3.41526 4.153 3.28623C4.151 3.15788 4.205 3.03738 4.315 2.92473L6.935 0.24168C7.02167 0.152927 7.11067 0.0904591 7.202 0.0542754C7.29267 0.0180918 7.392 0 7.5 0C7.608 0 7.70733 0.0180918 7.798 0.0542754C7.88933 0.0904591 7.97833 0.152927 8.065 0.24168L10.685 2.92473C10.7823 3.02441 10.8333 3.14183 10.838 3.27701C10.842 3.41219 10.791 3.5361 10.685 3.64875C10.5743 3.76208 10.4553 3.81976 10.328 3.82181C10.2007 3.82318 10.0817 3.76754 9.971 3.65489L8 1.63646V9.98259C8 10.128 7.95233 10.2499 7.857 10.3482C7.761 10.4458 7.642 10.4946 7.5 10.4946C7.358 10.4946 7.239 10.4458 7.143 10.3482C7.04767 10.2499 7 10.128 7 9.98259V1.63646Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_15717_14053">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
