import { getUser, getToken } from '@/services/userActions';

export const checkUser = async (): Promise<boolean> => {
  try {
    await getToken();
    await getUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const userAuthentication = async (
  store: any
): Promise<string> => {
  try {
    await getToken();
    const user = await getUser();
    store.commit('SET_USER', user);
    store.dispatch('dictionary/fetch');
    return user?.role?.shortname || '';
  } catch (err) {
    console.error(err);
  }
  return '';
};
