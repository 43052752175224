<template>
  <component
    :is="component"
    class="lang-icon"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LangRu from '@/components/icons/LangRu.vue';
import LangEn from '@/components/icons/LangEn.vue';
import LangFr from '@/components/icons/LangFr.vue';
import LangHy from '@/components/icons/LangHy.vue';

const languageComponents = {
  ru: LangRu,
  en: LangEn,
  fr: LangFr,
  hy: LangHy,
};

interface Props {
  lang: string,
}

const props = withDefaults(defineProps<Props>(), {
  lang: 'ru',
});

const component = computed(() => languageComponents[props.lang]);

</script>

<style lang="scss">

.lang-icon {

  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
