import { RouteRecordRaw } from 'vue-router';

import addAnnouncement from '@/router/Admin/AddAnnouncement/router';
import addProduct from '@/router/Admin/AddProduct/router';
import editProduct from '@/router/Admin/EditProduct/router';

const error: Array<RouteRecordRaw> = [
  ...addAnnouncement,
  ...addProduct,
  ...editProduct,
];

export default error;
