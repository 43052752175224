<template>
  <svg v-bind="attrs">
    <path
      d="M1 1L17 17M17 1L1 17"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 18, height: 18 });
</script>
