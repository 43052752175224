import { ElNotification } from 'element-plus';

export const showValidationError = (fields, t) => {
  const messages = [];
  if (fields) {
    Object.keys(fields).forEach((fieldName) => {
      fields[fieldName].forEach((i) => {
        if (!i.message) { return false; }
        messages.push(i.message);
      });
    });

    ElNotification({
      title: t('OrgShopToastError'),
      message: messages.join('</br>'),
      type: 'warning',
      position: 'bottom-right',
      dangerouslyUseHTMLString: true,
    });
  }
};
