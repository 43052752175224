<template>
  <Dropdown
    :show="isShow"
    :indent="0"
    right
  >
    <template #trigger>
      <div
        ref="$el"
        class="header-profile"
        @click="handleToggle"
      >
        <h3 class="header-profile__name">
          <span> {{ firstName }} </span>
          <span v-if="secondName">&nbsp;{{ secondName }}. </span>
        </h3>
        <UserAvatar
          :avatarLink="userData.small_avatar"
          class="header-profile__avatar"
        />
      </div>
    </template>
    <div class="header-profile__dropdown-content">
      <div class="header-profile__role">
        {{ displayedRole }}
      </div>

      <router-link
        :to="{ name: names.profile }"
        class="header-profile__link"
        @click="handleClose"
      >
        {{ t("menus.profile") }}
      </router-link>
      <CleanButton
        class="header-profile__link"
        @click="handleLogout"
      >
        {{ t("menus.logout") }}
      </CleanButton>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { roles } from '@/constants/roles';
import { useClickOutside } from '@/libraries/useClickOutside';

import Dropdown from '@/components/Dropdown.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import CleanButton from '@/components/CleanButton.vue';

import { logoutUser } from '@/services/userActions';
import { LoggedUser } from '@/types/user';

const store = useStore();

const $el = ref<HTMLDivElement | null>(null);
const isShow = ref<boolean>(false);
const { t } = useI18n();

const userData = computed<LoggedUser>(() => store.state.UserData.user);
const firstName = computed<string>(() => {
  if (userData.value.firstname && userData.value.firstname?.length > 16) {
    return `${userData.value.firstname?.slice(0, 16)}...`;
  }
  return userData.value.firstname || '';
});

const secondName = computed<string>(() => (userData.value.surname || '').charAt(0));

const displayedRole = computed<string>(() => {
  const roleData = userData.value.role;
  if (roleData.shortname === roles.student && !userData.value.organization) {
    return t('roles.user');
  }
  return t(`roles.${roleData.shortname}`);
});

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const handleLogout = async (): Promise<void> => {
  handleClose();
  await logoutUser();
  await store.dispatch('clearUserData');
};

useClickOutside($el, handleClose);
</script>

<style lang="scss" src="./index.scss"></style>

<style scoped lang="scss">
@use "sass:math";
@import "@/assets/style/include.scss";

.header-profile {
  padding: 1rem 0;
  position: relative;
  cursor: pointer;

  @include media-breakpoint-down("sm") {
    padding: 0.25rem 0;

    button {
      font-size: 12px;
    }
  }

  @include media-breakpoint-up("sm") {
    &__name + &__avatar {
      margin-left: 10px;
    }
  }
}

.header-profile__name {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;

  @include media-breakpoint-down("sm") {
    display: none;
  }
}

.header-profile__dropdown-content {
  margin: -#{math.div($base-indent, 2)} -#{math.div($base-indent, 4)};
}

.header-profile__role {
  background: $color-accent;
  border-top: 2px solid $color-accent;
  color: $color-white;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

.header-profile__link {
  cursor: pointer;
  display: block;
  padding: 0.5rem;
  text-align: right;
  width: 100%;

  &.active {
    background: $color-accent-light;
    color: $color-white;
    pointer-events: none;
  }

  @include hover-focus() {
    background: $color-accent-lightest;
    outline: 0;
  }
}
</style>
