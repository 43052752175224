import service from '@/services/dictionary';
import { isOrg, isSU, isUsual } from '@/utils/user';
import { localesOptions } from '@/constants/languages';

const SET_CLASSES = 'SET_CLASSES';
const SET_ROLES = 'SET_ROLES';
const SET_SU_ROLES = 'SET_SU_ROLES';
const SET_LOCAL_ROLES = 'SET_LOCAL_ROLES';
const SET_ORG_ROLES = 'SET_ORG_ROLES';

export default {
  namespaced: true,
  state() {
    return {
      classes: [],
      roles: [],
      suRoles: [],
      localRoles: [],
      orgRoles: [],
      fakeRoles: [
        {
          value: 7,
          label: '',
          fakeRoleLabel: 'teacher',
          shortname: 'teacher',
        },
        {
          value: 8,
          label: '',
          fakeRoleLabel: 'user',
          shortname: 'student',
        },
      ],
      lang: localesOptions,
    };
  },
  getters: {
    optionsMapper: (state, getters, rootState, rootGetters) => {
      const isSu = isSU(rootGetters.role);
      const isUsualRole = isUsual(rootGetters.role);
      const { isOrgAttached } = rootGetters;
      const role = !isOrgAttached && isUsualRole
        ? state.fakeRoles
        : [...(isSu ? state.suRoles : []), ...state.roles];

      return {
        role,
        ...(isSu ? { local_roles: state.localRoles } : {}),
        class: state.classes,
        lang: state.lang,
      };
    },
  },
  actions: {
    fetch({ dispatch }) {
      dispatch('fetchClasses');
      dispatch('fetchRoles');
      dispatch('fetchSuRoles');
      dispatch('fetchLocalRoles');
      dispatch('fetchOrgRoles');
    },
    async fetchClasses({ commit }) {
      try {
        const list = await service.getClassesList();
        const options = list.map(
          ({ id: value, name: label }) => Object.freeze({
            label,
            value,
          })
        );
        commit(SET_CLASSES, options || []);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchRoles({ commit, rootGetters }) {
      const { role } = rootGetters;
      try {
        const list = await service.getRolesList(role);
        const options = list.map(
          ({ id: value, name: label, shortname }) => Object.freeze({
            label,
            value,
            shortname,
          })
        );
        commit(SET_ROLES, options || []);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSuRoles({ commit, rootGetters }) {
      const { role } = rootGetters;
      if (isSU(role)) {
        try {
          const list = await service.getVizexRolesList();
          const options = list.map(
            ({ id: value, name: label, shortname }) => Object.freeze({
              label,
              value,
              shortname,
            })
          );
          commit(SET_SU_ROLES, options || []);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async fetchLocalRoles({ commit, rootGetters }) {
      const { role } = rootGetters;
      if (isSU(role)) {
        try {
          const list = await service.getLocalRolesList();
          const options = list.map((item) => ({
            value: item,
            label: item,
          }));
          commit(SET_LOCAL_ROLES, options || []);
        } catch (e) {
          console.error(e);
        }
      }
    },
    async fetchOrgRoles({ commit, rootGetters }) {
      const { role } = rootGetters;

      if (isSU(role) || isOrg(role)) {
        try {
          const list = await service.getOrgRolesList();

          const options = list.map(
            ({ id: value, name: label, shortname }) => Object.freeze({
              label,
              value,
              shortname,
            })
          );
          commit(SET_ORG_ROLES, options || []);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  mutations: {
    [SET_CLASSES]: (state, data) => {
      state.classes = data;
    },
    [SET_ROLES]: (state, data) => {
      state.roles = data;
    },
    [SET_SU_ROLES]: (state, data) => {
      state.suRoles = data;
    },
    [SET_LOCAL_ROLES]: (state, data) => {
      state.localRoles = data;
    },
    [SET_ORG_ROLES]: (state, data) => {
      state.orgRoles = data;
    },
  },
};
