<template>
  <svg v-bind="attrs">
    <rect
      x="4"
      y="2.5"
      width="16"
      height="20"
      rx="1"
      fill="white"
    />
    <path
      d="M6.28544 2.5H5.14258V22.5H6.28544V2.5Z"
      fill="#FD8263"
    />
    <rect
      x="8.57129"
      y="8.75"
      width="9.14286"
      height="1.25"
      rx="0.625"
      fill="#FD8263"
    />
    <rect
      x="8.57129"
      y="13.75"
      width="9.14286"
      height="1.25"
      rx="0.625"
      fill="#FD8263"
    />
    <rect
      x="8.57129"
      y="11.25"
      width="9.14286"
      height="1.25"
      rx="0.625"
      fill="#FD8263"
    />
    <path
      d="M2.85742 12.5C2.85742 11.8096 3.41707 11.25 4.10742 11.25H5.14314V13.75H4.10742C3.41707 13.75 2.85742 13.1904 2.85742 12.5Z"
      fill="white"
    />
    <path
      d="M2.85742 17.5C2.85742 16.8096 3.41707 16.25 4.10742 16.25H5.14314V18.75H4.10742C3.41707 18.75 2.85742 18.1904 2.85742 17.5Z"
      fill="white"
    />
    <path
      d="M2.85742 7.5C2.85742 6.80964 3.41707 6.25 4.10742 6.25H5.14314V8.75H4.10742C3.41707 8.75 2.85742 8.19036 2.85742 7.5Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 24, height: 25 });
</script>
