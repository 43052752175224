<template>
  <svg
    v-bind="attrs"
    fill="none"
  >
    <path
      d="M10.9906 1.00012H4C2.34315 1.00012 1 2.34327 1 4.00012V13.9999C1 15.6567 2.34315 16.9999 4 16.9999H10.9906M5 9L14 9.00011M14 9.00011L10 5M14 9.00011L10 12"
      stroke="#0B2A45"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 15, height: 18 });
</script>
