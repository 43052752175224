<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_15776_12792)">
      <path
        d="M2.66667 6H8.66667C9.40305 6 10 6.59695 10 7.33333V13.3333C10 14.0697 9.40305 14.6667 8.66667 14.6667H2.66667C1.93029 14.6667 1.33333 14.0697 1.33333 13.3333V7.33333C1.33333 6.59695 1.93029 6 2.66667 6Z"
        stroke="currentColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6667 9.99967H13.3334C13.687 9.99967 14.0262 9.8592 14.2762 9.60915C14.5263 9.3591 14.6667 9.01996 14.6667 8.66634V2.66634C14.6667 2.31272 14.5263 1.97358 14.2762 1.72353C14.0262 1.47348 13.687 1.33301 13.3334 1.33301H7.33341C6.97979 1.33301 6.64065 1.47348 6.39061 1.72353C6.14056 1.97358 6.00008 2.31272 6.00008 2.66634V3.33301"
        stroke="currentColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15776_12792">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
